import React, { memo, useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { Autocomplete, Stack, Button, Tooltip, TextField, Grid, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { motion } from 'framer-motion'
import configServ from '../../../services/config'
import { useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AlertDialog from "../../AlertDialog/AlertDialog";
import CardHeadMenu from '../CardHeadMenu/CardHeadMenu'
import { useSelector } from 'react-redux'

//FUNCTION
const CategoryCard = ({ isChanged, setIsChanged, editData, setEditData }) => {
    //#region code
    const app = useSelector((state) => state.GlobalVariables.noRoomService);
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [superCategory, setSuperCategory] = useState([]);
    const [formData, setFormData] = useState({
        status: true,
        isPrimary: false

    });
    const [isEdit, setIsEdit] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);
    const [isCreate, setIsCreate] = useState(false);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    // useEffect(() => {
    //     fetchSuperCategory();
    // }, []);

    useEffect(() => {
        if (editData !== null) {
            setIsCreate(true);
        }
    }, [editData]);

    // const fetchSuperCategory = async () => {
    //     try {
    //         const result = await configServ.getSuperCategory({ "company_id": company_id });
    //         setSuperCategory(result);
    //     }
    //     catch (err) {
    //         console.log(err)
    //     }
    // }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    const checkFieldsNotEmpty = () => {
        return (
            formData.categoryname !== '' && formData.categoryname !== undefined && formData.categoryname !== undefined &&
            formData.brand_id !== '' && formData.brand_id !== undefined && formData.brand_id !== undefined
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const error = handleFormValidation();
        if (error === false) {
            return false;
        }
        setValidationErrors({});

        const dataToSend = {
            ...formData,
            company_id: company_id,
        }
        try {
            if (!isEdit) {
                const res = await configServ.addCategory(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged)
                    clear();
                    handleOpenDialog('Success', res.message, 'success');
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to add category.", 'error');
                }
            }
            else {
                const res = await configServ.editCategory(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged)
                    clear();
                    handleOpenDialog('Success', res.message, 'success');
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to update category.", 'error');
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (Object.keys(editData).length !== 0) {
            setFormData(editData)
            setIsEdit(true)
            setFormData((prev) => ({
                ...prev,
                status: true
            }));
        }
    }, [editData])

    const clear = () => {
        setFormData({
            status: true,
            isPrimary: false
        });
        setValidationErrors({});
        setIsEdit(false);
        setEditData({});
    }

    const handleFormValidation = () => {
        const errors = {}
        if (!formData.categoryname) {
            errors.categoryname = "required";
        }

        setValidationErrors(errors);
        return Object.values(errors).every((error) => error === "");
    }

    const handlePopup = () => {
        setIsCreate(!isCreate);
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />

            <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'CATEGORY'} handlePopup={handlePopup} isEdit={isEdit} create={isCreate} />

            {isCreate &&
                <motion.div style={{ width: "100%", backgroundColor: "#e3f3fc", paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, borderRadius: '10px', border: 'none' }}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.1, delay: 0.1 }}
                >
                    <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={1}>
                        <Grid item xs={12} md={12} justifyContent={'flex-start'}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Category Detail</Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField
                                id="categoryname"
                                name="categoryname"
                                label="Category Name*"
                                variant="outlined"
                                size='small'
                                fullWidth
                                sx={{ backgroundColor: 'white' }}
                                onChange={handleChange}
                                error={validationErrors.categoryname}
                                value={formData.categoryname || ''}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                id="description"
                                name="description"
                                label="Description"
                                variant="outlined"
                                size='small'
                                fullWidth
                                sx={{ backgroundColor: 'white' }}
                                onChange={handleChange}
                                value={formData.description || ''}
                            />
                        </Grid>

                        {/* {app === 'noroom' &&
                            <Grid item xs={12} md={3}>
                                <Autocomplete
                                    fullWidth
                                    sx={{ backgroundColor: 'white' }}
                                    size="small"
                                    options={superCategory}
                                    getOptionLabel={(item) => item.name}
                                    renderInput={(params) => <TextField {...params} label="Super Category" />}
                                    value={superCategory.find((item) => item.id === formData.super_category_id) || null}
                                    onChange={(event, newValue) => {
                                        handleChange({ target: { name: 'super_category_id', value: newValue?.id || '' } });
                                    }}
                                />
                            </Grid>
                        } */}

                        <Grid item xs={12} md={4}>
                            <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    name='status'
                                    control={<Checkbox checked={Boolean(formData.status) || false} />}
                                    onChange={handleChangeCheckbox}
                                    label="Status"
                                    style={{ marginRight: '20px' }}
                                />
                                <FormControlLabel
                                    name='isPrimary'
                                    control={<Checkbox checked={Boolean(formData.isPrimary) || false} />}
                                    onChange={handleChangeCheckbox}
                                    label="Is Primary"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} justifyContent="space-between" px={2}>
                        <Grid
                            item
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item>
                                <Button
                                    onClick={clear}
                                    variant="contained"
                                    color='secondary'
                                    startIcon={<RotateLeftIcon />}
                                >
                                    Reset
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleSubmit}
                                    autoFocus
                                    variant="contained"
                                    color={isEdit ? 'success' : 'primary'}
                                    startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                                >
                                    {isEdit ? "Update" : "Save"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </motion.div>
            }
        </>
    )
}

export default memo(CategoryCard);


