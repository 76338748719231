function checkUndefinedReturnNull(value) {
    return value !== undefined ? value : null;
}

function checkTextUndefined(value) {
    return value !== undefined ? value : '';
}

function checkTextNull(value) {
    return value !== null ? value : '';
}

function checkTextNullAndUndefined(value) {
    return value !== undefined && value !== null ? value : '';
}

const transformToDate = (value) => {
    if (value) {
        const date = new Date(value);
        return date;
    } else {
        return ''
    }
}

//when value = "2023-11-30"
const transformToDate2 = (value) => {
    if (value) {
        const date = new Date(value);
        const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    } else {
        return '';
    }
};

//Permision handler
const PermissionHandler = (task, permissions) => {
    const isPermitted = permissions.some(permission =>
        permission === task || permission === 'all-permissions'
    );
    return isPermitted;
}

const getCurrentDateTimeFormatted = () => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = currentDate.getFullYear();
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  
    return `${day}-${month}-${year} ${hours}-${minutes}-${seconds}`;
  };

const CheckAndReturn = {
    checkUndefinedReturnNull,
    checkTextUndefined,
    checkTextNull,
    checkTextNullAndUndefined,
    transformToDate,
    transformToDate2,
    PermissionHandler,
    getCurrentDateTimeFormatted,
}

export default CheckAndReturn;