import React, { memo } from 'react';
import { Dialog, DialogActions, DialogContent, Button, Grid, FormControl, InputLabel, Select, MenuItem, DialogTitle } from '@mui/material';

function ExcelRegisterDialogBox({
    open,
    onClose,
    handleDownload,
    handleExcelUpload,
    examList,
    examName,
    handleExamChange,
    centreList,
    centerName,
    handleCenterChange
}) {
    return (
        <Dialog open={open} fullWidth onClose={onClose}>
            <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', color: '#2e7d32' }}>
                REGISTER CANDIDATES
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}> {/* Added spacing between grid items */}
                    <Grid item xs={12} md={12} style={{ alignSelf: 'flex-end', marginBottom: '10px' }}>
                        <FormControl fullWidth>
                            <InputLabel>Exam Name</InputLabel>
                            <Select
                                fullWidth
                                variant="standard"
                                sx={{ backgroundColor: 'white' }}
                                labelId="exam-label"
                                label="Exam Name"
                                id="exam_id"
                                name="exam_id"
                                value={examName}
                                onChange={handleExamChange}
                            >
                                {examList.map((exam) => (
                                    <MenuItem key={exam.id} value={exam}>
                                        {exam.item_name} ({exam.item_code})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ alignSelf: 'flex-end', marginBottom: '10px' }}>
                        <FormControl fullWidth>
                            <InputLabel>Center Name</InputLabel>
                            <Select
                                variant="standard"
                                size="small"
                                fullWidth
                                value={centerName}
                                onChange={handleCenterChange}
                            >
                                {centreList.map((center, index) => (
                                    <MenuItem key={index} value={center}>
                                        {center.CompanyName} ({center.CompanyCode})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDownload}
                            disabled={!examName || !centerName}
                        >
                            Download Excel Template
                        </Button>
                    </Grid>
                    <Grid item>
                        <input
                            accept=".xlsx"
                            style={{ display: 'none' }}
                            id="excel-file-input"
                            type="file"
                            onChange={handleExcelUpload}
                            disabled={!examName || !centerName}
                        />
                        <label htmlFor="excel-file-input">
                            <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                disabled={!examName || !centerName}
                            >
                                Upload Excel Data
                            </Button>
                        </label>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>

    );
}

export default memo(ExcelRegisterDialogBox);


