import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AlertDialog from "../../AlertDialog/AlertDialog";
import configServ from "../../../services/config";
import CaptureOrUploadIris from "./CaptureOrUploadIris";
import ExistingIris from "./ExistingIris";
const Iris = ({
  open,
  irisData,
  onClose,
  from,
  verified,
  exam_id,
  firstStage,
}) => {
  const [irisTemplate, setIrisTemplate] = useState(null);
  const [irisImage, setIrisImage] = useState(null);
  const [iris, setIris] = useState(null);
  const [secondaryIris, setSecondaryIris] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [captured, setCaptured] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [existingImage, setExistingImage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState("Alert");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonType, setButtonType] = useState(null);
  const [capturing, setCapturing] = useState(false);
  const [remark, setRemark] = useState(null);
  const [isTested, setTested] = useState(false);
  const [isMatched, setIsMatched] = useState(false);
//var uri = "https://localhost:8031/marvisauth/"; //Secure
  const uri = "http://localhost:8031/marvisauth/"; // Non-Secure URI
  
  const { user_id } = useSelector((state) => state.GlobalVariables);

  useEffect(() => {
    const fetchExistingImage = async () => {
      try {
        const primary_iris = irisData.primary_iris;
        const secondary_iris = irisData.secondary_iris;
        if (primary_iris !== null) {
          setExistingImage(`data:image/jpg;base64,${primary_iris.image}`);
          setIris({
            isoTemplate: primary_iris.iso_template,
            remark: primary_iris.remark,
            capturedAt: formatDateTime(primary_iris.created_at),
            updatedAt: formatDateTime(primary_iris.updated_at),
          });
        }
        if (secondary_iris !== null) {
          setSecondaryIris(secondary_iris);
        }
      } catch (error) {
        console.error("Error fetching existing image:", error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchExistingImage();
    }
  }, [open]);

  const formatDateTime = (dateTime) => {
    if (!dateTime) return "Not available";
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const time = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedDate} - ${time}`;
  };

  const initIris = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const initResponse = await fetch(uri + "info", requestOptions);
      const initResponseData = await initResponse.json();
      if (initResponseData.ErrorCode !== "0") {
        handleOpenDialog("Info", initResponseData.ErrorDescription, "error");
        return false;
      }
      return true;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCaptureIris = async () => {
    const intilized = await initIris();
    if (!intilized) {
      handleOpenDialog(
        "Error",
        "Some Error Occured While Iris Intiliaztion",
        "error"
      );
      return;
    }
    setCapturing(true);
    try {
      const timeout = 30000;
      const captureResponse = await CaptureIris(timeout);

      if (captureResponse.ErrorCode !== "0") {
        handleOpenDialog("Error", captureResponse.ErrorDescription, "error");
        setCaptured(false);
        setCapturing(false);
        return;
      }
      handleOpenDialog("Success", "Iris Capture Succesfull", "success");
      setIris(captureResponse);
      setCaptured(true);
      setError(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
      setCapturing(false);
    }
  };

  const CaptureIris = async (timeout) => {
    const requestData = {
      TimeOut: timeout,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    };

    const response = await fetch(uri + "capture", requestOptions);
    const data = await response.json();

    if (data.ErrorCode !== "0") {
      handleOpenDialog("Error", data.ErrorDescription, "error");
      setCaptured(false);
      setCapturing(false);
      return;
    }

    return data;
  };

  const handleMatchIris = async () => {
    const initialized = await initIris();
    if (!initialized) {
      handleOpenDialog("Error", "Some Error Occurred While Iris Initialization", "error");
      return;
    }
  
    setCapturing(true);
    setLoading(true);
  
    try {
      const timeout = 30000;
      const quality = 100;
      const matchResponse = await matchIris(quality, timeout);

      if (matchResponse.Status || remark ) {
        setIris(matchResponse.BitmapData)
        setTested(true);
        setIsMatched(true);
        await handleSecondaryIrisUpload(matchResponse.BitmapData);
        // handleOpenDialog("Success", "Match Successful", "success");
      } else {
        setTested(true);
        setIsMatched(false);
        handleOpenDialog("Warning", matchResponse.ErrorDescription, "warning");
      }
    } catch (error) {
      setError(error.message);
      handleOpenDialog("Error", error.message, "error");
    } finally {
      setLoading(false);
      setCapturing(false);
    }
  };
  
  const matchIris = async (quality, timeout) => {
    const dataToSend = {
      Quality: quality,
      TimeOut: timeout,
      GalleryTemplate: iris.isoTemplate,
      BioType: "FMR",
    };
  
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    };
  
    try {
      const response = await fetch(uri + "match", requestOptions);
      const data = await response.json();

      if (data.ErrorCode === "0" || remark) {
        return data;
      } else {
        throw new Error(data.ErrorDescription);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const handlePrimaryIrisUpload = async () => {
    //const dateTime = CheckAndReturn.getCurrentDateTimeFormatted();
    const dataToSend = {
      image: `data:image/jpeg;base64,${iris.BitmapData}`,
      type: "iris",
      student_id: irisData.id,
      iso_template: iris.BitmapData,
      registration_no: irisData.registration_no,
      exam_id: exam_id,
      status: Boolean(irisData.status),
      user_id: user_id,
      is_verified: true,
      created_at: null,
    };

    try {
      const result = await configServ.addPrimaryStudentFingerprint(dataToSend);
      if (result.success === 200) {
        handleOpenDialog("Success", "Upload Succesfull", "success");
        setUploadStatus(true);
        
      }
    } catch (error) {
      setError(error.message);
    } finally {
      // setLoading(false);
      // setIris(null);
      // setCaptured(false);
      // setError(null);

      // handleClose()
    }
  };

  const handleSecondaryIrisUpload = async (secondaryIrisBitmap) => {
    try {
        //const dateTime = CheckAndReturn.getCurrentDateTimeFormatted();
        const dataToSend = {
          image: `data:image/jpeg;base64,${secondaryIrisBitmap}`,
          type: "iris",
          student_id: irisData.id,
          iso_template: secondaryIrisBitmap,
          registration_no: irisData.registration_no,
          exam_id: exam_id,
          status: Boolean(irisData.status),
          user_id: user_id,
          is_verified: true,
          remark: remark,
          created_at: null,
        };

        const result = await configServ.addStudentFingerprint(dataToSend);
      
        if (result.status === 200) {
          handleOpenDialog("Success", "Match & Upload Succesfull", "success");
          setUploadStatus(true);
          
        }
      } catch (error) {
        handleOpenDialog("Error", error.message, "error");
        setError(error.message);
      }
      //  finally {
      //   // setLoading(false);
      //   // setIris(null);
      //   // setCaptured(false);
      //   // setError(null);
  
      //   handleClose()
      // }
}

const handleRemark = (data) => {
  try {
    setRemark(data || null);
  } catch (e) {
    console.error('Error setting remark:', e);
  }
};

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle("Alert");
    setDialogContent("");
    setButtonType(null);
    if (uploadStatus) {
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
    setIris(null);
    setUploadStatus(false);
    setExistingImage(null);
    // setError(null);
    setLoading(true);
    setCaptured(false);
    // setTested(false);
    // setIsMatched(false);
    // setRemark(null);

  };
  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Grid container justifyContent="center">
            <Typography variant="h6">Iris</Typography>
          </Grid>
        </DialogTitle>
        {
          existingImage ? (
            <DialogContent>
            <ExistingIris
              open={open}
              handleClose={handleClose}
              handleCaptureIris={handleCaptureIris}
              handlePrimaryIrisUpload={handlePrimaryIrisUpload}
              handleMatchIris={handleMatchIris}
              iris={iris}
              existingImage={existingImage}
              secondaryIris={secondaryIris}
              captured={captured}
              capturing={capturing}
              verified={verified}
              isFirstStage={firstStage}
              handleRemark={handleRemark}
              remark={remark}
              fullWidth
            />
          </DialogContent>
          ):( 
            <DialogContent>
            <CaptureOrUploadIris
              open={open}
              handleClose={handleClose}
              handleCaptureIris={handleCaptureIris}
              handlePrimaryIrisUpload={handlePrimaryIrisUpload}
              iris={iris}
              captured={captured}
              capturing={capturing}
              loading={loading}
              fullWidth
            />
          </DialogContent>
          )
        }  
      </Dialog>
    </>
  );
};

export default Iris;
