import React, { useState, useEffect } from "react";
import CustomersTable from "../../Components/Card/Customers/CustomersTable";
import { Box, Stack, Paper, Divider, Typography, Button, Grid, Link, List, ListItem, ListItemText } from "@mui/material";
import { useSelector } from 'react-redux';
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import ImageUpload from "../../Components/Card/ImageUpload/ImageUpload";
import CustomerCard from "./CustomerCard";
import CheckAndReturn from "../../utility/CheckAndReturn";
import Biometric from "../../Components/Card/Biometric/Biometric";
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import ExcelDataPreview from "../../Components/Card/Excel/ExcelDataPreview";
import { handleExcelDownload, validateExcelFile, readExcelFile, formatExcelData, createErrorLogFile } from "../../Components/Card/Excel/excelUtils";
import configServ from "../../services/config";
import ExcelDialogBox from "../../Components/Card/Excel/ExcelRegisterDialogBox";
import Iris from "../../Components/Card/Iris/Iris";

const Customers = () => {
  //#region code
  const { permissions, user_id } = useSelector((state) => state.GlobalVariables);
  const open = useSelector((state) => state.sideExpand.open)
  const [openDialog, setOpenDialog] = useState(false);
  const [isChanged, setIsChanged] = useState(false)
  const [editData, setEditData] = useState({})
  const [openImageHandler, setOpenImageHandler] = useState(false);
  const [openBiometricHandler, setOpenBiometricHandler] = useState(false);
  const [openIrisHandler, setOpenIrisHandler] = useState(false);
  const [imageData, setImageData] = useState(null)
  const [biometricData, setBiometricData] = useState(null)
  const [irisData, setIrisData] = useState(null)
  const [verified, setVerified] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState('Alert');
  const [dialogContent, setDialogContent] = useState('');
  const [buttonType, setButtonType] = useState(null);
  const [openExcelDialog, setOpenExcelDialog] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [examList, setExamList] = useState([]);
  const [examName, setExamName] = useState(null);
  const [exam_id, setExam_id] = useState(null);
  const [excelDialog, setExcelDialog] = useState(null)
  const [centreList, setCentreList] = useState([]);
  const [centerName, setCenterName] = useState(null);
  const [firstStage, setFirstStage] = useState(false)

  useEffect(() => {
    fetchExams();
    fetchCentres();
  }, []);

  const handleClickOpen = () => {
    setOpenDialog(!openDialog);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleEdit = (data) => {
    setEditData(data);
    setOpenDialog(true);
  }

  const handleImage = (data) => {
    if (exam_id === null) {
      handleOpenDialog('Warning', 'Please select an exam.', 'warning');
    }
    else {
      setImageData(data);
      setOpenImageHandler(true);
    }
  }

  const handleBiometric = (data, verified, examId) => {
    if (exam_id === null) {
      handleOpenDialog('Warning', 'Please select an exam.', 'warning');
    }
    else {
      setVerified(verified)
      setBiometricData(data)
      setOpenBiometricHandler(true)
      getIsFirstStage(examId)
    }
  }
  const handleIris = (data, verified, examId) => {
    if (exam_id === null) {
      handleOpenDialog('Warning', 'Please select an exam.', 'warning');
    }
    else {
      setVerified(verified)
      setIrisData(data)
      setOpenIrisHandler(true)
      getIsFirstStage(examId)
    }
  }

  const getIsFirstStage = (examId) => {
    const selectedExam = examList.find(exam => exam.id === examId);
    if (selectedExam) {
      setFirstStage(Boolean(selectedExam.isFirstStage));
    }
    else {
      setFirstStage(false);
    }
  };

  const handleBiometricClose = () => {
    setOpenBiometricHandler(false)
    setIsChanged(!isChanged);
  }
  const handleIrisClose = () => {
    setOpenIrisHandler(false)
    setIsChanged(!isChanged);
  }

  const onClose = () => {
    setOpenImageHandler(false)
    setIsChanged(!isChanged);
  }

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle('Alert');
    setDialogContent('');
    setButtonType(null);
    setOpenExcelDialog(false)
    setExamName(null)
    setCenterName(null)
    setExcelDialog(false)
  };

  const handleExcelUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    try {
      await validateExcelFile(file, examName, centerName);
      const data = await readExcelFile(file);
      setExcelData(data);
      setOpenExcelDialog(true)
    }
    catch (error) {
      console.log(error);
      handleOpenDialog('Error', createErrorMessage(error.errorFileUrl, "Some Error Occured"), 'error');
    }
  };

  const handleExcelDialog = () => {
    setExcelDialog(!excelDialog)
  }

  const createErrorMessage = (errorFileUrl, heading) => (
    <Box textAlign="center">
      <Typography variant="body1">{heading}</Typography>
      <Typography variant="body1">
        <Link href={errorFileUrl} download="error_log.txt">Click here</Link> for more info.
      </Typography>
    </Box>
  )

  const handleDownload = () => {
    const instructions = (
      <div>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Download Instructions:</Typography>
        <List>
          <ListItem>
            <ListItemText primary={<Typography variant="body1"><strong>File Name:</strong> The file name should include 'candidateRegistrationTemplate'.</Typography>} />
          </ListItem>
          <ListItem>
            <ListItemText primary={<Typography variant="body1"><strong>File Extension:</strong> Ensure the file has the '.xlsx' extension.</Typography>} />
          </ListItem>
          <ListItem>
            <ListItemText primary={<Typography variant="body1"><strong>Header Integrity:</strong> Do not change the headers in the file.</Typography>} />
          </ListItem>
          <ListItem>
            <ListItemText primary={<Typography variant="body1"><strong>Row Limit:</strong> The number of rows in the file should not exceed 1000.</Typography>} />
          </ListItem>
        </List>
      </div>
    );
    setExamName(null)
    setCenterName(null)
    setExcelDialog(false)
    handleOpenDialog('Info', instructions, 'primary');
    handleExcelDownload(examName, centerName)
  }

  const handleExamChange = (e) => {
    const selectedExamObject = e.target.value;
    setExamName(selectedExamObject)
  };

  const handleCenterChange = (e) => {
    const selectedCenterObject = e.target.value;
    setCenterName(selectedCenterObject)
  };

  const handleSubmit = async () => {
    try {
      const formattedExcel = await formatExcelData(excelData, examName, user_id);
      const response = await configServ.bulkAddStudent(formattedExcel);
      if (response.status === 200) {
        setExcelData(null)
        if (response.duplicateRegNo && response.duplicateRegNo.length > 0) {
          const message = "Some registration numbers have already been registered for the same exam and have been skipped. Please review the following registration numbers:";
          const errorFileUrl = createErrorLogFile({ duplicateRegistrationNumbers: response.duplicateRegNo }, message);
          handleOpenDialog("Warning", createErrorMessage(errorFileUrl, "Upload Successful! Please Review: Duplicate Registration Numbers Detected for the Same Exam. These Registrations Have Been Skipped."), "secondary")
        }
        else {
          handleOpenDialog('Success', 'File Uploaded Successfully', 'success');
        }
      }
      else {
        handleOpenDialog('Error', 'Failed to upload file', 'error');
      }
    }
    catch (error) {
      console.error('Error:', error);
      handleOpenDialog('Error', 'Failed to upload file', 'error');
    }
    finally {
      setOpenExcelDialog(false);
    }
  };

  const fetchExams = async () => {
    try {
      const response = await configServ.getItemList();
      if (response.status === 200) {
        setExamList(response.data);
      }
    }
    catch (error) {
      console.error("Error fetching exams:", error);
    }
  };

  const fetchCentres = async () => {
    try {
      const response = await configServ.getAllCompanyList();
      if (response.status === 200) {
        setCentreList(response.data);
      }
    }
    catch (error) {
      console.error("Error fetching centers:", error);
    }
  };

  const handleCloseExcelDialog = () => {
    setExcelDialog(false)
    setExamName(null)
    setCenterName(null)
  }

  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />
      <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'Registration & Attendance'} handlePopup={handleClickOpen} create={openDialog} hideAddButton={!CheckAndReturn.PermissionHandler('create-student', permissions)} from={"RegCandidate"} handleExcelDialog={handleExcelDialog} />
      <CustomerCard handleClose={handleClose} open={openDialog} isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} from={'registeredCandidates'} handleDownload={handleDownload} handleExcelUpload={handleExcelUpload} excelData={excelData} />
      <CustomersTable handleEdit={handleEdit} handleImage={handleImage} handleBiometric={handleBiometric} handleIris={handleIris} isChanged={isChanged} examId={setExam_id} />
      <ExcelDialogBox open={excelDialog} onClose={handleCloseExcelDialog} handleDownload={handleDownload} handleExcelUpload={handleExcelUpload} examList={examList} centreList={centreList} examName={examName} centerName={centerName} handleExamChange={handleExamChange} handleCenterChange={handleCenterChange} />
      {
        excelData &&
        <ExcelDataPreview open={openExcelDialog} handleClose={handleCloseDialog} excelData={excelData} handleSubmit={handleSubmit} examList={examList} examName={examName} centerName={centerName} handleExamChange={handleExamChange} handleCenterChange={handleCenterChange} />
      }
      <ImageUpload open={openImageHandler} imageData={imageData} onClose={onClose} exam_id={exam_id} />
      <Biometric open={openBiometricHandler} biometricData={biometricData} onClose={handleBiometricClose} verified={verified} exam_id={exam_id} firstStage={firstStage} match={true} />
      <Iris open={openIrisHandler} irisData={irisData} onClose={handleIrisClose} exam_id={exam_id} verified={verified} firstStage={firstStage} />
    </>
  )
}
export default Customers