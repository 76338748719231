import React, { useState } from 'react';
import { Grid, Typography, Button, CircularProgress, TextField } from '@mui/material';

//FUNCTION
const ExistingFingerprint = ({ existingImage, handleMatchFinger, handleClose, loading, verified, primaryFingerPrint, secondaryFingerPrint, firstStage, match, isTested, isMatched, handleUnMatchedFingerprintUpload, remark, handleRemark }) => {

    const formatDateTime = (dateTime) => {
        if (!dateTime) return 'Not available';
        const date = new Date(dateTime);
        const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true });
        return `${formattedDate} - ${time}`;
    };
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} align="center" style={{ margin: '1rem' }}>
                <Typography variant="h4" gutterBottom>
                    Primary Fingerprint
                </Typography>
            </Grid>
            <Grid item xs={6} style={{ margin: '1rem', padding: '0.5rem', maxWidth: '10rem' }}>
                <img src={existingImage} alt="Existing" style={{ width: '100%' }} />
            </Grid>
            <Grid container spacing={1} item xs={12} align="center" style={{ marginBottom: '.5rem' }}>
                <Grid container spacing={1} item xs={12} justifyContent={'flex-start'}>
                    <Grid item xs={12} justifyContent={'flex-start'} style={{ marginBottom: '0.5rem' }}>
                        <Typography variant="body2">
                            <b>CAPTURED</b>: {primaryFingerPrint.capturedAt}
                        </Typography>
                    </Grid>
                    {
                        (primaryFingerPrint.remark !== null && primaryFingerPrint.remark !== undefined) &&
                        <Grid item xs={12} justifyContent={'flex-start'} style={{ marginBottom: '0.5rem' }}>
                            <Typography variant="body2">
                                <b>REMARK</b>: {primaryFingerPrint.remark}
                            </Typography>
                        </Grid>
                    }
                    {(verified === true) &&
                        (
                            <Grid item xs={12} justifyContent={'center'} style={{ marginBottom: '0.5rem' }}>
                                <Typography variant="body2">
                                    <b>VERIFIED</b>: {formatDateTime(secondaryFingerPrint.created_at)}
                                </Typography>
                            </Grid>
                        )
                    }
                    {
                        (secondaryFingerPrint.remark !== null && secondaryFingerPrint.remark !== undefined) &&
                        <Grid item xs={12} justifyContent={'flex-start'} style={{ marginBottom: '0.5rem' }}>
                            <Typography variant="body2">
                                <b>REMARK</b>: {secondaryFingerPrint.remark}
                            </Typography>
                        </Grid>
                    }
                </Grid>
                {match &&
                    (isTested === false ?
                        (
                            (!firstStage && !verified) &&
                            <>
                                <Grid container>
                                    <Grid container item xs={6} md={6} justifyContent={'flex-end'}>
                                        <Button onClick={handleMatchFinger} disabled={loading} variant="contained" color="primary">
                                            {loading ? <CircularProgress size={24} /> : "Match"}
                                        </Button>
                                    </Grid>
                                    <Grid container item xs={6} md={6} justifyContent={'flex-start'}>
                                        <Button onClick={handleClose} variant="contained" color="error" sx={{ml:1}}>
                                            Close
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        )
                        :
                        (
                            isMatched === false ?
                                <>
                                    <Grid container justifyContent={'center'}>
                                        <Grid item xs={6}>
                                            <p style={{color:'red'}}>Does not match.</p>
                                        </Grid>
                                        <Grid item xs={8} mb={1}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={2}
                                                name='remark'
                                                variant='outlined'
                                                value={remark || ""}
                                                placeholder='Enter comment here.'
                                                onChange={(e) => handleRemark(e.target.value)}
                                                error={!!(remark === null)} />
                                        </Grid>
                                        <Grid container item xs={6} md={6} justifyContent={'flex-end'}>
                                            <Button onClick={handleUnMatchedFingerprintUpload} disabled={loading} variant="contained" color="primary" sx={{ mr: 1 }}>
                                                {loading ? <CircularProgress size={24} /> : "Upload"}
                                            </Button>
                                        </Grid>
                                        <Grid container item xs={6} md={6} justifyContent={'flex-start'}>
                                            <Button onClick={handleClose} variant="contained" color="error">
                                                Close
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                ""
                        )
                    )
                }
                {
                    (match === false || verified === true) &&
                    <Grid item xs={6} md={12} justifyContent={'center'}>
                        <Button onClick={handleClose} variant="contained" color="error">
                            Close
                        </Button>
                    </Grid>
                }
            </Grid>
        </Grid>

    );
};

export default ExistingFingerprint;
