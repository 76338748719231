import React, { useState, useEffect } from "react";
import Header from "../../Components/header/Header/Header";
import MiniDrawer from "../../Components/sidebar/SideBar";
import { useSelector } from "react-redux";
import InvoiceTable from "../../Components/Card/InvoiceCards/InvoiceTable";
import { Container } from "react-bootstrap";
import axios from "axios";
import configServ from "../../services/config";
import LocationTable from "./LocationTable";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";
import { useNavigate } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import {
    Grid,
    TextField,
    Box,
    Typography,
    Stack,
    Paper,
    Button,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Tooltip,
    IconButton,
    Autocomplete
} from "@mui/material";
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import City from "./City";


const Location = () => {
    //#region code
    const { user_id } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open);
    const { company_type } = useSelector((state) => state.admin);
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        status: true
    })
    const [cityOpen, setCityOpen] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [country, setCountry] = useState('');
    const [stateList, setStateList] = useState([]);
    const [stateId, setStateId] = useState([]);
    const [state, setState] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [cityId, setCityId] = useState([]);
    const [city, setCity] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const fetchCountry = async () => {
        try {
            const res = await configServ.getCountry();
            if (res.status === 200) {
                setCountryList(res.data);
            }
            else {
                handleOpenDialog(res.message || "An error occurred.")
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchCountry();
    }, []);

    const fetchState = async () => {
        try {
            const res = await configServ.getState({ "country_id": countryId });
            if (res.status === 200) {
                setStateList(res.data);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchState();
    }, [countryId]);

    const fetchCity = async () => {
        try {
            const res = await configServ.getCity({ "state_id": stateId });
            if (res.status === 200) {
                setCityList(res.data);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    // const fetchDistrict = async () => {
    //     try {
    //         const res = await configServ.getDistrictByState({ "state_id": stateId });
    //         if (res.status === 200) {
    //             setCityList(res.data);
    //         }
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    useEffect(() => {
        fetchCity();
    }, [stateId]);

    const handleCountryStateCity = async (params) => {
        try {
            const { name, value } = params.target;
            if (name === 'country_id') {
                setStateList([]);
                setCountryId(value);
                setFormData((prev) => ({
                    ...prev,
                    [name]: value
                }));
            }
            else if (name === 'state_id') {
                setCityList([]);
                setStateId(value);
                setFormData((prev) => ({
                    ...prev,
                    [name]: value
                }));
            }
            else if (name === 'city_id') {
                setCityId(value);
                setFormData((prev) => ({
                    ...prev,
                    [name]: value
                }));
            }
            await handleName(name, value);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleName = async (name, value) => {
        try {
            if (name === 'country_id') {
                const countryData = countryList.filter(x => x.id === value)[0];
                const country = countryData.country_name;
                setFormData((prev) => ({
                    ...prev,
                    "country": country
                }));
            }
            else if (name === 'state_id') {
                const stateData = stateList.filter(x => x.id === value)[0];
                const state = stateData.state_name;
                setFormData((prev) => ({
                    ...prev,
                    "state": state
                }));
            }
            else if (name === 'city_id') {
                const cityData = cityList.filter(x => x.id === value)[0];
                const city = cityData.city_name;
                setFormData((prev) => ({
                    ...prev,
                    "city": city
                }));
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    // const handleAutoOnchange = (e, value) => {
    //     const { name, label } = value
    //     setFormData((state) => ({
    //         ...state,
    //         [name]: label
    //     }));
    // }

    // const fetchCounties = async () => {
    //     const res = await axios.get('https://countriesnow.space/api/v0.1/countries/capital')
    //     if (!res.error) {
    //         setCountryToList(res.data.data)
    //     }
    // }
    // const setCountryToList = (data) => {
    //     if (data.length === 0) {
    //         return;
    //     }
    //     data.map((item) => {
    //         setCountryList((state) => ([
    //             ...state,
    //             {
    //                 // ...item,
    //                 label: item.name,
    //                 name: 'country',
    //             }
    //         ]))
    //     })
    // }

    // const fetchStates = async () => {
    //     const res = await axios.post('https://countriesnow.space/api/v0.1/countries/states', { "country": `${formData.country}` })
    //     if (!res.error) {
    //         // setStateList(res.data.data.states)
    //         setStatesToList(res.data.data.states)
    //         // console.log(res.data.data.states)
    //     }
    // }
    // const setStatesToList = (data) => {
    //     if (data.length === 0) {
    //         return;
    //     }
    //     data.map((item) => {
    //         setStateList((state) => ([
    //             ...state,
    //             {
    //                 // ...item,
    //                 label: item.name,
    //                 name: 'state',
    //             }
    //         ]))
    //     })
    // }

    // const fetchCities = async () => {
    //     const res = await axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', { "country": `${formData.country}`, "state": `${formData.state}` })
    //     if (!res.error) {
    //         // setCityList(res.data.data)
    //         setCitiesToList(res.data.data)
    //         // console.log(res.data.data)
    //     }
    // }
    // const setCitiesToList = (data) => {
    //     if (data.length === 0) {
    //         return;
    //     }
    //     data.map((item) => {
    //         setCityList((state) => ([
    //             ...state,
    //             {
    //                 // ...item,
    //                 label: item,
    //                 name: 'state',
    //             }
    //         ]))
    //     })
    // }

    const handleSubmit = async () => {
        const dataToSend = {
            ...formData,
            admin_id: user_id,
            company_type: company_type
        }
        try {
            if (!isEdit) {
                const result = await configServ.addLocation(dataToSend);
                setIsChanged(!isChanged);
                clear();
                handleOpenDialog(result.message);
            }
            else {
                const result = await configServ.editLocation(dataToSend);
                setIsChanged(!isChanged);
                clear();
                handleOpenDialog(result.message);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const clear = () => {
        setFormData({
            status: true
        });
        setCountryId('');
        setStateId('');
        setCityId('');
        setStateList([]);
        setCityList([]);
        if (isEdit === true) {
            setIsCreate(false);
        }
        setIsEdit(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    // useEffect(() => {
    //     fetchCounties()
    // }, []);

    // useEffect(() => {
    //     if (formData.country !== undefined) {
    //         fetchStates()
    //     }
    // }, [formData]);

    // useEffect(() => {
    //     if (formData.state !== undefined) {
    //         fetchCities()
    //     }
    // }, [formData]);

    const handleEditData = async (data) => {
        setIsCreate(true);
        if (data[0].country_id) {
            setCountryId(data[0].country_id);
            setCountry(data[0].country);
        }
        if (data[0].state_id) {
            setStateId(data[0].state_id);
            setState(data[0].state);
        }
        setFormData(data[0]);
        setIsEdit(true);
    }

    const handleEditCountryStateCity = async (params) => {
        try {
            if (params.country) {
                const countryData = countryList.filter(x => x.country_name.toLowerCase() === params.country.toLowerCase())[0];
                setCountryId(countryData.id);
                setCountry(countryData.country_name);
            }
            else if (params.state) {
                const stateData = stateList.filter(x => x.state_name.toLowerCase() === params.state.toLowerCase())[0];
                setStateId(stateData.id);
                setState(stateData.state_name);
            }
            else if (params.city) {
                const cityData = cityList.filter(x => x.city_name.toLowerCase() === params.state.toLowerCase())[0];
                setCityId(cityData.id);
                setCity(cityData.city_name);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handlePopup = () => {
        try {
            setIsCreate(!isCreate);
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleCityOpen = () => {
        try {
            setCityOpen(!cityOpen);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleCityClose = () => {
        setCityOpen(false);
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'Locations'} handlePopup={handlePopup} create={isCreate} />

            <City open={cityOpen} handleClose={handleCityClose} />

            {isCreate && <>

                <Container>
                    <div
                        style={{
                            width: '100%',
                            backgroundColor: '#e3f3fc',
                            //backgroundImage: 'linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%)',
                            padding: 20,
                            borderRadius: '10px',
                            border: 'none',
                            margin: '10px',
                        }}
                    >
                        <Grid container alignItems={'center'}>
                            <Button size={'small'} variant="contained" startIcon={<AddIcon />} onClick={handleCityOpen}>
                                ADD DISTRICT
                            </Button>
                        </Grid>
                        <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10 }} spacing={2} >
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    label="Location Name"
                                    variant="outlined"
                                    name='location_name'
                                    type="text"
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white' }}
                                    onChange={handleChange}
                                    value={formData.location_name || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    label="Address"
                                    variant="outlined"
                                    name='address'
                                    type="text"
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white' }}
                                    onChange={handleChange}
                                    value={formData.address || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    label="Latitude"
                                    variant="outlined"
                                    name='latitude'
                                    type="text"
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white' }}
                                    onChange={handleChange}
                                    value={formData.latitude || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    label="Longitude"
                                    variant="outlined"
                                    name='longitude'
                                    type="text"
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white' }}
                                    onChange={handleChange}
                                    value={formData.longitude || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel size='small' id="demo-simple-select-label" >Country</InputLabel>
                                    <Select
                                        size='small'
                                        labelId="demo-simple-select-label"
                                        variant="outlined"
                                        id="country_id"
                                        name='country_id'
                                        value={formData.country_id || ''}
                                        label="Country"
                                        sx={{ backgroundColor: 'white' }}
                                        onChange={handleCountryStateCity}
                                    //error={!!validationErrors.country_id}
                                    >
                                        {countryList.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>{item.country_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {/* <Autocomplete
                                                options={countryList}
                                                size="small"
                                                value={formData.country || ''}
                                                onChange={handleAutoOnchange}
                                                sx={{ backgroundColor: 'white' }}
                                                renderInput={(params) => <TextField {...params} label="Country" />}
                                            /> */}
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel size='small' id="demo-simple-select-label" >State</InputLabel>
                                    <Select
                                        size='small'
                                        labelId="demo-simple-select-label"
                                        variant="outlined"
                                        id="state_id"
                                        name='state_id'
                                        value={formData.state_id || ''}
                                        sx={{ backgroundColor: 'white' }}
                                        label="State"
                                        onChange={handleCountryStateCity}
                                    //error={!!validationErrors.state_id}
                                    >
                                        {stateList.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>{item.state_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {/* <Autocomplete
                                                options={stateList}
                                                size="small"
                                                value={formData.state || ''}
                                                onChange={handleAutoOnchange}
                                                sx={{ backgroundColor: 'white' }}
                                                renderInput={(params) => <TextField {...params} label="State" />}
                                            /> */}
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <TextField
                                    label="City"
                                    variant="outlined"
                                    name='city'
                                    type="text"
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white' }}
                                    onChange={handleChange}
                                    value={formData.city || ''}
                                />
                                {/* <FormControl fullWidth>
                                                <InputLabel size='small' id="demo-simple-select-label" >City</InputLabel>
                                                <Select
                                                    size='small'
                                                    labelId="demo-simple-select-label"
                                                    variant="outlined"
                                                    id="city_id"
                                                    name='city_id'
                                                    value={formData.city_id || ''}
                                                    sx={{ backgroundColor: 'white' }}
                                                    label="City"
                                                    onChange={handleCountryStateCity}
                                                >
                                                    {cityList.map((item, index) => (
                                                        <MenuItem key={index} value={item.id}>{item.city_name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl> */}
                                {/* <FormControl fullWidth size='small'>
                                                <InputLabel>City</InputLabel>
                                                <Select required name='city' label='select' value={city || ''} onChange={handleCountryStateCity} sx={{ backgroundColor: 'white' }}>
                                                    {cityList.map((item, index) => {
                                                        return (
                                                            <MenuItem key={index} value={item.id}>{item.city_name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl> */}
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <TextField
                                    label="Pin"
                                    variant="outlined"
                                    name='pin'
                                    type="number"
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white' }}
                                    onChange={handleChange}
                                    value={formData.pin || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel>Company Type</InputLabel>
                                    <Select name='company_type' label='select' value={formData.company_type || ''} onChange={handleChange} sx={{ backgroundColor: 'white' }}>
                                        <MenuItem value='product'>product</MenuItem>\
                                        <MenuItem value='service'>service</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} md={9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Status"
                                    name="status"
                                    onChange={handleChangeCheckbox}
                                    checked={Boolean(formData.status || '')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} >
                                <Stack
                                    // border={1}
                                    direction={{ xs: 'row', sm: 'row' }}
                                    justifyContent={isEdit ? 'space-between' : 'flex-end'}
                                    alignItems={'center'}
                                >
                                    <Button
                                        variant="contained"
                                        color="info"
                                        onClick={() => { navigate('advance', { state: formData }) }}
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: 'auto'
                                            },
                                            marginRight: '10px'
                                        }}
                                        hidden={isEdit ? false : true}
                                    >
                                        Advance Options
                                    </Button>
                                    <Stack width={{ xs: '100%', sm: 'auto' }} direction={{ xs: 'row', sm: 'row' }}>
                                        <Button
                                            variant="contained"
                                            color={isEdit ? 'error' : 'secondary'}
                                            onClick={clear}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '7rem'
                                                },
                                                marginRight: '10px'
                                            }}
                                        >
                                            {isEdit ? <CloseIcon sx={{ marginRight: '5px' }} /> : <RotateLeftIcon sx={{ marginRight: '5px' }} />}
                                            {isEdit ? 'Close' : 'Reset'}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={handleSubmit}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '7rem'
                                                }
                                            }}
                                        >
                                            {isEdit ? <UpdateIcon sx={{ marginRight: '10px' }} /> : <SaveIcon sx={{ marginRight: '10px' }} />}
                                            {isEdit ? ' Update' : ' Save'}
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </>}

            <Divider />
            <br />
            <LocationTable handleEditData={handleEditData} isChanged={isChanged} />
        </>

    )

}

export default Location;