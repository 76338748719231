import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PreviewIcon from '@mui/icons-material/Preview';
const CaptureOrUploadIris = ({
  open,
  handleClose,
  iris,
  captured,
  capturing,
  handleCaptureIris,
  handlePrimaryIrisUpload,
}) => {
 

  return (
    <>
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={6} md={4}> 
        <Grid container justifyContent="center" alignItems="center" style={{ borderRadius: '45%' }}>
          {iris ? (
            <img
              src={`data:image/jpeg;base64,${iris.BitmapData}`}
              alt="iris"
              style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '50%' }}
            />
          ) : (
            <PreviewIcon fontSize="large" style={{ height: '10rem', width: '10rem' }} />
          )}
        </Grid>
      </Grid>
    </Grid>
    <Grid container justifyContent="center" style={{ marginTop: '16px' }}>
      <Grid item>
        <Button variant="contained" color="primary" onClick={captured ? handlePrimaryIrisUpload : handleCaptureIris}>
          {captured ? 'Upload' : 'Capture'}
        </Button>
      </Grid>
      <Grid item style={{ marginLeft: '8px' }}>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Close
        </Button>
      </Grid>
    </Grid>
  </>
  );
};

export default CaptureOrUploadIris;
