import React, { memo, useEffect, useState } from 'react';
import SupplierCard from './SupplierCard';
import SupplierTable from './SupplierTable';
import { Dialog, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import configServ from '../../services/config';

//FUNCTION
const Supplier = ({role}) => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);

    const [supplierList, setSupplierList] = useState([])
    const [isChanged, setIsChanged] = useState(false)
    const [editData, setEditData] = useState({})

    const fetchSuppplierList = async () => {
        try {
            const result = await configServ.getSuppliers({ "company_id": company_id });
            setSupplierList(result.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchSuppplierList()
    }, [isChanged]);

    const handleEdit = (data) => {
        setEditData(data);
    }
    //#endregion

  return (
    <>
        <SupplierCard isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} role={role}/>
        <SupplierTable data={supplierList} handleEdit={handleEdit} />
    </>
  )
}

export default memo(Supplier);
