import React, { useState, useEffect, useRef } from "react";
import RegistrationTable from "../../Components/Card/RegistrationCards/RegistrationTable";
import configServ from "../../services/config";
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Autocomplete, Grid, RadioGroup, TextField, Button, FormHelperText, Input, DialogContent, DialogTitle, DialogActions, Dialog } from '@mui/material';
import { FormControl } from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import UpdateIcon from '@mui/icons-material/Update';
import {
    Typography,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { useSelector } from 'react-redux';
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import CheckAndReturn from "../../utility/CheckAndReturn";

//FUNCTION
const Registration = () => {
    //#region code
    const { user_id, is_super, permissions } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const initialFormData = {
        CompanyType: 'service',
        country: 'India',
        is_active: true,
        EmailNotification: false
    }
    const [formData, setFormData] = useState(initialFormData);
    const [validationErrors, setValidationErrors] = useState({});
    //const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [districtList, setDistrictList] = useState([])
    const [selectedImage, setSelectedImage] = useState(null)
    //const [adminList, setAdminList] = useState([])
    const [isChanged, setIsChanged] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editData, setEditData] = useState({})
    const [isCreate, setIsCreate] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);
    const [companyImage, setCompanyImage] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const fileInputRef = useRef(null);
    //const subscriptionOptions = ['Yearly', 'Monthly', 'Trial', 'Daily'];
    const [cannotAdd, setCannotAdd] = useState(true);
    const [cannotUpdate, setCannotUpdate] = useState(true);

    const checkPermissions = () => {
        try {
            const isAdd = CheckAndReturn.PermissionHandler('create-center', permissions);
            setCannotAdd(!isAdd);
            const isUpdate = CheckAndReturn.PermissionHandler('update-center', permissions);
            setCannotUpdate(!isUpdate);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    const changeHandler = async (e) => {
        try {
            const { name, value } = e.target;
            // if (name === 'country') {
            //     setFormData((state) => ({
            //         ...state,
            //         [name]: value
            //     }));
            // }
            if (name === 'State') {
                setFormData((state) => ({
                    ...state,
                    [name]: value
                }));
            }
            else {
                setFormData((state) => ({
                    ...state,
                    [name]: value
                }));
            }
            if (name === 'State') {
                if (value !== null) {
                    const stateid = stateList.filter(x => x.state_name === value)[0].id;
                    const res = await configServ.getDistrictByState({ "state_id": stateid });
                    if (res.status === 200) {
                        setDistrictList(res.data);
                    }
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    // const convertToBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const fileReader = new FileReader();
    //         fileReader.readAsDataURL(file);
    //         fileReader.onload = () => {
    //             resolve(fileReader.result);
    //         };
    //         fileReader.onerror = (error) => {
    //             reject(error);
    //         };
    //     });
    // };

    // const convertBase64ToFile = (base64String, fileName = 'file') => {
    //     const byteCharacters = atob(base64String.split(',')[1]);
    //     const byteNumbers = new Array(byteCharacters.length);

    //     for (let i = 0; i < byteCharacters.length; i++) {
    //         byteNumbers[i] = byteCharacters.charCodeAt(i);
    //     }

    //     const byteArray = new Uint8Array(byteNumbers);
    //     const file = new Blob([byteArray], { type: 'application/octet-stream' });
    //     file.name = fileName;

    //     return file;
    // };

    // const removeDataUrlPrefix = (base64String) => {
    //     return base64String.split(',')[1];
    // };

    // const handleChangeFile = async (e) => {
    //     const { name, files } = e.target
    //     const base64Image = await convertToBase64(files[0])
    //     // setSelectedImage(removeDataUrlPrefix(base64Image))
    //     setSelectedImage(base64Image)
    // }

    // const handleChangeFile = async (e) => {
    //     const { name, files } = e.target
    //     setSelectedImage(files[0])
    //     console.log(files[0])
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // let invoicePrefix = 'INVEX';
        // if (formData.InvoicePrefix !== '' && formData.InvoicePrefix !== null && formData.InvoicePrefix !== undefined) {
        //     invoicePrefix = formData.InvoicePrefix;
        // }
        const dataToSend = {
            ...formData,
            //InvoicePrefix: invoicePrefix,
            //CompanyLogo: selectedImage,
            created_by: user_id,
        }
        try {
            const error = handleFormValidation();
            if (error === false) {
                scrollToTop();
                return false;
            }
            setValidationErrors({});

            if (!isEdit) {
                const res = await configServ.addCompany(dataToSend);
                if (res.status === 200) {
                    handleOpenDialog('Success', res.message, 'success');
                    clear();
                    setIsChanged(!isChanged);
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to add center.", 'error');
                }
            }
            else {
                const res = await configServ.editCompany(dataToSend);
                if (res.status === 200) {
                    handleOpenDialog('Success', res.message, 'success');
                    clear();
                    setIsChanged(!isChanged);
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to update center.", 'error');
                }
            }

            // setInvoiceDataList(res || []);
            // setInvoiceList(result || []);
            // setLoading(false);
        }
        catch (error) {
            console.log(error);
            // setInvoiceDataList([]);
            // setInvoiceList([]);
            // setLoading(false);
        }
    }

    // const fetchAdmins = async () => {
    //     try {
    //         const res = await configServ.getAdminList();
    //         if (res.status === 200) {
    //             setAdminList(res.data);
    //         }
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    // const fetchCurrency = async (country) => {
    //     const res = await axios.post('https://countriesnow.space/api/v0.1/countries/currency', { country: country })
    //     if (!res.error) {
    //         return res.data.data.currency;
    //     }
    //     return null;
    // }

    // const fetchCounties = async () => {
    //     const res = await axios.get('https://countriesnow.space/api/v0.1/countries/capital')
    //     if (!res.error) {
    //         setCountryList(res.data.data);
    //     }
    // }

    const fetchState = async () => {
        try {
            const res = await configServ.getStates();
            if (res.status === 200) {
                setStateList(res.data);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        checkPermissions();
        fetchState();
    }, []);

    // useEffect(() => {
    //     if (formData.country !== undefined) {
    //         fetchStates();
    //     }
    // }, [formData.country]);

    // const getImageFormatFromBase64 = (base64String) => {
    //     const mimeRegex = /^data:(image\/\w+);/;
    //     const matches = base64String.match(mimeRegex);
    //     return matches ? matches[1] : null;
    // };

    const handleEditData = async (data) => {
        if (data.length > 0) {
            setFormData(data[0]);
            if (data[0].EmailNotification === "0") {
                setFormData((prev) => ({
                    ...prev,
                    EmailNotification: false
                }));
            }
            else {
                setFormData((prev) => ({
                    ...prev,
                    EmailNotification: true
                }));
            }
            if (data[0].State) {
                const stateid = stateList.filter(x => x.state_name.toLowerCase() === data[0].State.toLowerCase())[0].id;
                const res = await configServ.getDistrictByState({ "state_id": stateid });
                if (res.status === 200) {
                    setDistrictList(res.data);
                }
            }
            setIsEdit(true);
            setIsCreate(true);
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const clear = () => {
        setFormData(initialFormData);
        setValidationErrors({});
        setIsEdit(false);
        scrollToTop();
        setSelectedImage(null);
        setCompanyImage(null);

        // Clear the file input value
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }

    const handlePopup = () => {
        setIsCreate(!isCreate);
    }

    // const handleClose = () => {
    //     setIsCreate(false);
    //     clear();
    // }

    // const handleImageChange = async (event) => {
    //     setOpenDialog(true);
    //     setCompanyImage(event.target.files[0]);
    //     const base64Image = await convertToBase64(event.target.files[0]);
    //     setSelectedImage(base64Image);
    // };

    const handleRemoveImage = () => {
        setCompanyImage(null);
        setSelectedImage(null);
        setOpenDialog(false);

        // Clear the file input value
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    // const handlePreview = () => {
    //     if (selectedImage === null) {
    //         setDialogOpen(true);
    //         setDialogContent('Please choose center logo.');
    //     }
    //     else {
    //         setOpenDialog(true);
    //     }
    // }

    const handleFormValidation = () => {
        const errors = {};
        if (!formData.CompanyName) {
            errors.CompanyName = "required";
        }
        if (!formData.CompanyCode) {
            errors.CompanyCode = "required";
        }
        if (!formData.State) {
            errors.State = "required";
        }
        if (!formData.district) {
            errors.district = "required";
        }
        if (!formData.Pincode) {
            errors.Pincode = "required";
        }
        setValidationErrors(errors);
        return Object.values(errors).every((error) => error === "");
    };
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Preview</DialogTitle>
                <DialogContent>
                    {companyImage && typeof companyImage === 'object' && (
                        <img
                            alt="not found"
                            width="100%"
                            src={URL.createObjectURL(companyImage)}
                        />
                    )}
                    {companyImage && typeof companyImage === 'string' && (
                        <img
                            alt="not found"
                            width="100%"
                            src={`data:image/png;base64,${companyImage}`}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveImage}>Remove</Button>
                    <Button onClick={() => setOpenDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'CENTER'} handlePopup={handlePopup} isEdit={isEdit} create={isCreate} hideAddButton={!CheckAndReturn.PermissionHandler('create-center', permissions)} />

            {isCreate === true &&
                <div style={{ width: "100%", backgroundColor: "#e3f3fc", padding: 20, marginTop: 10, borderRadius: '10px', border: 'none' }}>
                    <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={1}>
                        <Grid item xs={12} md={12} justifyContent={'flex-start'}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Center Detail</Typography>
                        </Grid>
                        {/* <Grid item xs={12} md={3}>
                                        <FormControl fullWidth size='small' error={!!validationErrors.CompanyAdmin}>
                                            <InputLabel>Admin*</InputLabel>
                                            <Select
                                                name='CompanyAdmin'
                                                value={formData.CompanyAdmin || ''}
                                                onChange={changeHandler}
                                                sx={{ backgroundColor: 'white' }}
                                            >
                                                {adminList.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid> */}
                        <Grid item xs={12} md={3}>
                            <TextField
                                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                                label={<span>Center Name<span style={{ color: 'red' }}>*</span></span>}
                                placeholder="Enter center name"
                                name="CompanyName"
                                onChange={changeHandler}
                                value={formData.CompanyName || ''}
                                fullWidth
                                size="small"
                                sx={{ backgroundColor: 'white' }}
                                error={!!validationErrors.CompanyName}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                                label={<span>Center Code<span style={{ color: 'red' }}>*</span></span>}
                                placeholder="Enter center code"
                                name="CompanyCode"
                                type="number"
                                onChange={changeHandler}
                                value={formData.CompanyCode || ''}
                                fullWidth
                                size="small"
                                sx={{ backgroundColor: 'white' }}
                                error={!!validationErrors.CompanyCode}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                                label={<span>Address</span>}
                                placeholder="Enter address"
                                name="Address"
                                onChange={changeHandler}
                                value={formData.Address || ''}
                                fullWidth
                                size="small"
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth size="small" error={!!validationErrors.State}>
                                <InputLabel htmlFor="state">{<span>State<span style={{ color: 'red' }}>*</span></span>}</InputLabel>
                                <Select
                                    disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                                    id="state"
                                    name="State"
                                    value={formData.State || ''}
                                    onChange={(event) => {
                                        const newState = event.target.value;
                                        changeHandler({ target: { name: 'State', value: newState === '' ? null : newState } });
                                    }}
                                    label="State*"
                                    sx={{ backgroundColor: 'white' }}
                                    error={!!validationErrors.State}
                                >
                                    {stateList.map((item) => (
                                        <MenuItem key={item.id} value={item.state_name}>
                                            {item.state_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth size="small" error={!!validationErrors.district}>
                                <InputLabel htmlFor="district">{<span>District<span style={{ color: 'red' }}>*</span></span>}</InputLabel>
                                <Select
                                    disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                                    id="district"
                                    name="district"
                                    value={formData.district || ''}
                                    onChange={(event) => {
                                        const newDistrict = event.target.value;
                                        changeHandler({ target: { name: 'district', value: newDistrict === '' ? null : newDistrict } });
                                    }}
                                    label="District*"
                                    sx={{ backgroundColor: 'white' }}
                                    error={!!validationErrors.district}
                                >
                                    {districtList.map((item) => (
                                        <MenuItem key={item.id} value={item.district_name}>
                                            {item.district_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                                label={<span>Pin Code<span style={{ color: 'red' }}>*</span></span>}
                                placeholder="Enter pincode"
                                name="Pincode"
                                type="number"
                                onChange={changeHandler}
                                value={formData.Pincode || ''}
                                fullWidth
                                size="small"
                                sx={{ backgroundColor: 'white' }}
                                error={!!validationErrors.Pincode}
                            />
                        </Grid>
                        {/* <Grid item xs={12} md={3}>
                                        <FormControl fullWidth size="small" error={!!validationErrors.country}>
                                            <InputLabel htmlFor="country">Country*</InputLabel>
                                            <Select
                                                id="country"
                                                name="country"
                                                value={formData.country || ''}
                                                onChange={(event) => {
                                                    const newCountry = event.target.value;
                                                    changeHandler({ target: { name: 'country', value: newCountry === '' ? null : newCountry } });
                                                }}
                                                label="Country*"
                                                sx={{ backgroundColor: 'white' }}
                                                error={!!validationErrors.country}
                                            >
                                                {countryList.map((item) => (
                                                    <MenuItem key={item.name} value={item.name}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Email"
                                            placeholder="enter email"
                                            name="CompanyEmail"
                                            onChange={changeHandler}
                                            value={formData.CompanyEmail || ''}
                                            fullWidth
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                        />
                                    </Grid>
                                    {<Grid item xs={12} md={5}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Form.Label style={validationErrors.CompanyLogo ? { color: '#d32f2f', fontWeight: 700 } : { color: 'black', fontWeight: 700 }}>Logo*</Form.Label>
                                            <Input
                                                type="file"
                                                name="myImage"
                                                onChange={handleImageChange}
                                                accept=".jpg, .gif, .png, .gif, .jpeg"
                                                sx={{ marginLeft: '10px', marginBottom: '10px' }}
                                                inputRef={fileInputRef}
                                            />
                                            <Button variant="outlined" onClick={handlePreview} sx={{ marginLeft: '10px', backgroundColor: 'white', ':hover': { backgroundColor: 'wheat' } }}>Preview</Button>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Website"
                                            placeholder="https://www.exampla.com"
                                            name="CompanyWebsite"
                                            type="text"
                                            onChange={changeHandler}
                                            value={formData.CompanyWebsite || ''}
                                            fullWidth
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{
                                            borderRadius: '5px', border: validationErrors.CompanyType ? '1px solid red' : '1px solid transparent', // Set border color based on validation error
                                            backgroundColor: 'white', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)'
                                        }}
                                        >
                                            <FormControl component="fieldset" error={validationErrors.CompanyType}>
                                                <FormLabel component="legend" sx={{ fontWeight: 'bolder', marginLeft: '40px' }}>Company Type*</FormLabel>
                                                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" row
                                                    sx={{ marginLeft: '20px' }} name="CompanyType"
                                                    onChange={changeHandler} value={formData.CompanyType || ''}
                                                >
                                                    <FormControlLabel value="product" control={<Radio size='small' />} label="product" />
                                                    <FormControlLabel value="service" control={<Radio size='small' />} label="service" />
                                                    <FormControlLabel value="Both" control={<Radio size='small' />} label="Both" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    </Grid> */}

                        {/* <Grid item xs={12} md={12} justifyContent={'flex-start'} mt={2}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Tax Detail</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Beginning Financial year*"
                                            type="date"
                                            name="FinancialBeginningfrom"
                                            onChange={changeHandler}
                                            value={formData.FinancialBeginningfrom || ''}
                                            fullWidth
                                            InputLabelProps={{ shrink: true, }}
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                            error={!!validationErrors.FinancialBeginningfrom}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Autocomplete
                                            id="currency"
                                            options={CurrencyList}
                                            getOptionLabel={(item) => (item ? item.code : '')}
                                            value={formData.currency}
                                            onChange={(_, newValue) => {
                                                changeHandler({ target: { name: 'currency', value: newValue === null ? '' : newValue } });
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Currency"
                                                sx={{ backgroundColor: 'white' }} fullWidth size="small"
                                                value={formData.currency}
                                            />}
                                        />
                                        <FormControl fullWidth size="small">
                                            <InputLabel htmlFor="currency">Currency</InputLabel>
                                            <Select
                                                id="currency"
                                                value={formData.currency || ''}
                                                onChange={(event) => {
                                                    const newValue = event.target.value;
                                                    changeHandler({ target: { name: 'currency', value: newValue === '' ? null : newValue } });
                                                }}
                                                label="Currency"
                                                sx={{ backgroundColor: 'white' }}
                                            >
                                                {CurrencyList.map((item) => (
                                                    <MenuItem key={item.code} value={item.code}>
                                                        {item.code}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth size="small" error={!!validationErrors.SubscriptionStatus}>
                                            <InputLabel htmlFor="subscription-status">Subscription Status*</InputLabel>
                                            <Select
                                                id="subscription-status"
                                                name="SubscriptionStatus"
                                                value={formData.SubscriptionStatus || ''}
                                                onChange={(event) => {
                                                    const newSubscriptionStatus = event.target.value;
                                                    changeHandler({ target: { name: 'SubscriptionStatus', value: newSubscriptionStatus === '' ? null : newSubscriptionStatus } });
                                                }}
                                                label="Subscription Status*"
                                                sx={{ backgroundColor: 'white' }}
                                                error={!!validationErrors.SubscriptionStatus}
                                            >
                                                {subscriptionOptions.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="GSTIN/UIN"
                                            placeholder="Enter GSTIN/UIN"
                                            name="tax"
                                            onChange={changeHandler}
                                            value={formData.tax || ''}
                                            fullWidth
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Pan No/Tin No"
                                            placeholder=" Enter Pan No/Tin No"
                                            name="CompanyPan_tin_no"
                                            onChange={changeHandler}
                                            value={formData.CompanyPan_tin_no || ''}
                                            fullWidth
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Invoice Prefix"
                                            placeholder="INVEX"
                                            name="InvoicePrefix"
                                            type="text"
                                            onChange={changeHandler}
                                            value={formData.InvoicePrefix || ''}
                                            fullWidth
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="CIN"
                                            placeholder="Enter CIN"
                                            name="CIN"
                                            type="text"
                                            onChange={changeHandler}
                                            value={formData.CIN || ''}
                                            fullWidth
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="TAN"
                                            placeholder="Enter TAN"
                                            name="tan"
                                            type="text"
                                            onChange={changeHandler}
                                            value={formData.tan || ''}
                                            fullWidth
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} justifyContent={'flex-start'} mt={2}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Additional Detail</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Declaration"
                                            placeholder="Enter declaration"
                                            name="declaration"
                                            multiline
                                            rows={3}
                                            onChange={changeHandler}
                                            value={formData.declaration || ''}
                                            fullWidth
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Terms & Conditions"
                                            placeholder="Enter Terms & Conditions"
                                            name="term_conditions"
                                            multiline
                                            rows={3}
                                            onChange={changeHandler}
                                            value={formData.term_conditions || ''}
                                            fullWidth
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl>
                                            <FormLabel id="demo-radio-buttons-group-label">Company Type*</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                name="CompanyType"
                                                onChange={changeHandler}
                                                value={formData.CompanyType || ''}
                                            >
                                                <FormControlLabel value="product " control={<Radio size='small' />} label="product" />
                                                <FormControlLabel value="service" control={<Radio size='small' />} label="service" />
                                                <FormControlLabel value="Both" control={<Radio size='small' />} label="Both" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl disabled>
                                            <FormLabel id="demo-radio-buttons-group-label">Subscription Type</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                name="SubscriptionType"
                                                onChange={changeHandler}
                                                value={formData.SubscriptionType || ''}
                                            >
                                                <FormControlLabel value="single " control={<Radio size='small' />} label="Single" />
                                                <FormControlLabel value="hybrid" control={<Radio size='small' />} label="Hybrid" />
                                                <FormControlLabel value="integrated" control={<Radio size='small' />} label="Integrated " />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Form.Label> <Form.Check onChange={handleChangeCheckbox} name="EmailNotification" checked={formData.EmailNotification} inline aria-label="option 1" size={100} />Send Notification</Form.Label>
                                    </Grid> */}

                        {/* <div hidden={formData.EmailNotification ? false : true}>
                                        <Stack sx={{
                                            backgroundColor: 'white',
                                            padding: 2,
                                            mt: 2,
                                            borderRadius: 1,
                                            gap: 2
                                        }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={3}>
                                                    <Typography>
                                                        Email(cc)
                                                    </Typography>
                                                    <TextField name='Company_cc' value={formData.Company_cc} onChange={changeHandler}
                                                        fullWidth size='small' label="Enter address" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography>
                                                        Message
                                                    </Typography>
                                                    <TextField
                                                        name='Message' value={formData.Message}
                                                        onChange={changeHandler}
                                                        fullWidth
                                                        size='small'
                                                        multiline
                                                        label="Leave a comment here"
                                                        variant="outlined"
                                                        rows={3}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <Typography component={'p'} sx={{ color: 'blue' }}>
                                                        Note:<br />
                                                        1. If you don't want to send Company Creation/updation mail, uncheck the Send Notification checkbox.<br />
                                                        2. Email(cc) separate email IDs with a comma (,)
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </div> */}

                        <Grid container alignItems="flex-end" justifyContent="flex-start" marginTop={'10px'} spacing={1}>
                            <Grid item xs={3} md={8}>
                                <Form.Label> <Form.Check inline name="is_active" checked={formData.is_active} onChange={handleChangeCheckbox} size={100} /> Status</Form.Label>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Button
                                    disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                                    fullWidth
                                    variant="contained"
                                    onClick={handleSubmit}
                                    startIcon={isEdit ? <UpdateIcon /> : <HowToRegIcon />}
                                >
                                    {isEdit ? 'Update' : 'Save'}
                                </Button>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Button
                                    disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                                    fullWidth
                                    variant="contained"
                                    color={'secondary'}
                                    onClick={clear}
                                    startIcon={<UpdateIcon />}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            }

            <RegistrationTable handleEditData={handleEditData} isChanged={isChanged} />
        </>
    )
}
export default Registration;