import * as XLSX from "xlsx";
import Calculation from "../../../utility/Calculations";
import { useSelector } from "react-redux";

export const handleExcelDownload = async (exam, center) => {
  const headers = [
    //"studentUniqueId",
    "registrationNo",
    "rollNo",
    //"fullName",
    //"photoId",
    "centreCode",
    //"centreName",
    "examCode",
    //"examName",
  ];

  const currentDate = Calculation.getCurrentDate();

  // Pre-determined headers
  const filename = "candidateEnrollmentTemplate" + currentDate + ".xlsx"; // Filename for the downloaded template file

  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Create a worksheet with headers only
  const ws = XLSX.utils.aoa_to_sheet([headers]);

  // // Set data on the second row in respective columns (assuming exam and center objects are available)
  //   const dataRow = 2; // Second row (index starts at 0)

  // ws[`H${dataRow}`] = { t: 's', v: center.CompanyCode || 'n' }; // Insert centerCode
  //   ws[`G${dataRow}`] = { t: "s", v: center.CompanyName || "n" }; // Insert centerName
  // ws[`I${dataRow}`] = { t: 's', v: exam.item_code || 'n' }; // Insert examCode
  // ws[`J${dataRow}`] = { t: 's', v: exam.item_name || 'n' }; // Insert examName

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Save the workbook as a file
  XLSX.writeFile(wb, filename);
};

// const prefill = async (ws, exam, center, rows) => {
//   // Pre-fill first 1000 rows with examCode, centerCode, examName, and centerName
//   for (let i = 1; i <= rows; i++) {
//     // Assuming exam and center are objects with item_code, item_name, and companyCode, CompanyName properties respectively
//     if (exam) {
//       ws[`H${i}`] = { t: "s", v: exam.item_code || "" }; // Insert examCode
//       ws[`I${i}`] = { t: "s", v: exam.item_name || "" }; // Insert examName
//     }
//     if (center) {
//       ws[`F${i}`] = { t: "s", v: center.companyCode || "" }; // Insert centerCode
//       ws[`G${i}`] = { t: "s", v: center.CompanyName || "" }; // Insert centerName
//     }
//   }
// };

// Function to validate the file name
const validateFileName = (file) => {
  if (!file.name.includes("candidateEnrollmentTemplate")) {
    return `File name should inclue candidateEnrollmentTemplate in xlsx file. Current file name: ${file.name}`;
  }
  return null;
};

// Function to validate the file type
const validateFileType = (file) => {
  const expectedFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  if (file.type !== expectedFileType) {
    return `File should have the .xlsx extension. Current file type: ${file.type}`;
  }
  return null;
};

// Function to validate headers
const validateHeaders = (jsonData, headers) => {
  const headerErrors = [];
  const headerCells = jsonData[0];
  headerCells.forEach((headerCell, index) => {
    if (headerCell !== headers[index]) {
      headerErrors.push(`Header '${headerCell}' should be '${headers[index]}'`);
    }
  });
  return headerErrors;
};

// Function to find duplicate registration numbers
const findDuplicates = (registrationNumbers) => {
  const seen = {};
  const duplicates = [];
  registrationNumbers.forEach((regNo) => {
    if (seen[regNo]) {
      duplicates.push(regNo);
    } else {
      seen[regNo] = true;
    }
  });
  return duplicates;
};

// Function to create error log file with a custom message
export const createErrorLogFile = (errors, message) => {
  let errorText = message ? `${message}\n\n` : "";
  errorText += formatErrors(errors);
  const errorBlob = new Blob([errorText], { type: "text/plain" });
  return URL.createObjectURL(errorBlob);
};

const formatErrors = (errors) => {
  const formattedErrors = {};

  for (const key in errors) {
    // If the error value is an array, format it as specified
    if (Array.isArray(errors[key])) {
      formattedErrors[key] = `[${errors[key].join(", ")}]`;
    } else {
      formattedErrors[key] = errors[key];
    }
  }

  // Convert the formatted errors object to a string
  let errorText = "";
  for (const key in formattedErrors) {
    errorText += `${key}: ${formattedErrors[key]}\n`;
  }

  return errorText;
};

// Function to validate if the Excel file is not empty
const validateNotEmpty = (jsonData) => {
  if (jsonData.length <= 1) {
    // jsonData length will be 1 if only headers are present
    return "Excel file is empty.";
  }
  return null;
};

// Function to validate the number of rows in the Excel file
const validateMaxRows = (jsonData) => {
  const maxRows = 1000;
  if (jsonData.length > maxRows) {
    return `Excel file should not exceed ${maxRows} rows. Current row count: ${
      jsonData.length - 1
    }`;
  }
  return null;
};

export const validateExcelFile = (file, exam, center) => {
  const errors = {};

  const fileNameError = validateFileName(file);
  if (fileNameError) {
    errors.fileName = fileNameError;
  }

  const fileTypeError = validateFileType(file);
  if (fileTypeError) {
    errors.fileType = fileTypeError;
  }

  const headers = [
    //"studentUniqueId",
    "registrationNo",
    "rollNo",
    //"fullName",
    //"photoId",
    "centreCode",
    //"centreName",
    "examCode",
    //"examName",
  ];
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const filterData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Remove rows where no data exists
      const jsonData = filterData.filter((row) => row.some((cell) => !!cell));

      // Check if the excel file isn't empty
      const emptyError = validateNotEmpty(jsonData);
      if (emptyError) {
        errors.empty = emptyError;
      }
      // Check if the excel file doesnt have not more then the specifed rows
      const maxRowsError = validateMaxRows(jsonData);
      if (maxRowsError) {
        errors.maxRows = maxRowsError;
      }

      // Check if the headers match and have the correct length
      const headerErrors = validateHeaders(jsonData, headers);
      if (headerErrors.length > 0) {
        errors.headers = headerErrors;
      }

      // Extract registration numbers from data
      const registrationNumbers = jsonData
        .slice(1)
        .map((row) => row[headers.indexOf("registrationNo")]);

      // Check for duplicate registration numbers
      const duplicates = findDuplicates(registrationNumbers);
      if (duplicates.length > 0) {
        errors.duplicates = "Duplicate registration numbers found.";
        errors.duplicateRegistrationNumbers = duplicates;
      }

      // Extract data from the Excel file
      const examCodeIndex = headers.indexOf("examCode");
      //const examNameIndex = headers.indexOf("examName");
      const centreCodeIndex = headers.indexOf("centreCode");
      //const centreNameIndex = headers.indexOf("centreName");

      const dataErrors = [];

      jsonData.slice(1).forEach((row) => {
        const examCode = row[examCodeIndex];
        //const examName = row[examNameIndex];
        const centreCode = row[centreCodeIndex];
        //const centreName = row[centreNameIndex];
        // Check if examCode matches

        if (parseInt(examCode) !== parseInt(exam.item_code)) {
          dataErrors.push(
            `\nExam code in row ${row} doesn't match selected exam.\n`
          );
        }

        // Check if examName matches
        // if (examName !== exam.item_name) {
        //   dataErrors.push(
        //     `\nExam name in row ${row} doesn't match selected exam.\n`
        //   );
        // }

        // Check if centreCode matches
        if (parseInt(centreCode) !== parseInt(center.CompanyCode)) {
          dataErrors.push(
            `\nCentre code in row ${row} doesn't match selected center.\n`
          );
        }

        // Check if centreName matches
        // if (centreName !== center.CompanyName) {
        //   dataErrors.push(
        //     `\nCentre name in row ${row} doesn't match selected center.\n`
        //   );
        // }
      });

      if (dataErrors.length > 0) {
        errors.data = dataErrors;
      }

      if (Object.keys(errors).length === 0) {
        resolve(true);
      } else {
        const errorFileUrl = createErrorLogFile(errors);
        reject({ errors, errorFileUrl });
      }
    };

    reader.onerror = (e) => {
      reject(new Error("Error reading the file."));
    };

    reader.readAsArrayBuffer(file);
  });
};

// export const validateExcelFile = (file) => {
//   // Check if the file name matches the required pattern
//   if (file.name !== 'candidateRegistrationTemplate.xlsx') {
//     return Promise.reject(new Error('File name should be "candidateRegistrationTemplate.xlsx"'));
//   }

//   // Check if the file type is correct
//   if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
//     return Promise.reject(new Error('File should be with the .xlsx extension.'));
//   }

//   const headers = [
//     // 'DistrictCode',
//     // 'DIST_NAME',
//     'studentUniqueId',
//     'rollNo',
//     'registrationNo',
//     'fullName',
//     'photoId',
//     'centreCode',
//     'centreName',
//     // 'examdate',
//     // 'ExamShift',
//     // 'ExamTime'
//   ];
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();

//     reader.onload = (e) => {
//       const data = new Uint8Array(e.target.result);
//       const workbook = XLSX.read(data, { type: 'array' });

//       const sheetName = workbook.SheetNames[0];
//       const worksheet = workbook.Sheets[sheetName];
//       const headerCells = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];

//       // Check if the headers match and have the correct length
//       if (JSON.stringify(headerCells) === JSON.stringify(headers) && headerCells.length >= headers.length) {
//         resolve(true);
//       } else {
//         reject(new Error('Invalid headers.Use the headers provided in the template'));
//       }
//     };

//     reader.onerror = (e) => {
//       reject(new Error('Error reading the file.'));
//     };

//     reader.readAsArrayBuffer(file);
//   });
// };
export const formatExcelData = (jsonData, examName, user_id) => {
  // Extract headers from the first row and trim any extra spaces
  const headers = jsonData[0].map((header) => header.trim());

  // Convert array of arrays to array of objects
  const formattedData = jsonData.slice(1).map((row) => {
    const obj = {};
    headers.forEach((header, index) => {
      obj[header] = row[index];
    });
    // Set examId field
    obj["examId"] = examName.id;
    // Set examCode field
    obj["examCode"] = examName.item_code;
    // Set examName field
    obj["user_id"] = user_id;
    return obj;
  });

  return formattedData;
};

export const readExcelFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      resolve(jsonData);
    };
    reader.onerror = (e) => {
      reject(new Error("Error reading the file."));
    };
    reader.readAsArrayBuffer(file);
  });
};
