import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Box,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useSelector } from 'react-redux';
import configServ from '../../../services/config';

import ExistingImage from './ExistingImage';
import CaptureOrUploadImage from './CaptureOrUploadImage';
import CheckAndReturn from '../../../utility/CheckAndReturn';

//FUNCTION
const ImageUpload = ({ open, onClose, imageData, from, exam_id }) => {
  const { user_id } = useSelector((state) => state.GlobalVariables);
  const webcamRef = useRef(null);
  const [existingImage, setExistingImage] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [manualVerification, setManualVerification] = useState(false);
  const [loading, setLoading] = useState(true);
  const [verifiedImage, setVerifiedImage] = useState(null);
  const [imageDate, setImageDate] = useState({});
  const [remark, setRemark] = useState(null);

  useEffect(() => {
    const fetchExistingImage = async () => {
      try {
        const primary_image = imageData.primary_image;
        if(primary_image){
          setImageDate(prevState => ({
            ...prevState,
            'capturedAt': formatDateTime(primary_image.created_at),
            'primary_remark': primary_image.remark
          }));
          setExistingImage(`data:image/jpg;base64,${primary_image.image}`);
        }
        else{
          setImageDate(prevState => ({
            ...prevState,
            'capturedAt': null,
            'primary_remark': null
          }));
          setExistingImage(null);
        }

        const secondary_image = imageData.secondary_image;
        if(secondary_image){
          setImageDate(prevState => ({
            ...prevState,
            'updatedAt': formatDateTime(secondary_image.created_at),
            'secondary_remark': secondary_image.remark
          }));
          setVerifiedImage(`data:image/jpg;base64,${secondary_image.image}`);
        }
        else{
          setImageDate(prevState => ({
            ...prevState,
            'updatedAt': null,
            'secondary_remark': null
          }));
          setVerifiedImage(null);
        }

        // const dataToSend = {
        //   student_id: imageData.id,
        //   registration_no: imageData.registration_no,
        //   exam_id: exam_id,
        //   type: 'photo'
        // };
        // const primaryImageResponse = await configServ.getPrimaryStudentImage(dataToSend);
        // if (primaryImageResponse.status === 200) {
        //   setImageDate(prevState => ({
        //     ...prevState,
        //     'capturedAt': formatDateTime(primaryImageResponse.created_at)
        //   }));
        //   setExistingImage(`data:image/jpg;base64,${primaryImageResponse.data}`);
        // } 
        // const verifiedImageResponse = await configServ.getVerifiedImage(dataToSend);
        // if (verifiedImageResponse.status === 200) {
        //   setImageDate(prevState => ({
        //     ...prevState,
        //     'updatedAt': formatDateTime(verifiedImageResponse.created_at)
        //   }));
        //   setVerifiedImage(`data:image/jpg;base64,${verifiedImageResponse.data}`);
        // }

      }
      catch (error) {
        console.error('Error fetching existing image:', error);
      }
      finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchExistingImage();
    }
  }, [open]);

  const formatDateTime = (dateTime) => {
    if (!dateTime) return 'Not available';
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true });
    return `${formattedDate} - ${time}`;
  };

  // const captureImage = () => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   setCapturedImage(imageSrc);
  //   setSelectedFile(null);
  // };

  const handleRemark = (data) => {
    try {
      if (data === "") {
        setRemark(null);
      }
      else {
        setRemark(data);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleImageUpload = async () => {
    //const dateTime = CheckAndReturn.getCurrentDateTimeFormatted();
    const dataToSend = {
      image: capturedImage || selectedFile,
      type: 'photo',
      student_id: imageData.id,
      registration_no: imageData.registration_no,
      exam_id: parseInt(exam_id),
      status: Boolean(imageData.status),
      is_verified: manualVerification,
      remark: remark,
      user_id: user_id,
      created_at: null,
    };
    try {
      setUploadStatus(false);
      const result = await configServ.addImage(dataToSend);
      if (result.success === 200) {
        setUploadStatus(true);
        setCapturedImage(null);
        setExistingImage(null);
        
      }
    }
    catch (error) {
      console.error('Error uploading image:', error);
    }
    finally {
      setCapturedImage(null);
      setExistingImage(null);
    }
  };

  const handlePrimaryImageUpload = async () => {
    //const dateTime = CheckAndReturn.getCurrentDateTimeFormatted();
    const dataToSend = {
      image: capturedImage || selectedFile,
      type: 'photo',
      student_id: imageData.id,
      registration_no: imageData.registration_no,
      roll_no: imageData.subscriptions.roll_no,
      exam_id: exam_id,
      status: Boolean(imageData.status),
      user_id: user_id,
      is_verified: true,
      created_at: null,
    };
    try {
      setUploadStatus(false);
      const result = await configServ.addPrimaryStudentImage(dataToSend);
      if (result.success === 200) {
        setUploadStatus(true);
        setCapturedImage(null);
        setExistingImage(null);
        
      }
    }
    catch (error) {
      console.error('Error uploading image:', error);
    }
    finally {
      setCapturedImage(null);
      setExistingImage(null);
    }
  };

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const base64Url = reader.result;
  //       setCapturedImage(null);
  //       setSelectedFile(base64Url);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleClose = () => {
    setUploadStatus(null);
    setCapturedImage(null);
    setExistingImage(null);
    setSelectedFile(null);
    setVerifiedImage(null);
    setManualVerification(false);
    setRemark(null);
    setLoading(true);
    onClose();
  };

  const handleCancel = () => {
    setCapturedImage(null);
    setSelectedFile(null);
    setManualVerification(false);
    setVerifiedImage(null);
  }

  // const renderCameraFeed = () => (
  //   <>
  //     <Webcam
  //       audio={false}
  //       height={300}
  //       ref={webcamRef}
  //       screenshotFormat="image/jpeg"
  //       width={400}
  //       style={{
  //         borderRadius: '8px',
  //       }}
  //     />
  //     <Grid container alignItems="center" justifyContent="center" spacing={2} style={{ marginTop: '8px' }}>
  //       <Grid item xs={6}>
  //         <Button onClick={captureImage} style={{ width: '100%' }} variant="contained" color="primary">
  //           Capture Image
  //         </Button>
  //       </Grid>
  //       <Grid item xs={6}>
  //         <input
  //           type="file"
  //           accept="image/*"
  //           onChange={handleFileUpload}
  //           style={{ display: 'none' }}
  //           id="fileInput"
  //         />
  //         <label htmlFor="fileInput" style={{ width: '100%' }}>
  //           <Button component="span" style={{ width: '100%' }} variant="contained" color="primary">
  //             Choose File
  //           </Button>
  //         </label>
  //       </Grid>
  //     </Grid>
  //   </>
  // );

  // const handleManualVerificationChange = (event) => {
  //   setManualVerification(event.target.checked);
  // };

  return (
    <>
      <Dialog open={open} onClose={onClose} minWidth="xs" maxWidth="md" fullWidth>
        <DialogContent>
          {uploadStatus ? (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
              <CheckCircleIcon color='success' fontSize='32px' />
              <Typography variant="h5" color={'success'}>Image uploaded successfully!</Typography>
            </Grid>
          ) : (
            loading ?
              <CircularProgress />
              :
              <Grid container spacing={2}>
                {/* Left Side: Display Existing Image */}
                <ExistingImage loading={loading} existingImage={existingImage} capturedAt={imageDate.capturedAt} primary_remark={imageDate.primary_remark} />
                {
              /* <Grid item xs={12} md={6}>
                {!loading ?
                  (existingImage ? (
                    <>
                    <img src={existingImage} alt="Existing" style={{ width: '100%', borderRadius: '8px' }} />
                    </>
                  ) : (
                    <Typography color={'red'} fontWeight={500}>Primary Image not found.</Typography>
                  )) :
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'100%'}>
                    <CircularProgress />
                  </Box>
                }
              </Grid> */}

                {/* Right Side: Capture Image or Upload Image */}

                <CaptureOrUploadImage
                  verifiedImage={verifiedImage}
                  existingImage={existingImage}
                  capturedImage={capturedImage}
                  selectedFile={selectedFile}
                  manualVerification={manualVerification}
                  setManualVerification={setManualVerification}
                  setCapturedImage={setCapturedImage}
                  setSelectedFile={setSelectedFile}
                  handleImageUpload={handleImageUpload}
                  handlePrimaryImageUpload={handlePrimaryImageUpload}
                  from={from}
                  updatedAt={imageDate.updatedAt}
                  secondary_remark={imageDate.secondary_remark}
                  remark={remark}
                  handleRemark={handleRemark}
                />



                {
              /* <Grid item xs={12} md={6}>
                {(verifiedImage ? ImagePreview(verifiedImage) 
                :
                 (capturedImage || selectedFile) ? 
                 (<>
                    {ImagePreview(capturedImage || selectedFile)}
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={manualVerification} onChange={handleManualVerificationChange} />}
                        label="Manual Verification"
                      />
                    </FormGroup>
                    <Grid container spacing={1}>
                      {
                        is_super &&
                        <Grid item xs={12} sm={6} md={6}>
                          <Button
                            fullWidth
                            disabled={!manualVerification}
                            onClick={() => handlePrimaryImageUpload()}
                            sx={{ my: 1 }}
                            variant="contained"
                            color="primary"
                          >
                            Upload Primary
                          </Button>
                        </Grid>
                      }
                      {
                        existingImage ?
                          <Grid item xs={12} sm={6} md={6}>
                            <Button
                              fullWidth
                              disabled={!manualVerification}
                              onClick={() => handleImageUpload()}
                              sx={{ my: 1 }}
                              variant="contained"
                              color="success"
                            >
                              Upload & Verify
                            </Button>
                          </Grid>
                          :
                          <Grid item xs={12} sm={6} md={6}>
                            <Button
                              fullWidth
                              disabled={true}
                              onClick={() => handleImageUpload()}
                              sx={{ mt: 1 }}
                              variant="contained"
                              color="success"
                            >
                              Upload & Verify
                            </Button>
                          </Grid>
                      }
                    </Grid>
                  </>
                ) :
                  <WebcamCapture setCapturedImage={setCapturedImage} setSelectedFile={setSelectedFile} hidden={existingImage}/>
                  //  !verifiedImage ? (
                  //     <WebcamCapture setCapturedImage={setCapturedImage} setSelectedFile={setSelectedFile} handleFileUpload={handleFileUpload}/>
                  //     // renderCameraFeed()
                  //   ) :
                  //   <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  //   <CircularProgress />
                  // </Box>
                )}
              </Grid> */}
              </Grid>
          )}
        </DialogContent>

        {/* Close button */}
        <Grid container justifyContent="center" sx={{ mb: '16px', mt: '8px' }} spacing={3}>
          <Grid container item xs={6} justifyContent={'flex-end'}>
            <Button
              color='warning'
              sx={{ px: 4 }}
              onClick={handleClose}
              variant="contained"
            >
              Close
            </Button>
          </Grid>
          {(capturedImage || selectedFile) &&
            <Grid container item xs={6} justifyContent={'flex-start'}>
              <Button
                color='error'
                sx={{ px: 4 }}
                onClick={handleCancel}
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
          }
        </Grid>
      </Dialog>
    </>
  );
};

export default ImageUpload;
