import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
  Button,
  Grid,
  CircularProgress,
  TextField,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PreviewIcon from "@mui/icons-material/Preview";
import { Verified } from "@mui/icons-material";
const ExistingIris = ({
  open,
  handleClose,
  iris,
  captured,
  capturing,
  handleCaptureIris,
  handleUploadIris,
  handleMatchIris,
  loading,
  verified,
  isFirstStage,
  secondaryIris,
  handleRemark,
  remark,
}) => {
  const formatDateTime = (dateTime) => {
    if (!dateTime) return "Not available";
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const time = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedDate} - ${time}`;
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ borderRadius: "45%" }}
          >
            {iris ? (
              <img
                src={`data:image/jpeg;base64,${iris.isoTemplate}`}
                alt="iris"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <PreviewIcon
                fontSize="large"
                style={{ height: "10rem", width: "10rem" }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" style={{ marginTop: "16px" }}>
        <Grid
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: "0.5rem" }}
        >
          <Typography variant="body2" style={{ textAlign: "center" }}>
            <b>CAPTURED</b>: {iris.capturedAt}
          </Typography>
        </Grid>

        {secondaryIris && secondaryIris.is_verified === 1 && (
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: "0.5rem" }}
          >
            <Typography variant="body2" align="center">
              <b>VERIFIED</b>: {formatDateTime(secondaryIris.created_at)}
            </Typography>
          </Grid>
        )}

        {secondaryIris && (
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: "0.5rem" }}
          >
            <Typography variant="body2" style={{ textAlign: "center" }}>
              <b>REMARK</b>: {secondaryIris.remark}
            </Typography>
          </Grid>
        )}
        {isFirstStage !== undefined && !verified && !isFirstStage && (
          <Grid item xs={8} mb={1}>
            <TextField
              fullWidth
              multiline
              rows={2}
              name="remark"
              variant="outlined"
              value={remark || ""}
              placeholder="Enter comment here."
              onChange={(e) => handleRemark(e.target.value)}
              error={!!(remark === null)}
            />
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          {isFirstStage !== undefined && !isFirstStage && !verified && (
            <Grid item>
              <Button
                onClick={handleMatchIris}
                disabled={loading}
                variant="contained"
                color="primary"
                style={{ marginRight: "8px" }}
              >
                {loading ? <CircularProgress size={24} /> : "Match"}
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button onClick={handleClose} variant="contained" color="error">
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ExistingIris;
