import React, { useState } from 'react';
import configServ from '../../../services/config';
import CheckAndReturn from '../../../utility/CheckAndReturn';
import { useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { appServiceName } from '../../../services/http';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Box,
    Tooltip,
    IconButton,
    Grid,
    Typography,
    Button,
    NativeSelect,
    InputLabel,
    TextField,
    InputAdornment,
    FormControl,
    CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';

//FUNCTION
const RegistrationTable = ({ handleEditData, isChanged, pagination = true }) => {
    //#region code
    const { user_id, company_id, is_super, permissions } = useSelector((state) => state.GlobalVariables);
    const rowsPerPageOptions = [25, 100, 200, 500];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [count, setCount] = useState(0);
    const [companyList, setCompanyList] = useState([]);
    //const [filterCompanyList, setFilterCompanyList] = useState([]);
    const [searchBy, setSearchBy] = useState('CompanyName');
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);

    const headers = ['CENTER NAME', 'CENTER CODE', 'DISTRICT', 'STATE', 'STATUS', 'ACTION'];

    // useEffect(() => {
    //     fetchCompanyList();
    // }, [isChanged]);

    useEffect(() => {
        fetchCompanyList();
    }, [isChanged, page, rowsPerPage, search]);

    const fetchCompanyList = async () => {
        try {
            const dataToSend = {
                "user_id": user_id,
                "is_super": is_super,
                "company_id": company_id,
                "search_by": searchBy,
                "search": search,
                "per_page": rowsPerPage,
                "page": page
            }
            const res = await configServ.getCompanyList(dataToSend);
            if (res.status === 200) {
                setCompanyList(res.data);
                setCount(res.total);
            }
            else if (res.status === 404) {
                setCompanyList([]);
                setCount(0);
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: 'black', // Set the header background color to blue
        color: 'white',
        padding: 5,
    };

    const evenRowStyle = {
        background: '#f5f5f5',
    };

    const oddRowStyle = {
        background: 'white',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    const cellStyle = {
        py: 0,
        my: 0
      }

    const handleSearchBy = (event) => {
        try {
            setSearchBy(event.target.value);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleSearch = (event) => {
        try {
            const data = event.target.value;
            if (data === "") {
                setSearch(null);
            } 
            else {
                setSearch(data);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const printInvoice = async (invoice_no) => {
        const data = {
            "invoice_no": invoice_no,
            "user_id": user_id,
            "company_id": company_id
        }
        console.log(data)

        try {
            const fileName = `invoice${user_id}${invoice_no}`
            const url = '/api/print_invoice/'
            axios.post(`${appServiceName}${url}`, data, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            })
                .then((res) => {
                    const blobFile = new Blob([res.data], { type: 'application/pdf' })
                    const url = window.URL.createObjectURL(blobFile);
                    window.open(url)
                    // console.log(url)
                    // link.href = url;
                    // const link = document.createElement('a');
                    // link.download = fileName;
                    // link.click();
                })
                .catch((err) => {
                    console.log(err)
                });
        } catch (err) {
            console.log(err)
        }
    }

    // const handlePrint = (params) => {
    //     const data = companyList.filter(x => x.id === params.id);
    //     printInvoice(data[0].invoice_number)
    // }

    const handleEdit = (params) => {
        scrollToTop();
        const data = companyList.filter(x => x.id === params.id);
        handleEditData(data)
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    //#endregion

    if (loading) {
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <CircularProgress />
            </Box>
        )
    }
    else {
        return (
            <>
                <Grid container alignItems="flex-end" justifyContent="flex-start" sx={{ my: 1, paddingTop: 0 }} spacing={1} direction={'row'}>
                    <Grid item xs={12} md={5} ml={4} justifyContent={'flex-start'}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Center List:{count}</Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
                            <FormControl
                                fullWidth
                            >
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Search By
                                </InputLabel>
                                <NativeSelect
                                    defaultValue={'contact_name'}
                                    onChange={handleSearchBy}
                                    inputProps={{
                                        name: 'searchBy',
                                        id: 'uncontrolled-native',
                                    }}
                                >
                                    <option value={'CompanyName'}>Center Name</option>
                                    <option value={'district'}>District</option>
                                    <option value={'State'}>State</option>
                                </NativeSelect>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} mr={{ xs: 0, md: 4 }} justifyContent={'flex-end'}>
                        <TextField
                            id="outlined-basic"
                            size="small"
                            fullWidth
                            placeholder="search"
                            variant="outlined"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ fill: 'grey' }} />
                                    </InputAdornment>
                                ),
                            }} />
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ px: 0 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellStyle}>S.NO.</TableCell>
                                {headers.map((header, index) => (
                                    <TableCell key={index} style={headerCellStyle}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companyList.map((row, rowIndex) => (
                                <TableRow key={rowIndex}
                                    sx={{
                                        ...rowStyle,
                                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                    }}>
                                    <TableCell sx={cellStyle}>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                    <TableCell sx={cellStyle}>{row.CompanyName}</TableCell>
                                    <TableCell sx={cellStyle}>{row.CompanyCode}</TableCell>
                                    <TableCell sx={cellStyle}>{row.district}</TableCell>
                                    <TableCell sx={cellStyle}>{row.State}</TableCell>
                                    <TableCell sx={cellStyle}>{row.is_active === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0, ":hover": { backgroundColor: '#99999955' } }}>
                                            <IconButton title='Edit' color='primary' onClick={() => { handleEdit(row); scrollToTop(); }} >
                                                <EditIcon />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={headers.length + 2} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    </Table>
                    {pagination && (
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage} />
                    )}
                </TableContainer>
            </>
        );
    }
};

export default RegistrationTable;
