import React, { useEffect, useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import configServ from "../../services/config";
import CustomerContact from "../../Components/Customers/CustomerContact";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  IconButton,
  Typography,
  Stack,
  Tooltip,
  Box,
  Paper,
  Grid,
} from "@mui/material";
import { motion } from "framer-motion";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import ButtonMUI from "@mui/material/Button";
// import http from '../../services/http';
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import { useSelector } from "react-redux";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckAndReturn from "../../utility/CheckAndReturn";

//FUNCTION
const CustomerCard = ({
  handleClose,
  open,
  isChanged,
  setIsChanged,
  editData,
  setEditData,
  handleDownload,
  handleExcelUpload,
  excelData
}) => {
  //#region code
  const { user_id, company_id, permissions } = useSelector((state) => state.GlobalVariables);

  const initialFormData = {
    status: true,
  };


  const [formData, setFormData] = useState(initialFormData);
  //const [countryList, setCountryList] = useState([]);
  //const [stateList, setStateList] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openCustContact, setOpenCustContact] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState('Alert');
  const [dialogContent, setDialogContent] = useState('');
  const [buttonType, setButtonType] = useState(null);
  const [cannotAdd, setCannotAdd] = useState(true);
  const [cannotUpdate, setCannotUpdate] = useState(true);

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle('Alert');
    setDialogContent('');
    setButtonType(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  useEffect(()=>{
    const fullName = formData.middle_name !== "" && formData.middle_name !== undefined
    ? `${formData.first_name || ""} ${formData.middle_name || ""} ${formData.last_name || ""}`
    : `${formData.first_name || ""} ${formData.last_name || ""}`;

    setFormData((prev)=>({
      ...prev,
      full_name: fullName
    }));
  },[formData.first_name, formData.middle_name, formData.last_name]);

  const handleSubmit = async () => {
    //e.preventDefault();
    const dataToSend = {
      ...formData,
      user_id: user_id,
      company_id: company_id? company_id: null,
    };

    try {
      const error = await handleFormValidation();
      if (error === false) {
        return false;
      }
      setValidationErrors({});

      if (!isEdit) {
        const res = await configServ.addStudent(dataToSend);
        if (res.status === 200) {
          setIsChanged(!isChanged);
          clear();
          handleOpenDialog('Success', res.message, 'success');
        }
        else if(res.status === 400){
          handleOpenDialog('Warning', res.message || "Failed to create candidate.", 'warning');
        }
        else {
          handleOpenDialog('Error', res.message || "Failed to create candidate.", 'error');
        }
      }
      else {
        const res = await configServ.editStudent(dataToSend);
        if (res.status === 200) {
          setIsChanged(!isChanged);
          clear();
          handleOpenDialog('Success', res.message, 'success');
        }
        else {
          handleOpenDialog('Error', res.message || "Failed to update candidate enrollment.", 'error');
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const fetchCounties = async () => {
  //   const res = await axios.get(
  //     "https://countriesnow.space/api/v0.1/countries/capital"
  //   );
  //   if (!res.error) {
  //     setCountryList(res.data.data);
  //     // console.log(res.data.data)
  //   }
  // };

  // const fetchStates = async () => {
  //   const res = await axios.post(
  //     "https://countriesnow.space/api/v0.1/countries/states",
  //     { country: `${formData.country}` }
  //   );
  //   if (!res.error) {
  //     setStateList(res.data.data.states);
  //     // console.log(res.data.data.states)
  //   }
  // };


  const checkPermissions = () => {
    try {
      const isAdd = CheckAndReturn.PermissionHandler('create-student', permissions);
      setCannotAdd(!isAdd);
      const isUpdate = CheckAndReturn.PermissionHandler('update-student', permissions);
      setCannotUpdate(!isUpdate);
    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    checkPermissions();
  }, []);

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setFormData(editData);
      setFormData((prev)=>({
        ...prev,
        roll_no: editData.subscriptions !== null ? editData.subscriptions.roll_no : '',
      }));
      setIsEdit(true);
    }
  }, [editData]);

  const clear = () => {
    setFormData(initialFormData);
    setEditData({});
    setIsDone(false);
    setIsEdit(false);
    setValidationErrors({});
  };

  // const handleCustContactOpen = () => {
  //   setOpenCustContact(true);
  // };

  const handleCustContactClose = () => {
    setOpenCustContact(false);
  };

  const handleFormValidation = async() => {
    const errors = {};
    if (!formData.student_uniq_id) {
      errors.student_uniq_id = "required";
    }
    if (!formData.roll_no) {
      errors.roll_no = "required";
    }
    if (!formData.registration_no) {
      errors.registration_no = "required";
    }
    if (!formData.first_name) {
      errors.first_name = "required";
    }
    if (!formData.last_name) {
      errors.last_name = "required";
    }
    if (!formData.full_name) {
      errors.full_name = "required";
    }

    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      <CustomerContact
        handleClose={handleCustContactClose}
        open={openCustContact}
        customer_id={formData.id}
        customer_code={formData.customer_code}
      />

      {/* <motion.h4 style={{ textAlign: 'center' }}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1 }}
            >
                Add Category
            </motion.h4>
            <br /> */}

      {open && (
        <motion.div
          style={{
            width: "100%",
            backgroundColor: "#e3f3fc",
            marginTop: 20,
            padding: 20,
            borderRadius: "10px",
            border: "none",
          }}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.1, delay: 0.1 }}
        >
          <Grid
            container
            alignItems="flex-end"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={12} md={12} justifyContent={"flex-start"}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bolder" }}>
                Candidate's Detail
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>Student Id</span>}
                name="student_uniq_id"
                type="number" // Set input type to "number" for numeric input
                onChange={handleChange}
                value={formData.student_uniq_id || ""}
                error={!!validationErrors.student_uniq_id}
                sx={{ backgroundColor: "white" }}
                required // Make the field required
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Allow only numeric input
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>Roll No</span>}
                name="roll_no"
                type="number" // Set input type to "number" for numeric input
                onChange={handleChange}
                value={formData.roll_no || ""}
                error={!!validationErrors.roll_no}
                sx={{ backgroundColor: "white" }}
                required // Make the field required
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Allow only numeric input
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>Registration No</span>}
                name="registration_no"
                type="number" // Set input type to "number" for numeric input
                onChange={handleChange}
                value={formData.registration_no || ""}
                error={!!validationErrors.registration_no}
                sx={{ backgroundColor: "white" }}
                required // Make the field required
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Allow only numeric input
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>First Name</span>}
                name="first_name"
                onChange={handleChange}
                value={formData.first_name || ""}
                error={!!validationErrors.first_name}
                sx={{ backgroundColor: "white" }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>Middle Name</span>}
                name="middle_name"
                onChange={handleChange}
                value={formData.middle_name || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>Last Name</span>}
                name="last_name"
                onChange={handleChange}
                value={formData.last_name || ""}
                error={!!validationErrors.last_name}
                sx={{ backgroundColor: "white" }}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled
                fullWidth
                size="small"
                label={<span>Full Name<span style={{ color: 'red' }}>*</span></span>}
                name="full_name"
                onChange={handleChange}
                value={formData.full_name || ""}
                error={!!validationErrors.full_name}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>

            {/* <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <FormControl fullWidth size="small">
                <InputLabel>Country<span style={{color:'red'}}>*</span></InputLabel>
                <Select
                  required
                  name="country"
                  label="select"
                  value={formData.country || ""}
                  onChange={handleChange}
                  error={!!validationErrors.country}
                  sx={{ backgroundColor: "white" }}

                >
                  {countryList.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <FormControl fullWidth size="small">
                <InputLabel>State<span style={{color:'red'}}>*</span></InputLabel>
                <Select
                  required
                  name="State"
                  label="select"
                  value={formData.State || ""}
                  onChange={handleChange}
                  error={!!validationErrors.State}
                  sx={{ backgroundColor: "white" }}

                >
                  {stateList.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                type="number"
                size="small"
                label={<span>Pin<span style={{color:'red'}}>*</span></span>}
                name="Pin"
                onChange={handleChange}
                value={formData.Pin || ""}
                error={!!validationErrors.Pin}
                sx={{ backgroundColor: "white" }}
              />
            </Grid> */}
            {/* <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <FormControl fullWidth size="small">
                <InputLabel id="customer-type">Customer Type<span style={{color:'red'}}>*</span></InputLabel>
                <Select
                  labelId="customer-type"
                  label="Customer Type"
                  name="type"
                  value={formData.type || ""}
                  onChange={handleChange}
                  error={!!validationErrors.type}
                  sx={{ backgroundColor: "white" }}

                >
                  <MenuItem value={"Standard"}>Standard </MenuItem>
                  <MenuItem value={"Previleged"}>Previleged</MenuItem>
                  <MenuItem value={"Staff"}>Staff</MenuItem>
                  <MenuItem value={"Discount"}>Discount </MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                type="number"
                size="small"
                label={<span>Opening Amount<span style={{color:'red'}}>*</span></span>}
                name="openingamount"
                onChange={handleChange}
                value={formData.openingamount || ""}
                defaultValue={0}
                error={!!validationErrors.openingamount}
                sx={{ backgroundColor: "white" }}

              />
            </Grid> */}
            {/* <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label="PAN/NO"
                name="pan_no"
                onChange={handleChange}
                value={formData.pan_no || ""}
                sx={{ backgroundColor: "white" }}

              />
            </Grid> */}
            {/* <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label="GSTIN/UIN"
                name="gstin"
                onChange={handleChange}
                value={formData.gstin || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <FormControlLabel
                control={<Checkbox />}
                name="is_credit_days"
                checked={Boolean(formData.is_credit_days)}
                onChange={handleChangeCheckbox}
                label="Check credit days"
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                label="Credit Period"
                size="small"
                name="credit_period"
                InputProps={{
                  endAdornment: "d",
                }}
                disabled={formData.is_credit_days === true ? false : true}
                onChange={handleChange}
                value={formData.credit_period || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                label="Credit Limmit"
                size="small"
                name="credit_limit"
                disabled={formData.is_credit_days === true ? false : true}
                onChange={handleChange}
                value={formData.credit_limit || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={12} columnSpacing={0}>
                <FormControlLabel 
                    control={<Checkbox />} 
                    name='isShipTomidNameSame' 
                    checked={Boolean(isShipTomidNameSame)} 
                    onChange={()=>setIsShipTomidNameSame(!isShipTomidNameSame)} 
                    label='Ship To middle_name same as Bill To middle_name'   
                    style={{fontWeight:'bolder'}}/>
            </Grid>*/}

            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <FormControlLabel
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                control={<Checkbox />}
                name="status"
                checked={Boolean(formData.status)}
                onChange={handleChangeCheckbox}
                label="Status"
                sx={{ fontWeight: "bold" }}
              />
            </Grid>

            {/* <Grid container spacing={2} justifyContent="space-between" px={2} py={2}> */}
            {/* <Grid item container justifyContent='flex-start' alignItems='center' spacing={2} md={6}>  
                <Grid item>
                  <Button
                    disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                    onClick={handleDownload}
                    variant="contained"
                    color="primary"
                    size='small'
                    style={{ fontSize: '0.8rem' }} 
                   startIcon={<CloudDownloadIcon />}
                  >
                    Download Excel
                  </Button>
                </Grid>

                <Grid item>
                  <input
                    accept=".xlsx"
                    style={{ display: 'none' }}
                    id="excel-file-input"
                    type="file"
                    onChange={handleExcelUpload}
                    disabled={!!excelData}
                  />
                  <label htmlFor="excel-file-input">
                    <Button
                      variant="contained"
                      color="secondary"
                      component="span"
                      size='small'
                      style={{ fontSize: '0.8rem' }} 
                      disabled={!!excelData}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Excel
                    </Button>
                  </label>
                </Grid>

              </Grid> */}

            <Grid item container justifyContent="flex-end" alignItems="center" spacing={2} xs={12}>
              <Grid item>
                <Button
                  disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                  onClick={clear}
                  variant="contained"
                  color="secondary"
                  startIcon={<RotateLeftIcon />}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                  onClick={handleSubmit}
                  autoFocus
                  variant="contained"
                  color={isEdit ? "success" : "primary"}
                  startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                >
                  {isEdit ? "Update" : "Save"}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* </Grid> */}
        </motion.div>
      )}
    </>
  );
};

export default CustomerCard;
