import React, { memo, useState } from "react";
import { Stack, Paper, Divider } from "@mui/material";
import CategoryTable from "../Card/MaterEntryCards/CategoryTable";
import CategoryCard from "../Card/MaterEntryCards/CategoryCard";

//FUNCTION
const Category = () => {
    //#region code
    const [isChanged, setIsChanged] = useState(false)
    const [editData, setEditData] = useState({})

    const handleEdit = (data) => {
        setEditData(data);
    }
    //#endregion

    return (
        <>
            <CategoryCard isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} />
            <Divider />

            <CategoryTable handleEdit={handleEdit} isChanged={isChanged} />
        </>
    )
}
export default memo(Category);