import React, { memo, useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    Checkbox,
    Grid,
    Divider,
} from '@mui/material'
import { motion } from 'framer-motion';
import ButtonMUI from '@mui/material/Button';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import CustomUserTable from './CustomUserTable';
import configServ from '../../services/config';
import UserContact from '../AccessMaster/UserContact';
import AlertDialog from '../AlertDialog/AlertDialog';
import CheckAndReturn from '../../utility/CheckAndReturn';
import { triggerLoader } from '../../redux/reducers/LoaderTrigger';

//FUNCTION
const CustomUser = ({ role }) => {
    //#region code
    const dispatch = useDispatch();
    const { user_id, is_super, permissions } = useSelector((state) => state.GlobalVariables);

    const initialFormData = {
        openingamount: 0,
        status: true,
    };

    const [formData, setFormData] = useState(initialFormData);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    //const [isDone, setIsDone] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [openCustContact, setOpenCustContact] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [roleList, setRoleList] = useState([]);
    const [centreList, setCentreList] = useState([]);
    const [isChanged, setIsChanged] = useState(false);
    const [editData, setEditData] = useState({});
    const [buttonType, setButtonType] = useState(null);
    const [title, setTitle] = useState('Alert');
    const [cannotAdd, setCannotAdd] = useState(true);
    const [cannotUpdate, setCannotUpdate] = useState(true);

    const checkPermissions = () => {
        try {
            const isAdd = CheckAndReturn.PermissionHandler('create-access-master', permissions);
            setCannotAdd(!isAdd);
            const isUpdate = CheckAndReturn.PermissionHandler('update-access-master', permissions);
            setCannotUpdate(!isUpdate);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent("");
        setButtonType(null);
    };

    const handleEdit = (data) => {
        setEditData(data);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((state) => ({
            ...state,
            [name]: value,
        }));
    };

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target;
        setFormData((state) => ({
            ...state,
            [name]: checked,
        }));
    };

    // const assignContactName = () => {
    //     if (!isDone) {
    //         setFormData((state) => ({
    //             ...state,
    //             contact_name: formData.name || "",
    //         }));
    //     }
    // };

    const handleSubmit = async () => {
        dispatch(triggerLoader());
        const dataToSend = {
            ...formData,
            user_id: user_id,
        };
        if (formData.is_credit_days === false) {
            delete dataToSend.credit_period;
            delete dataToSend.credit_limit;
        }
        try {
            const error = handleFormValidation();
            if (error === false) {
                return false;
            }
            setValidationErrors({});
            if (!isEdit) {
                const result = await configServ.createAccessMasterUser(dataToSend);
                if (result.status === 200) {
                    setFormData(initialFormData);
                    setIsChanged(!isChanged);
                    handleOpenDialog('Success', result.message, 'success');
                    clear();
                }
                else {
                    handleOpenDialog('Error', result.message || 'Failed to add user.', 'error');
                }
            }
            else {
                const result = await configServ.updateAccessMasterUser(dataToSend);
                if (result.status === 200) {
                    setIsChanged(!isChanged);
                    handleOpenDialog('Success', result.message, 'success');
                    clear();
                }
                else {
                    handleOpenDialog(result.message || 'Failed to update user.', 'error');
                }
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            dispatch(triggerLoader());
        }
    };

    const fetchCounties = async () => {
        const res = await axios.get(
            "https://countriesnow.space/api/v0.1/countries/capital"
        );
        if (!res.error) {
            setCountryList(res.data.data);
        }
    };

    const fetchStates = async () => {
        const res = await axios.post(
            "https://countriesnow.space/api/v0.1/countries/states",
            { country: `${formData.country}` }
        );
        if (!res.error) {
            setStateList(res.data.data.states);
        }
    };

    const fetchRoles = async () => {
        try {
            const result = await configServ.getRoles({ "user_id": user_id, "is_super": is_super })
            if (result.status === 200) {
                setRoleList(result.data);
            }
            else {
                setRoleList([]);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchCentres = async () => {
        try {
            const res = await configServ.getAllCompanyList();
            if (res.status === 200) {
                setCentreList(res.data);
            }
            else {
                setCentreList([]);
            }
        }
        catch (error) {
            console.error("Error fetching centers:", error);
        }
    };

    useEffect(() => {
        checkPermissions();
        fetchCounties();
        fetchRoles();
        fetchCentres();
    }, []);

    useEffect(() => {
        if (formData.country !== undefined) {
            fetchStates();
        }
    }, [formData]);

    // useEffect(()=>{
    //     if(role !== "" && roleList.length > 0){
    //         const selectedRole = roleList.filter(x=>x.name.toLowerCase().includes(role.toLowerCase()))[0];
    //         setFormData((prev)=>({
    //             ...prev,
    //             'role': selectedRole.id
    //         }));
    //     }
    // },[roleList][role]);

    useEffect(() => {
        if (Object.keys(editData).length !== 0) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                ...editData,
                role: editData.roles[0]?.id || null,
            }));
            setIsEdit(true);
        }
    }, [editData]);

    const clear = () => {
        setFormData(initialFormData);
        setEditData({});
        //setIsDone(false);
        setIsEdit(false);
        setValidationErrors({});
    };

    const handleCustContactOpen = () => {
        setOpenCustContact(true);
    };

    const handleCustContactClose = () => {
        setOpenCustContact(false);
    };

    const handleFormValidation = () => {
        const errors = {};
        if (!formData.name) {
            errors.name = "required";
        }
        if (!formData.mobile) {
            errors.mobile = "required";
        }
        if (!formData.email) {
            errors.email = "required";
        }
        if (!formData.country) {
            errors.country = "required";
        }
        if (!formData.state) {
            errors.state = "required";
        }
        if (!formData.pincode) {
            errors.pincode = "required";
        }
        if (!formData.role) {
            errors.role = "required";
        }
        if (is_super) {
            if (!formData.company_id) {
                errors.company_id = "required";
            }
        }
        setValidationErrors(errors);
        return Object.values(errors).every((error) => error === "");
    };
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />

            <UserContact
                handleClose={handleCustContactClose}
                open={openCustContact}
                customer_id={formData.id}
                customer_code={formData.customer_code}
            />

            <motion.div
                style={{
                    width: "100%",
                    backgroundColor: "#e3f3fc",
                    marginTop: 20,
                    padding: 20,
                    borderRadius: "10px",
                    border: "none",
                }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1, delay: 0.1 }}
            >
                <Divider sx={{ my: 1 }}><b>USER DETAIL</b></Divider>
                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    spacing={2}
                >
                    <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                        <TextField
                            disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                            fullWidth
                            size="small"
                            label={<span>Admin Name<span style={{ color: 'red' }}>*</span></span>}
                            name="name"
                            onChange={handleChange}
                            value={formData.name || ""}
                            error={!!validationErrors.name}
                            sx={{ backgroundColor: "white" }} />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Contact Name*"
                                name="contact_name"
                                onChange={handleChange}
                                onClick={() => {
                                    assignContactName();
                                    setIsDone(true);
                                }}
                                value={formData.contact_name || ""}
                                error={!!validationErrors.contact_name}
                                sx={{ backgroundColor: "white" }} />
                        </Grid> */}
                    <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                        <TextField
                            disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                            fullWidth
                            size="small"
                            label={<span>Mobile Number<span style={{ color: 'red' }}>*</span></span>}
                            name="mobile"
                            type="number"
                            onChange={handleChange}
                            value={formData.mobile || ""}
                            error={!!validationErrors.mobile}
                            sx={{ backgroundColor: "white" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                        <TextField
                            disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                            fullWidth
                            size="small"
                            label={<span>Email<span style={{ color: 'red' }}>*</span></span>}
                            name="email"
                            onChange={handleChange}
                            value={formData.email || ""}
                            error={!!validationErrors.email}
                            sx={{ backgroundColor: "white" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                        <TextField
                            disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                            fullWidth
                            size="small"
                            label={<span>Address</span>}
                            name="address"
                            onChange={handleChange}
                            value={formData.address || ""}
                            //error={!!validationErrors.address}
                            sx={{ backgroundColor: "white" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                        <FormControl fullWidth size="small">
                            <InputLabel error={!!validationErrors.country}>Country<span style={{ color: 'red' }}>*</span></InputLabel>
                            <Select
                                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                                required
                                name="country"
                                l
                                abel="select"
                                value={formData.country || ""}
                                onChange={handleChange}
                                error={!!validationErrors.country}
                                sx={{ backgroundColor: "white" }}

                            >
                                {countryList.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                        <FormControl fullWidth size="small">
                            <InputLabel error={!!validationErrors.state}>State<span style={{ color: 'red' }}>*</span></InputLabel>
                            <Select
                                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                                required
                                name="state"
                                label="select"
                                value={formData.state || ""}
                                onChange={handleChange}
                                error={!!validationErrors.state}
                                sx={{ backgroundColor: "white" }}

                            >
                                {stateList.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                        <TextField
                            disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                            fullWidth
                            type="number"
                            size="small"
                            label={<span>Pincode<span style={{ color: 'red' }}>*</span></span>}
                            name="pincode"
                            onChange={handleChange}
                            value={formData.pincode || ""}
                            error={!!validationErrors.pincode}
                            sx={{ backgroundColor: "white" }} />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="user-type">User Type*</InputLabel>
                                <Select
                                    labelId="user-type"
                                    label="User Type"
                                    name="type"
                                    value={formData.type || ""}
                                    onChange={handleChange}
                                    error={!!validationErrors.type}
                                    sx={{ backgroundColor: "white" }}

                                >
                                    <MenuItem value={"Standard"}>Standard </MenuItem>
                                    <MenuItem value={"Previleged"}>Previleged</MenuItem>
                                    <MenuItem value={"Staff"}>Staff</MenuItem>
                                    <MenuItem value={"Discount"}>Discount </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                type="number"
                                size="small"
                                label="Opening Amount*"
                                name="openingamount"
                                onChange={handleChange}
                                value={formData.openingamount || ""}
                                defaultValue={0}
                                error={!!validationErrors.openingamount}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                size="small"
                                label="PAN/NO"
                                name="pan_no"
                                onChange={handleChange}
                                value={formData.pan_no || ""}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                size="small"
                                label="GSTIN/UIN"
                                name="gstin"
                                onChange={handleChange}
                                value={formData.gstin || ""}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                label="Credit Period"
                                size="small"
                                name="credit_period"
                                InputProps={{
                                    endAdornment: "d",
                                }}
                                disabled={formData.is_credit_days === true ? false : true}
                                onChange={handleChange}
                                value={formData.credit_period || ""}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                label="Credit Limmit"
                                size="small"
                                name="credit_limit"
                                disabled={formData.is_credit_days === true ? false : true}
                                onChange={handleChange}
                                value={formData.credit_limit || ""}
                                sx={{ backgroundColor: "white" }} />
                        </Grid> */}
                    <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                        <FormControl fullWidth size="small">
                            <InputLabel htmlFor="role" error={!!validationErrors.role}>Role<span style={{ color: 'red' }}>*</span></InputLabel>
                            <Select
                                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                                value={formData.role || ""}
                                onChange={handleChange}
                                label="Role*"
                                name='role'
                                inputProps={{
                                    name: 'role',
                                    id: 'role',
                                }}
                                sx={{ backgroundColor: 'white' }}
                                error={!!validationErrors.role}
                            >
                                {roleList.map((item) => (
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        is_super &&
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <FormControl fullWidth size="small">
                                <InputLabel htmlFor="center" error={!!validationErrors.company_id}>Center<span style={{ color: 'red' }}>*</span></InputLabel>
                                <Select
                                    disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                                    value={formData.company_id || ""}
                                    onChange={handleChange}
                                    label="Center*"
                                    name='company_id'
                                    inputProps={{
                                        name: 'company_id',
                                        id: 'company_id',
                                    }}
                                    sx={{ backgroundColor: 'white' }}
                                    error={!!validationErrors.company_id}
                                >
                                    {centreList.map((item) => (
                                        <MenuItem value={item.id}>{item.CompanyName}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    {/* <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <ButtonMUI
                                variant="outlined"
                                color="secondary"
                                size="small"
                                hidden={isEdit ? false : true}
                                onClick={handleCustContactOpen}
                            >
                                Add Contact Details
                            </ButtonMUI>
                        </Grid> */}

                    {/* <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <FormControlLabel
                                control={<Checkbox />}
                                name="is_credit_days"
                                checked={Boolean(formData.is_credit_days)}
                                onChange={handleChangeCheckbox}
                                label="Check credit days" />
                        </Grid> */}
                    <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                        <FormControlLabel
                            control={<Checkbox />}
                            name="status"
                            checked={Boolean(formData.status)}
                            onChange={handleChangeCheckbox}
                            label="Status"
                            sx={{ fontWeight: "bold" }} />
                    </Grid>

                    <Grid container spacing={2} justifyContent="space-between" px={2}>
                        <Grid
                            item
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item>
                                <Button
                                    onClick={clear}
                                    variant="contained"
                                    color='secondary'
                                    startIcon={<RotateLeftIcon />}
                                >
                                    Reset
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                                    onClick={handleSubmit}
                                    autoFocus
                                    variant="contained"
                                    color='primary'
                                    startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                                >
                                    {isEdit ? "Update" : "Save"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </motion.div>

            <CustomUserTable handleEdit={handleEdit} isChanged={isChanged} />
        </>
    )
}

export default memo(CustomUser);
