
function GetCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function formatDate(dateString) {
    const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
}

function GetTaxAmount(taxRate, rate, quantity) {
    const tx_rt = parseFloat(taxRate !== '' && taxRate !== null && taxRate !== undefined ? taxRate : 0);
    const rt = parseFloat(rate !== '' && rate !== null && rate !== undefined ? rate : 0);
    const qty = parseFloat(quantity !== '' && quantity !== null && quantity !== undefined ? quantity : 0);
    const totalTax = (rt * tx_rt) / 100
    const taxAmount = (totalTax * qty).toFixed(2);
    return taxAmount;
}

function GetAmount(rate, quantity) {
    const rt = parseFloat(rate !== '' && rate !== null && rate !== undefined ? rate : 0);
    const qty = parseFloat(quantity !== '' && quantity !== null && quantity !== undefined ? quantity : 0);
    const amount = (rt * qty).toFixed(2);
    return amount;
}

function GetTotalAmount(taxRate, rate, quantity) {
    const taxAmount = parseFloat(GetTaxAmount(taxRate, rate, quantity));
    const rt = parseFloat(rate !== '' && rate !== null && rate !== undefined ? rate : 0);
    const qty = parseFloat(quantity !== '' && quantity !== null && quantity !== undefined ? quantity : 0);
    const totalAmount = ((rt * qty) + taxAmount).toFixed(2);
    return totalAmount;
}

function GetTotalDiscount(amount, discount) {
    const totalDiscount = (amount * discount) / 100;
    return totalDiscount;
}

function GetDiscountTaxAmount(taxRate, rate, quantity, discount) {
    const taxAmount = parseFloat(GetTaxAmount(taxRate, rate, quantity));
    const dsct = parseFloat(discount !== '' && discount !== null && discount !== undefined ? discount : 0);
    const totatlDiscount = GetTotalDiscount(taxAmount, dsct);
    const discountAmount = (taxAmount - totatlDiscount).toFixed(2);
    return discountAmount;
}

function GetDiscountTotalAmount(taxRate, rate, quantity, discount) {
    const amount = GetTotalAmount(taxRate, rate, quantity);
    const dsct = parseFloat(discount !== '' && discount !== null && discount !== undefined ? discount : 0);
    const toatlDiscount = GetTotalDiscount(amount, dsct);
    const discountAmount = (amount - toatlDiscount).toFixed(2);
    return discountAmount;
}

function GetOrderItemAmount(quantity, rate) {
    const qty = parseFloat(quantity !== '' && quantity !== null && quantity !== undefined ? quantity : 0);
    const rt = parseFloat(rate !== '' && rate !== null && rate !== undefined ? rate : 0);
    const saleOrderAmount = (qty * rt).toFixed(2);
    return saleOrderAmount;
}

function getCurrentDate() {
    // Get the current date and time
    const currentDate = new Date();

    // Format the date and time
    const year = String(currentDate.getFullYear());
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    // Concatenate the date and time into the desired format
    const datetimeString = `${year}${month}${day}${hours}${minutes}${seconds}`;

    return datetimeString;
}

const Calculation = {
    GetCurrentDate,
    formatDate,
    GetTaxAmount,
    GetAmount,
    GetTotalAmount,
    GetTotalDiscount,
    GetDiscountTaxAmount,
    GetDiscountTotalAmount,
    GetOrderItemAmount,
    getCurrentDate,
}

export default Calculation;