import React from 'react';
import { Grid, Typography, Button } from '@mui/material';

const UploadedSuccessfullyComponent = ({ handleClose }) => {
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} align="center" style={{ margin: '1rem' }}>
                <Typography variant="h4" gutterBottom>
                    Fingerprint Uploaded Successfully!!
                </Typography>
            </Grid>
            <Grid item xs={12} align="center" style={{ margin: '10px' }}>
                <Button onClick={handleClose} variant="contained" color="error">
                    Close
                </Button>
            </Grid>
        </Grid>
    );
};

export default UploadedSuccessfullyComponent;
