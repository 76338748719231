import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import {
  Grid,
  Button,
} from '@mui/material';
const WebcamCapture = ({ setCapturedImage, setSelectedFile, hidden }) => {
  const webcamRef = useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Url = reader.result;
        setCapturedImage(null);
        setSelectedFile(base64Url);
      };
      reader.readAsDataURL(file);
    }
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setSelectedFile(null);
  };

  return (
    <>
      <Webcam
        audio={false}
        height={300}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={400}
        style={{
          borderRadius: '8px',
        }}
      />
      <Grid container alignItems="center" justifyContent="center" spacing={2} style={{ marginTop: '8px' }}>
        <Grid item xs={6}>
          <Button onClick={captureImage} style={{ width: '100%' }} variant="contained" color="primary">
            Capture Image
          </Button>
        </Grid>
        <Grid item xs={6}>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
            id="fileInput"
          />
          <label htmlFor="fileInput" style={{ width: '100%' }}>
            <Button component="span" style={{ width: '100%' }} variant="contained" color="primary">
              Choose File
            </Button>
          </label>
        </Grid>
      </Grid>
    </>

  )
}

export default WebcamCapture