import React, { useState } from 'react'
import {
    Grid,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import WebcamCapture from '../../Webcam/WebcamCapture';


const CaptureOrUploadImage = ({
    verifiedImage,
    existingImage,
    capturedImage,
    selectedFile,
    manualVerification,
    setManualVerification,
    setCapturedImage,
    setSelectedFile,
    handleImageUpload,
    handlePrimaryImageUpload,
    from,
    updatedAt,
    secondary_remark,
    remark,
    handleRemark
}) => {
    const { is_super } = useSelector((state) => state.GlobalVariables);
    const [isRemark, setIsRemark] = useState(false);
    const [remarkValidation, setRemarkValidation] = useState('')

    const ImagePreview = (image) => (
        <div style={{ textAlign: 'center' }}>
            <img src={image} alt="Preview" style={{ width: '97%', borderRadius: '8px' }} />
            {
                (updatedAt !== null && updatedAt !== undefined) &&
                <div style={{ marginTop: '8px' }}><b>VERIFIED</b>: {updatedAt}</div>
            }
            {
                (secondary_remark !== null && secondary_remark !== undefined) &&
                <div style={{ marginTop: '8px' }}><b>REMARK</b>: {secondary_remark}</div>
            }
        </div>
    );

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target;
        setIsRemark(checked);
    };

    const handleUpload = () => {
        if(isRemark === true && remark === null){
            setRemarkValidation("required");
            return false;
        }
        else{
            handleImageUpload();
        }
     }

    const handleManualVerificationChange = (event) => {
        setManualVerification(event.target.checked);
    };
    return (
        <><Grid item xs={12} md={6}>
            {(verifiedImage ?
                ImagePreview(verifiedImage)
                : (
                    (from !== 'fromVerified') && ((capturedImage || selectedFile) ? (
                        <>
                            {ImagePreview(capturedImage || selectedFile)}
                            <Grid container alignItems={'center'} justifyContent={'flex-start'}>
                                {existingImage && (
                                    <Grid item xs={6}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked={manualVerification} onChange={handleManualVerificationChange} />}
                                                label="Manual Verification"
                                            />
                                        </FormGroup>
                                    </Grid>
                                )}
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        name="status"
                                        checked={isRemark}
                                        onChange={handleChangeCheckbox}
                                        label="Remark"
                                        sx={{ fontWeight: "bold" }}
                                    />
                                </Grid>
                            {/* </Grid> */}

                            {/* <Grid container spacing={1}> */}
                                {is_super &&
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Button
                                            fullWidth
                                            disabled={existingImage && !manualVerification}
                                            onClick={handlePrimaryImageUpload}
                                            sx={{ my: 1 }}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Upload Primary
                                        </Button>
                                    </Grid>
                                }
                                {existingImage ? (
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Button
                                            fullWidth
                                            disabled={(!manualVerification && !isRemark)}
                                            onClick={handleUpload}
                                            sx={{ my: 1 }}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Upload & Verify
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Button
                                            fullWidth
                                            disabled={!isRemark}
                                            onClick={handleUpload}
                                            sx={{ my: 1 }}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Upload & Verify
                                        </Button>
                                    </Grid>
                                )}
                                {
                                    (isRemark === true) &&
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={2}
                                            name='remark'
                                            variant='outlined'
                                            value={remark || ""}
                                            placeholder='Enter comment here.'
                                            onChange={(e) => handleRemark(e.target.value)}
                                            error={!!remarkValidation}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </>
                    ) : (
                        <WebcamCapture
                            setCapturedImage={setCapturedImage}
                            setSelectedFile={setSelectedFile}
                            hidden={existingImage}
                        />
                    )
                    )
                )
            )}
        </Grid>

        </>
    )
}

export default CaptureOrUploadImage