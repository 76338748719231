import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Grid,
  FormControl,
  NativeSelect,
  InputLabel,
  Typography,
  IconButton,
  MenuItem,
  Select,
  Checkbox,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';
import { useEffect } from "react";
import { useSelector } from "react-redux";
import configServ from "../../../services/config";
import EnrollmentBulkUpload from "./EnrollmentBulkUpload";

//FUNCTION
const EnrollmentTable = ({
  handleEdit,
  handleImageUpload,
  isChanged,
  pagination = true,
}) => {
  //#region code
  const { user_id, company_id, is_super } = useSelector(
    (state) => state.GlobalVariables
  );
  const rowsPerPageOptions = [25, 100, 200, 500];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]); // Default rows per page
  const [customerList, setCustomerList] = useState([]);
  const [exam_id, setExam_id] = useState(null);
  const [searchBy, setSearchBy] = useState("roll_no");
  const [search, setSearch] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [examList, setExamList] = useState([]);
  const [exam, setExam] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEnrollmentBulkUploadOpen, setIsEnrollmentBulkUploadOpen] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [action,setAction] = useState('update');

  const handleSelectAll = () => {
    if (selectedRows.length === customerList.length) {
      setSelectedRows([]);
    } else {
      const allRowIds = customerList.map((row) => ({
        id: row.id,
        student_id: row.student_id,
      }));
      setSelectedRows(allRowIds);
    }
  };

  const handleRowCheckboxChange = (row) => {
    const selectedRowId = row.id;
    const selectedStudentId = row.student_id;

    if (
      selectedRows.some(
        (item) =>
          item.id === selectedRowId && item.student_id === selectedStudentId
      )
    ) {
      // Row is already selected, unselect it
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter(
          (item) =>
            !(
              item.id === selectedRowId && item.student_id === selectedStudentId
            )
        )
      );
    }
    else {
      // Row is not selected, select it
      setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        { id: selectedRowId, student_id: selectedStudentId },
      ]);
    }
  };

  useEffect(() => {
    fetchExams();
  }, []);

  useEffect(() => {
    fetchCustomerList();
  }, [isChanged, page, rowsPerPage, search, exam_id, isBulkUpload, action]);

  useEffect(() => {
    setSearch(null);
  }, [exam_id, searchBy]);

  const fetchCustomerList = async () => {
    try {
      const dataToSend = {
        user_id: user_id,
        is_super: is_super,
        company_id: company_id,
        exam_id: exam_id,
        search_by: searchBy,
        search: search,
        per_page: rowsPerPage,
        page: page,
        action: action,
      };
      setLoading(true);
      const result = exam_id  !== null? await configServ.getSubscriptions(dataToSend): await configServ.getUnenrolledSubscriptions(dataToSend);
      if (result.status === 200) {
        setCustomerList(result.data);
        setCount(result.total);
      }
      else{
        setCustomerList([]);
        setCount(0);
      }
    } 
    catch (error) {
      console.log(error);
    } 
    finally {
      setLoading(false);
    }
  };

  const headers = [
    "Roll No",
    "Candidate Name",
    "Exam Name",
    "Centre Name",
    "Action",
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const headerCellStyle = {
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "black",
    color: "white",
    padding: 5,
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const cellStyle = {
    py: 0,
    my: 0
  }

  // const handleVerification = (event) => {
  //   try {
  //     setVerified(event.target.value);
  //     setPage(0);
  //   } 
  //   catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    }
    catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    try {
      const data = event.target.value;
      if (data === "") {
        setSearch(null);
      }
      else {
        setSearch(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleName = (params) => {
    try {
      if (params.length > 0) {
        const name = params[0].full_name;
        return name;
      } 
      else {
        return "N/A";
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleExam = (params) => {
    try {
      if (params.length > 0) {
        const exam = params[0].item_name;
        return exam;
      } else {
        return "N/A";
      }
    }
    catch (e) {
      console.log(e);
    }
  };

  const handleExamId = (params) => {
    try {
      const data = parseInt(params.target.value);
      if (data > 0) {
        setExam_id(data);
        const examDetail = examList.find(x => x.id === data);
        setExam(examDetail);
      }
      else {
        setExam_id(null);
        setExam(null);
      }
      setPage(0);
    }
    catch (e) {
      console.log(e);
    }
  };

  const handleCenter = (params) => {
    try {
      if (params.length > 0) {
        const center = params[0].CompanyName;
        return center;
      } else {
        return "N/A";
      }
    } catch (e) {
      console.log(e);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchExams = async () => {
    try {
      const dataToSend = {
        'company_id': company_id
      }
      const response = await configServ.getUpcomingExamList(dataToSend);
      if (response.status === 200) {
        setExamList(response.data);
      }
    }
    catch (error) {
      console.error("Error fetching exams:", error);
    }
  };

  const handleOpenEnrollmentBulkUpload = () => {
    setIsEnrollmentBulkUploadOpen(true);
  };

  const handleCloseEnrollmentBulkUpload = () => {
    setSelectedRows([])
    setIsBulkUpload(!isBulkUpload);
    setIsEnrollmentBulkUploadOpen(false);
  }

  // Event handler for when the radio button value changes
  const handleRadioChange = (event) => {
    setAction(event.target.value);
  };

  //#endregion

  return (
    <>
      {isEnrollmentBulkUploadOpen && (
        <EnrollmentBulkUpload
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          onClose={handleCloseEnrollmentBulkUpload}
          action={action}
        />
      )}
      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        style={{ paddingBottom: 10, paddingTop: 0 }}
        spacing={1}
        direction={"row"}
      >
        <Grid item xs={12} md={12} justifyContent={"flex-start"}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Enrollment List: {count}
          </Typography>
        </Grid>

        <Grid item xs={12} md={2} justifyContent={"flex-end"}>
          {
            selectedRows.length > 0 &&
            <Button
              variant="contained"
              color="primary"
              disabled={selectedRows.length === 0}
              onClick={handleOpenEnrollmentBulkUpload}
              startIcon={<SecurityUpdateIcon />}
            >
              {action === "update"?"Update":"Promote"}
            </Button>
          }
        </Grid>
        <Grid item xs={12} md={3} justifyContent={"flex-start"}>
          <FormControl sx={{fontSize:'12px', margin:0, padding:0}}>
            {/* <FormLabel id="demo-row-radio-buttons-group-label">Action</FormLabel> */}
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={action} 
              onChange={handleRadioChange}
              sx={{margin:0,padding:0}}
            >
              <FormControlLabel value="update" control={<Radio size="small" />} label={<span style={{fontSize:'14px'}}>Update</span>} sx={{margin:0,padding:0}}/>
              <FormControlLabel value="promote" control={<Radio size="small" />} label={<span style={{fontSize:'14px'}}>Promote</span>} sx={{margin:0,padding:0}}/>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} justifyContent={"flex-start"}>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Examination
            </InputLabel>
            <NativeSelect
              defaultValue={0}
              onChange={handleExamId}
              inputProps={{
                name: "Examination",
                id: "uncontrolled-native",
              }}
            >
              <option key={0} value={0}>
                Select Exam
              </option>
              {examList.map((exam) => (
                <option key={exam.id} value={exam.id}>
                  {exam.item_name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1}>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Search By
            </InputLabel>
            <Select
              fullWidth
              size="small"
              variant="standard"
              label="Search By"
              name="searchBy"
              value={searchBy}
              onChange={handleSearchBy}
            >
            <MenuItem value={"roll_no"}>Roll</MenuItem>
              <MenuItem value={"full_name"}>Name</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} justifyContent={"flex-end"}>
          <TextField
            id="outlined-basic"
            size="small"
            fullWidth
            placeholder="search"
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "grey" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper} elevation={0}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={headerCellStyle}>
                <Checkbox
                  checked={selectedRows.length === customerList.length}
                  onChange={handleSelectAll}
                  sx={{ color: "white" }}
                />
              </TableCell>
              <TableCell style={headerCellStyle}>Sr. No.</TableCell>
              {headers.map((header, index) => (
                <TableCell key={index} style={headerCellStyle}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading === false && (
            <TableBody>
              {customerList.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{
                    ...rowStyle,
                    ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                  }}
                >
                  <TableCell sx={cellStyle}>
                    <Checkbox
                      checked={selectedRows.some(
                        (item) =>
                          item.id === row.id &&
                          item.student_id === row.student_id
                      )}
                      onChange={() => handleRowCheckboxChange(row)}
                    />
                  </TableCell>
                  <TableCell sx={cellStyle}>{page * rowsPerPage + rowIndex + 1}</TableCell>
                  <TableCell sx={cellStyle}>{(row.roll_no)}</TableCell>
                  <TableCell sx={cellStyle}>{handleName(row.students)}</TableCell>
                  <TableCell sx={cellStyle}>{handleExam(row.exams)}</TableCell>
                  <TableCell sx={cellStyle}>{handleCenter(row.centers)}</TableCell>
                  <TableCell sx={cellStyle}>
                    <Box
                      sx={{
                        borderRadius: 10,
                        width: "fit-content",
                        transition: "100ms",
                        cursor: "pointer",
                        padding: 0,
                        ":hover": { backgroundColor: "#99999955" },
                      }}
                    >
                      <IconButton
                        title="Edit"
                        onClick={() => {
                          handleEdit(row);
                          scrollToTop();
                        }}
                        arrow
                      >
                        <EditIcon color={"primary"} />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={headers.length + 2} />
                </TableRow>
              )} */}
            </TableBody>
          )}
          {loading === true && (
            <TableRow>
              <TableCell colSpan={12} sx={{ textAlign: 'center', color: 'red' }}>
                <div>
                  <CircularProgress />
                </div>
              </TableCell>
            </TableRow>
          )}
        </Table>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </>
  );
};

export default EnrollmentTable;
