import React, { useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  CircularProgress,
  InputAdornment,
  TextField,
  Grid,
  FormControl,
  NativeSelect,
  InputLabel,
  Typography,
  Button,
  Stack
} from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect } from 'react';
import configServ from '../../services/config';
import { useSelector } from 'react-redux';
import CheckAndReturn from '../../utility/CheckAndReturn';
import PrintIcon from '@mui/icons-material/Print';
import DownloadReport from '../../utility/DownloadReport';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadFirstStageReport from '../../utility/DownloadFirstStageReport';

//FUNCTION
const ExamReportComp = () => {
  //#region code
  const { user_id, company_id, companyName, companyCode, is_super, permissions } = useSelector((state) => state.GlobalVariables);
  const rowsPerPageOptions = [100, 200, 500, 1000];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [examReport, setExamReport] = useState([]);
  const [examList, setExamList] = useState([]);
  const [reportName, setReportName] = useState([]);
  const [exam_id, setExam_id] = useState(null);
  const [isFirstStage, setIsFirstStage] = useState(false);
  const [verification_type, setVerification_type] = useState(0);
  const [searchBy, setSearchBy] = useState('roll_no');
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cannotAdd, setCannotAdd] = useState(true);
  const [cannotUpdate, setCannotUpdate] = useState(true);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const checkPermissions = () => {
    try {
      const isAdd = CheckAndReturn.PermissionHandler('create-student', permissions);
      setCannotAdd(!isAdd);
      const isUpdate = CheckAndReturn.PermissionHandler('update-student', permissions);
      setCannotUpdate(!isUpdate);
    }
    catch (e) {
      console.log(e);
    }
  }

  const fetchExams = async () => {
    try {
      const response = await configServ.getItemList();
      if (response.status === 200) {
        setExamList(response.data);
      }
    }
    catch (error) {
      console.error("Error fetching exams:", error);
    }
  };

  useEffect(() => {
    checkPermissions();
    fetchExams();
  }, []);

  useEffect(() => {
    if (exam_id !== null) {
      fetchExamReport();
    }
  }, [page, rowsPerPage, search, exam_id]);

  const fetchExamReport = async () => {
    try {
      const dataToSend = {
        "user_id": user_id,
        "is_super": is_super,
        "company_id": company_id,
        "exam_id": exam_id,
        "search_by": searchBy,
        "search": search,
        "per_page": rowsPerPage,
        "page": page
      }
      setLoading(true);
      const res = await configServ.getExamReport(dataToSend);
      if (res.status === 200) {
        setExamReport(res.data);
        setCount(res.total);
      }
      else if (res.status === 400) {
        setExamReport([]);
        setCount(0);
      }
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  const downloadReport = async () => {
    try {
      const dataToSend = {
        "user_id": user_id,
        "is_super": is_super,
        "company_id": company_id,
        "exam_id": exam_id
      }
      const res = await configServ.examReport(dataToSend);
      if (res.status === 200) {
        var reportData = [];
        if (verification_type === 1) {
          reportData = res.data.map((item) => ({
            registration_no: item.registration_no,
            roll_no: item.roll_no,
            full_name: item.students[0].full_name,
            exam_code: item.exam_code,
            exam_name: item.exams[0].item_name,
            center_code: item.center_code,
            center_name: item.centers[0].CompanyName,
            is_present: isFirstStage === true ?
              ((item.secondary_image !== null || item.primary_fingerprint !== null) ? true : false) :
              ((item.secondary_image !== null || item.seconadry_fingerprint !== null) ? true : false),
            primary_image: item.primary_image === null ? false : true,
            primary_image_path: item.primary_image === null ? '' : item.primary_image.image,
            secondary_image: item.secondary_image === null ? false : true,
            secondary_image_path: item.secondary_image === null ? '' : item.secondary_image.image,
            secondary_image_verified: item.secondary_image === null ? false : (item.secondary_image.is_verified === 0 ? false : true),
            secondary_image_remark: item.secondary_image === null ? '' : (item.secondary_image.remark === null ? '' : item.secondary_image.remark),
            primary_fingerprint: item.primary_fingerprint === null ? false : true,
            primary_fingerprint_path: item.primary_fingerprint === null ? '' : item.primary_fingerprint.image,
            primary_fingerprint_remark: item.primary_fingerprint === null ? '' : (item.primary_fingerprint.remark === null ? '' : item.primary_fingerprint.remark),
            secondary_fingerprint: item.secondary_fingerprint === null ? false : true,
            secondary_fingerprint_path: item.secondary_fingerprint === null ? '' : item.secondary_fingerprint.image,
            secondary_fingerprint_verified: item.secondary_fingerprint === null ? false : (item.secondary_fingerprint.is_verified === 0 ? false : true),
            secondary_fingerprint_remark: item.secondary_fingerprint === null ? '' : (item.secondary_fingerprint.remark === null ? '' : item.secondary_fingerprint.remark),
            sync_at: item.sync_at === null ? '' : item.sync_at,
          }));
        }
        else if (verification_type === 2) {
          reportData = res.data.map((item) => ({
            registration_no: item.registration_no,
            roll_no: item.roll_no,
            full_name: item.students[0].full_name,
            exam_code: item.exam_code,
            exam_name: item.exams[0].item_name,
            center_code: item.center_code,
            center_name: item.centers[0].CompanyName,
            is_present: isFirstStage === true ?
              ((item.secondary_image !== null || item.primary_iris !== null) ? true : false) :
              ((item.secondary_image !== null || item.secondary_iris !== null) ? true : false),
            primary_image: item.primary_image === null ? false : true,
            primary_image_path: item.primary_image === null ? '' : item.primary_image.image,
            secondary_image: item.secondary_image === null ? false : true,
            secondary_image_path: item.secondary_image === null ? '' : item.secondary_image.image,
            secondary_image_verified: item.secondary_image === null ? false : (item.secondary_image.is_verified === 0 ? false : true),
            secondary_image_remark: item.secondary_image === null ? '' : (item.secondary_image.remark === null ? '' : item.secondary_image.remark),
            primary_iris: item.primary_iris === null ? false : true,
            primary_iris_path: item.primary_iris === null ? '' : item.primary_iris.image,
            primary_iris_remark: item.primary_iris === null ? '' : (item.primary_iris.remark === null ? '' : item.primary_iris.remark),
            secondary_iris: item.secondary_iris === null ? false : true,
            secondary_iris_path: item.secondary_iris === null ? '' : item.secondary_iris.image,
            secondary_iris_verified: item.secondary_iris === null ? false : (item.secondary_iris.is_verified === 0 ? false : true),
            secondary_iris_remark: item.secondary_iris === null ? '' : (item.secondary_iris.remark === null ? '' : item.secondary_fingerprint.remark),
            sync_at: item.sync_at === null ? '' : item.sync_at,
          }));
        }

        if (isFirstStage === true) {
          await DownloadFirstStageReport(reportData, verification_type, reportName);
        }
        else if (isFirstStage === false) {
          await DownloadReport(reportData, verification_type, reportName);
        }
      }
      else {
        //setReport([]);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleExam = (params) => {
    try {
      const data = parseInt(params.target.value)
      if (data > 0) {
        setExam_id(data);
        const examData = examList.find(x => x.id === data);
        setReportName(examData.item_name);
        const firstStage = examData.isFirstStage === 0 ? false : true;
        setIsFirstStage(firstStage);
        setVerification_type(examData.verification_type);
      }
      else {
        setExam_id(null);
        setIsFirstStage(false);
        setVerification_type(0);
      }
      setPage(0);
      setSearch('');
    }
    catch (e) {
      console.log(e);
    }
  }

  //const headers = ['Registration ID', 'Roll No', 'Full Name', 'Primary Photo', 'Secondary Photo', 'Photo Verified', 'Primary Biometric', 'Secondary Biometric', 'Biometric Verified'];

  const headers = ['Roll No', 'Name', 'Enrolled Time', 'Profile Pic', 'Exam Pic', 'Exam Finger'];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: 'bold',
    backgroundColor: 'black',
    color: 'white',
    padding: 5,
  };

  const evenRowStyle = {
    background: '#f5f5f5',
  };

  const oddRowStyle = {
    background: 'white',
  };

  const rowStyle = {
    '&:hover': {
      background: '#f1f9fe',
    },
  };

  const cellStyle = {
    py: 0,
    my: 0
  }

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleSearch = (event) => {
    try {
      const data = event.target.value;
      if (data === "") {
        setSearch(null);
      }
      else {
        setSearch(data);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const tableRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  //#endregion

  return (
    <>
      <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ paddingBottom: 10, paddingTop: 0 }} spacing={1} direction={'row'}>
        <Grid item xs={12} md={12} container justifyContent={'center'}>
          <Typography sx={{ fontSize: '24px', fontWeight: 'bold', color: '#1e61b4' }}>Exam Report Data</Typography>
        </Grid>

        <Grid item xs={12} md={3} container justifyContent='flex-start'>
          {
            exam_id !== null && <>
            <Stack direction={'row'}>
              <Button variant='contained' color='success' onClick={downloadReport} startIcon={<DownloadIcon />} sx={{ml:3}}/>
              <Button variant='contained' color='primary' onClick={handlePrint} startIcon={<PrintIcon />} sx={{ml:3}}/>
              </Stack>
            </>
          }
        </Grid>
        <Grid item xs={12} md={3} justifyContent={'flex-start'}>
          <Box>
            <FormControl
              fullWidth
            >
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Examination
              </InputLabel>
              <NativeSelect
                defaultValue={0}
                onChange={handleExam}
                inputProps={{
                  name: 'Examination',
                  id: 'uncontrolled-native',
                }}
              >
                <option key={0} value={0}>Select Exam</option>
                {examList.map((exam) => (
                  <option key={exam.id} value={exam.id}>{exam.item_name}</option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ maxWidth: 220 }}>
            <FormControl
              fullWidth
            >
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Search By
              </InputLabel>
              <NativeSelect
                defaultValue={'roll_no'}
                onChange={handleSearchBy}
                inputProps={{
                  name: 'searchBy',
                  id: 'uncontrolled-native',
                }}
              >
                <option value={'roll_no'}>Roll No</option>
                <option value={'registration_no'}>Registration No</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} mr={{ xs: 0, md: 0 }} justifyContent={'flex-end'}>
          <TextField
            id="outlined-basic"
            size="small"
            fullWidth
            placeholder="search"
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: 'grey' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper} elevation={0} ref={tableRef}>
        <Typography variant='h6' sx={{fontWeight:600,textAlign:'center'}}>{companyCode}-{companyName}</Typography>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={headerCellStyle}>Sr. No.</TableCell>
              {headers.map((header, index) => (
                <TableCell key={index} style={headerCellStyle}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading === false &&
            <TableBody>
              {
                (exam_id !== null) ?
                  (examReport.length > 0 ?
                    examReport.map((row, rowIndex) => (
                      <TableRow key={rowIndex}
                        sx={{
                          ...rowStyle,
                          ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                        }}
                      >
                        <TableCell sx={cellStyle}>{page * rowsPerPage + rowIndex + 1}</TableCell>
                        {/* <TableCell
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {row.registration_no}
                        </TableCell> */}
                        <TableCell sx={cellStyle}>
                          {row.roll_no}
                        </TableCell>
                        <TableCell sx={cellStyle}>
                          {row.students[0].full_name}
                        </TableCell>
                        <TableCell sx={cellStyle}>
                          {row.sync_at}
                        </TableCell>
                        <TableCell sx={cellStyle}>
                          {row.primary_image === null ? <CancelIcon color='error' /> :
                            <img
                              src={`data:image/jpg;base64,${row.primary_image.image}`}
                              alt="primary image"
                              style={{ width: "50px", height: "50px", borderRadius: "2px" }}
                            />
                          }
                        </TableCell>
                        <TableCell sx={cellStyle}>
                          {row.secondary_image === null ? <CancelIcon color='error' /> :
                            <img
                              src={`data:image/jpg;base64,${row.secondary_image.image}`}
                              alt="secondary image"
                              style={{ width: "50px", height: "50px", borderRadius: "2px" }}
                            />
                          }
                        </TableCell>
                        {/* <TableCell sx={cellStyle}>
                          {row.secondary_image === null ? <CancelIcon color='error' /> :
                            row.secondary_image.is_verified === 0 ? <CancelIcon color='error' /> : <CheckCircleIcon color='success' />}
                        </TableCell> */}
                        {
                          verification_type === 1 && <>
                            <TableCell sx={cellStyle}>
                              {row.primary_fingerprint === null ? <CancelIcon color='error' /> :
                                <img
                                  src={`data:image/jpg;base64,${row.primary_fingerprint.image}`}
                                  alt=""
                                  style={{ width: "50px", height: "50px", borderRadius: "2px" }}
                                />
                              }
                            </TableCell>
                            {/* <TableCell sx={cellStyle}>
                              {row.secondary_fingerprint === null ? <CancelIcon color='error' /> :
                                <img
                                  src={`data:image/jpg;base64,${row.secondary_fingerprint.image}`}
                                  alt="secondary fingerprint"
                                  style={{ width: "50px", height: "50px", borderRadius: "2px" }}
                                />
                              }
                            </TableCell>
                            <TableCell sx={cellStyle}>
                              {row.secondary_fingerprint === null ? <CancelIcon color='error' /> :
                                row.secondary_fingerprint.is_verified === 0 ? <CancelIcon color='error' /> : <CheckCircleIcon color='success' />}
                            </TableCell> */}
                          </>
                        }
                        {
                          verification_type === 2 && <>
                            <TableCell sx={cellStyle}>
                              {row.primary_iris === null ? <CancelIcon color='error' /> :
                                <img
                                  src={`data:image/jpg;base64,${row.primary_iris.image}`}
                                  alt=" primary iris"
                                  style={{ width: "50px", height: "50px", borderRadius: "2px" }}
                                />
                              }
                            </TableCell>
                            <TableCell sx={cellStyle}>
                              {row.secondary_iris === null ? <CancelIcon color='error' /> :
                                <img
                                  src={`data:image/jpg;base64,${row.secondary_iris.image}`}
                                  alt="secondary fingerprint"
                                  style={{ width: "50px", height: "50px", borderRadius: "2px" }}
                                />
                              }
                            </TableCell>
                            <TableCell sx={cellStyle}>
                              {row.secondary_iris === null ? <CancelIcon color='error' /> :
                                row.secondary_iris.is_verified === 0 ? <CancelIcon color='error' /> : <CheckCircleIcon color='success' />}
                            </TableCell>
                          </>
                        }
                      </TableRow>
                    )
                    ) :
                    <TableRow>
                      <TableCell colSpan={12} sx={{ textAlign: 'center', color: 'red' }}>
                        <div>
                          <p><b>No Candidates Found.</b></p>
                        </div>

                      </TableCell>
                    </TableRow>
                  )
                  :
                  (
                    <TableRow>
                      <TableCell colSpan={12} sx={{ textAlign: 'center', color: 'red' }}>
                        <div>
                          <p><b>Please select an examination.</b></p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
              }
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={headers.length + 2} />
                </TableRow>
              )} */}
            </TableBody>
          }
          {loading === true &&
            <TableRow>
              <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                <div>
                  <CircularProgress />
                </div>

              </TableCell>
            </TableRow>
          }
        </Table>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
  // }
};

export default ExamReportComp;
