import React, { memo, useEffect, useState } from "react";
import ItemTableCard from "../../Components/Card/MaterEntryCards/ItemTableCard";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  RadioGroup,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Grid,
  Autocomplete,
  Button,
  FormControlLabel,
  Checkbox,
  Container,
  IconButton
} from "@mui/material";
import MyFormControlLabel from "@mui/material/FormControlLabel";
import { Radio } from "@mui/material";
import configServ from "../../services/config";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Stack, Paper, Divider, Typography } from "@mui/material";
import { motion } from "framer-motion";
import AddItemImage from "./ItemEditOptions/AddItemImage";
import AddItemSize from "./ItemEditOptions/AddItemSize";
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import CardHeadMenu from "../Card/CardHeadMenu/CardHeadMenu";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CancelIcon from "@mui/icons-material/Cancel";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";
import Footer from "../Footer/Footer";
import AlertDialog from "../AlertDialog/AlertDialog";

//FUNCTION
const Items = () => {
  //#region code
  const { user_id, company_id, noRoomService } = useSelector((state) => state.GlobalVariables);
  const dispatch = useDispatch();
  const formDataInitial = {
    item_type: "single",
    SubscriptionStatus: "Monthly",
    openingquantity: "0",
    discount: "0",
    tax_rate: "0",
    start_date: '',
    end_date: '',
    isFirstStage: false,
    status: true,
  };
  const [formData, setFormData] = useState(formDataInitial);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [superCategoryList, setSuperCategoryList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [hsnList, setHsnList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [companyType, setCompanyType] = useState("product");
  const [hsn, setHsn] = useState("");
  const { company_type } = useSelector((state) => state.admin);

  const [tableData, setTableData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [openAddImage, setOpenAddImage] = useState(false);
  const [openAddSize, setOpenAddSize] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [isItemUpdated, setIsItemUpdated] = useState(false);
  const navigate = useNavigate();
  const [associatedItem, setAssociatedItem] = useState([
    {
      id: 1,
      item_id: "",
      qty: "",
    },
  ]);
  const [isCreate, setIsCreate] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState('Alert');
  const [dialogContent, setDialogContent] = useState('');
  const [buttonType, setButtonType] = useState(null);

  const initialShift = [{ shift_name: '', start_time: '', end_time: '' }];
  const [shifts, setShifts] = useState(initialShift);
  const [verificationType, setVerificationType] = useState([]);

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle('Alert');
    setDialogContent('');
    setButtonType(null);
  };

  const handlePopup = () => {
    try {
      setValidationErrors({})
      setIsCreate(!isCreate);
    } catch (error) {
      console.log(error);
    }
  };

  // const addAssociatedItem = () => {
  //   setAssociatedItem((state) => [
  //     ...state,
  //     {
  //       id: associatedItem.length + 1,
  //       item_id: "",
  //       qty: "",
  //     },
  //   ]);
  // };

  // const delAssociatedItem = (id) => {
  //   const updatedItemList = associatedItem.filter((item) => item.id !== id);
  //   setAssociatedItem(updatedItemList);
  // };

  // const handleChangeAssociatedItem = (id, event) => {
  //   const { name, value } = event.target;
  //   setAssociatedItem((state) =>
  //     state.map((item) => (item.id === id ? { ...item, [name]: value } : item))
  //   );
  //   if (name === "item_id") {
  //     const selectedItem = tableData.filter((item) => value === item.id);
  //     setAssociatedItem((state) =>
  //       state.map((item) =>
  //         item.id === id
  //           ? { ...item, item_name: selectedItem[0].item_name }
  //           : item
  //       )
  //     );
  //   }
  // };

  // const fetchItemList = async () => {
  //   try {
  //     const res = await configServ.getItemList({
  //       company_id: company_id,
  //     });
  //     if (res.status === 200) {
  //       setTableData(res.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const fetchSuperCategory = async () => {
  //   try {
  //     const result = await configServ.getSuperCategory();
  //     setSuperCategoryList(result);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));

  };

  const checkHandler = (e) => {
    const { name, checked } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));

  };

  const fetchCategory = async () => {
    try {
      //const result = await configServ.getcategoryByBrand({ "brand_id": formData.brand_id });
      const res = await configServ.getcategory({
        "company_id": company_id,
      });
      if (res.status === 200) {
        const result = res.data.filter(x => x.status === 1);
        const categoryResponse = result;
        categoryResponse.forEach((item) => {
          if (item.isPrimary === 1) {
            setFormData((prev) => ({
              ...prev,
              category_id: item.categoryid.toString()
            }));
          }
        });
        setCategoryList(categoryResponse);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchBrand = async () => {
    try {
      const res = await configServ.getBrand({
        "company_id": company_id,
      });
      if (res.status === 200) {
        const result = res.data.filter(x => x.status === 1);
        const brandResponse = result;
        brandResponse.forEach((item) => {
          if (item.isPrimary === 1) {
            setFormData((prev) => ({
              ...prev,
              brand_id: item.id.toString()
            }));
          }
        });
        setBrandList(brandResponse);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchGroup = async () => {
    try {
      // const res = await configServ.groupByCategory({
      //   "category_id": formData.category_id,
      // });

      // if (res.status === 200) {
      //   const result = res.data.filter(x => x.status === 1);
      //   const groupResponse = result;
      //   groupResponse.map((item) => {
      //     if (item.isPrimary === 1) {
      //       setFormData((prev) => ({
      //         ...prev,
      //         group_id: item.id
      //       }));
      //     }
      //   });
      //   setGroupList(groupResponse);
      // }
      // else{
      const resg = await configServ.getGroup({
        "company_id": company_id,
      });
      if (resg.status === 200) {
        const result = resg.data.filter(x => x.status === 1);
        const groupResponse = result;
        groupResponse.map((item) => {
          if (item.isPrimary === 1) {
            setFormData((prev) => ({
              ...prev,
              group_id: item.id
            }));
          }
        });
        setGroupList(groupResponse);
      }
      else {
        setGroupList([]);
      }
      // }
    }
    catch (error) {
      console.log(error);
    }
  };

  // const fetchLocation = async () => {
  //   try {
  //     const result = await configServ.getLocation({
  //       "company_id": company_id,
  //     });
  //     setLocationList(result);
  //   }
  //   catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchVerificationType = async () => {
    try {
      const verificationTypes = await configServ.getVerificationType();
      if (verificationTypes.status === 200) {
        setVerificationType(verificationTypes.data);
      }
      else {
        setVerificationType([]);
      }
    }
    catch (e) {
      console.log(e);
    }
  }


  const fetchUnit = async () => {
    try {
      const res = await configServ.getUnit({
        "company_id": company_id
      });
      if (res.status === 200) {
        const result = res.data.filter(x => x.status === 1);
        const unitResponse = result;
        unitResponse.map((item) => {
          if (item.isPrimary === 1) {
            setFormData((prev) => ({
              ...prev,
              unit: item.units_name
            }));
          }
        });
        setUnitList(unitResponse);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  // const fetchGstDetails = async () => {
  //   try {
  //     const result = await configServ.getGstDetails();
  //     setHsnList(result);
  //   } 
  //   catch (error) {
  //     console.log(error);
  //   }
  // };

  // const fetchHsnCode = (data) => {
  //   data.map((item) => {
  //     setHsnList((state) => [
  //       ...state,
  //       {
  //         label: item.hsncode,
  //         id: item.pk,
  //       },
  //     ]);
  //   });
  // };

  useEffect(() => {
    //fetchSuperCategory();
    fetchCategory();
    fetchBrand();
    fetchGroup();
    fetchVerificationType();
    fetchUnit();
    //fetchGstDetails();
    //fetchLocation();
    setValidationErrors({});
  }, []);

  // useEffect(()=>{
  //     if(formData.brand_id){
  //         fetchCategory();
  //     }
  // },[formData.brand_id]);

  const fetchVerificationTypeByCategory = async () => {
    try {
      const dataToSend = {
        brand_id: formData.brand_id,
        category_id: formData.category_id
      }
      const res = await configServ.getVerificationTypeByCategory(dataToSend);
      if (res.status === 200) {
        setFormData((prev) => ({
          ...prev,
          verification_type: res.data
        }));
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (formData.category_id && formData.brand_id) {
      fetchVerificationTypeByCategory();
    }
  }, [formData.category_id]);

  // useEffect(() => {
  //   if (formData.item_type === "composite") {
  //     fetchItemList();
  //   }
  // }, [formData.item_type]);

  // const itemUpdated = () => {
  //     setIsItemUpdated(!isItemUpdated)
  // }

  const handleEdit = async (data) => {
    setIsCreate(true);
    setValidationErrors({});
    setCompanyType(data.type);
    if (data.shifts.length > 0) {
      setShifts(data.shifts);
    }
    //setHsn(data.hsncode);

    const updatedFormData = { ...data };

    setFormData(updatedFormData);

    setIsEdit(true);

    // if (data.isItemAssociated === 1) {
    //   fetchAssociatedItem(data.id);
    // }
  };

  // const fetchAssociatedItem = async (id) => {
  //   try {
  //     const result = await configServ.getAssociateItem({
  //       associatedWith_id: id,
  //     });
  //     setAssociatedItem(result);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleSubmit = async () => {
    const itemData = {
      ...formData,
      shifts: shifts,
      hsncode: hsn,
      type: companyType,
      company_id: company_id,
      user_id: user_id,
    };

    const error = await handleFormValidation();
    if (error === false) {
      return false;
    }
    setValidationErrors({});
    if (!isEdit) {
      try {
        const res = await configServ.createItem(itemData);
        if (res.status === 200) {
          handleOpenDialog('Success', res.message, 'success');
          clear();
          setIsChanged(!isChanged);
        }
        else {
          handleOpenDialog('Error', res.message || "Failed to add exam.", 'error');
        }
      }
      catch (error) {
        console.log(error);
      }
    }
    else {
      try {
        const res = await configServ.updateItem(itemData);
        if (res.status === 200) {
          handleOpenDialog('Success', res.message, 'success');
          clear();
          setIsChanged(!isChanged);
        }
        else {
          handleOpenDialog('Error', res.message || "Failed to update exam.", 'error');
        }
      }
      catch (error) {
        console.log(error);
      }
    }
  };

  const clear = () => {
    setFormData(formDataInitial);
    setIsEdit(false);
    setHsn("");
    setCompanyType("");
    setValidationErrors({});
    setAssociatedItem([
      {
        id: 1,
        item_id: "",
        qty: "",
      },
    ]);
    setShifts(initialShift);
  };

  const handleCloseImage = () => {
    setOpenAddImage(false);
  };

  const handleOpenImage = () => {
    setOpenAddImage(true);
  };

  const handleCloseSize = () => {
    setOpenAddSize(false);
  };

  const handleOpenSize = () => {
    setOpenAddSize(true);
  };

  const formArrayZeroToN = (number) => {
    if (
      typeof number !== "number" ||
      !Number.isInteger(number) ||
      number <= 0
    ) {
      throw new Error("Input must be a positive integer");
    }

    return Array.from({ length: number }, (_, index) => index + 1);
  };

  const requiredLabel = {
    content: "*",
    color: "red" /* Change the color as needed */,
  };

  const Label = {
    color: "black" /* Change the color as needed */,
  };

  // const handleHSN = (selectedhsn) => {
  //   if (selectedhsn === null) {
  //     setHsn("");
  //     setFormData((state) => ({
  //       ...state,
  //       hsncode: "",
  //       tax_rate: "",
  //     }));
  //   }
  //   else {
  //     const hsn = hsnList.filter(
  //       (x) => x.hsncode === selectedhsn.hsncode.toString()
  //     )[0];
  //     setHsn(hsn.hsncode);
  //     setFormData((state) => ({
  //       ...state,
  //       hsncode: hsn.hsncode,
  //       tax_rate: hsn.GSTRate,
  //     }));
  //   }
  // };

  const handleFormValidation = async () => {
    const errors = {};
    if (!formData.brand_id) {
      errors.brand_id = "required";
    }
    if (!formData.category_id) {
      errors.category_id = "required";
    }
    if (!formData.group_id) {
      errors.group_id = "required";
    }
    // if (!formData.location_id) {
    //   errors.location_id = "required";
    // }
    if (!formData.item_name) {
      errors.item_name = "required";
    }
    if (!formData.item_code) {
      errors.item_code = "required";
    }
    // if (!formData.hsncode) {
    //   errors.hsncode = "required";
    // }
    if (!formData.unit) {
      errors.unit = "required";
    }
    // if (!formData.openingquantity) {
    //   errors.openingquantity = "required";
    // }
    // if (!formData.discount.toString()) {
    //   errors.discount = "required";
    // }
    if (!formData.verification_type) {
      errors.verification_type = "required";
    }
    if (!formData.rate_unit) {
      errors.rate_unit = "required";
    }
    if (!formData.start_date) {
      errors.start_date = "required";
    }
    if (!formData.end_date) {
      errors.end_date = "required";
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const handleClose = (params) => {
    clear();
    setIsCreate(false);
  };

  //Shift
  const handleAddShift = () => {
    setShifts([...shifts, { shift_name: '', time_start: null, time_end: null }]);
  };

  const handleRemoveShift = (index) => {
    const updatedShifts = [...shifts];
    updatedShifts.splice(index, 1);
    setShifts(updatedShifts);
  };

  const handleChangeTime = (index, field, value) => {
    const updatedShifts = [...shifts];
    updatedShifts[index][field] = value;
    setShifts(updatedShifts);
  };

  const handleChange = (index, field, value) => {
    const updatedShifts = [...shifts];
    updatedShifts[index][field] = value;
    setShifts(updatedShifts);
  };

  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />
      <Container>
        <CardHeadMenu
          prefix={"Na"}
          suffix={"Na"}
          title={"EXAMINATIONS"}
          handlePopup={handlePopup}
          isEdit={isEdit}
          create={isCreate}
        />
      </Container>
      {isCreate === true && (
        <Container>
          {/* <Grid
                container
                alignItems="flex-end"
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item xs={5} md={2}>
                  <h4>Business Type</h4>
                </Grid>
                <Grid item xs={6} md={3}>
                  <RadioGroup
                    name="use-radio-group"
                    defaultValue="first"
                    row
                    onChange={(e) => {
                      setCompanyType(e.target.value);
                    }}
                  >
                    <MyFormControlLabel
                      value="sales"
                      label="Sales"
                      control={<Radio size="small" />}
                      checked={
                        company_type === "product"
                          ? true
                          : false
                      }
                      disabled={
                        company_type === "service"
                      }
                    />
                    <MyFormControlLabel
                      value="service"
                      label="Service"
                      control={<Radio size="small" />}
                      checked={
                        company_type === "service"
                          ? true
                          : false
                      }
                      disabled={
                        company_type === "product"
                      }
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={6} md={2}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Application</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      //value={age}
                      //onChange={handleChange}
                      label="Application"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={'dcrm'}>DCRM</MenuItem>
                      <MenuItem value={'noroom'}>NoRoom</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid> */}

          <motion.div
            style={{
              width: "100%",
              backgroundColor: "#e3f3fc",
              padding: 20,
              borderRadius: "10px",
              border: "none",
            }}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.1, delay: 0.1 }}
          >
            <Grid
              container
              alignItems="flex-end"
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item xs={12} md={12} justifyContent={"flex-start"}>
                <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                  Exam Detail
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  id="brand"
                  options={brandList}
                  getOptionLabel={(option) => option.brand_name}
                  value={
                    brandList.find(
                      (item) => item.id.toString() === formData.brand_id
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    changeHandler({
                      target: {
                        name: "brand_id",
                        value: newValue?.id.toString() || "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="brand_id"
                      label={<span>Brand<span style={{ color: 'red' }}>*</span></span>}
                      variant="outlined"
                      fullWidth
                      error={!!validationErrors.brand_id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  id="category"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  options={categoryList}
                  getOptionLabel={(option) => option.categoryname}
                  value={
                    categoryList.find(
                      (item) =>
                        item.categoryid.toString() === formData.category_id
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    changeHandler({
                      target: {
                        name: "category_id",
                        value: newValue?.categoryid.toString() || "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="category_id"
                      label={<span>Category<span style={{ color: 'red' }}>*</span></span>}
                      variant="outlined"
                      fullWidth
                      error={!!validationErrors.category_id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  id="group"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  options={groupList}
                  getOptionLabel={(option) => option.group_name}
                  value={
                    groupList.find(
                      (item) => item.id === formData.group_id
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    changeHandler({
                      target: {
                        name: "group_id",
                        value: newValue?.id || "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="group_id"
                      label={<span>Tier/Stage<span style={{ color: 'red' }}>*</span></span>}
                      variant="outlined"
                      fullWidth
                      error={!!validationErrors.group_id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="item_name"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="item_name"
                  label={<span>Exam Name<span style={{ color: 'red' }}>*</span></span>}
                  variant="outlined"
                  fullWidth
                  value={formData.item_name || ""}
                  onChange={changeHandler}
                  error={!!validationErrors.item_name}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="item_code"
                  name="item_code"
                  label={<span>Exam Code<span style={{ color: 'red' }}>*</span></span>}
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formData.item_code || ""}
                  onChange={changeHandler}
                  error={!!validationErrors.item_code}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="itemType">Shift</InputLabel>
                  <Select
                    id="itemType"
                    size="small"
                    sx={{ backgroundColor: "white" }}
                    name="item_type"
                    value={formData.item_type || ""}
                    onChange={changeHandler}
                    label="Item Type*"
                  >
                    <MenuItem value="single">Single</MenuItem>
                    <MenuItem value="composite">Multiple</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="superCategory"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      options={superCategoryList}
                      getOptionLabel={(option) => option.name}
                      value={
                        superCategoryList.find(
                          (item) => item.id === formData.superCategory
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        changeHandler({
                          target: {
                            name: "superCategory",
                            value: newValue?.id || "",
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="superCategory"
                          label="Super Category"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid> */}
              <Grid item xs={12} md={3}>
                <TextField
                  id="itemDescription"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="description"
                  label={<span>Exam Description</span>}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  value={formData.description || ""}
                  onChange={changeHandler}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel htmlFor="verification_type" error={!!validationErrors.verification_type}>
                    Verification Type<span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Select
                    disabled={!Boolean(formData.isFirstStage)}
                    id="verification_type"
                    size="small"
                    sx={{ backgroundColor: "white" }}
                    name="verification_type"
                    value={formData.verification_type || ""}
                    onChange={changeHandler}
                    label={<span>Verification Type<span style={{ color: 'red' }}>*</span></span>}
                    error={!!validationErrors.verification_type}
                  >
                    {verificationType.map((item, index) => (
                      <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  id="unit"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  options={unitList}
                  getOptionLabel={(option) => option.units_name}
                  value={
                    unitList.find(
                      (item) => item.units_name === formData.unit
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    changeHandler({
                      target: {
                        name: "unit",
                        value: newValue?.units_name || "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="unit"
                      label={<span>Unit<span style={{ color: 'red' }}>*</span></span>}
                      variant="outlined"
                      fullWidth
                      error={!!validationErrors.unit}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="unitPrice"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="rate_unit"
                  label={<span>Time Duration<span style={{ color: 'red' }}>*</span></span>}
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={formData.rate_unit || ""}
                  onChange={changeHandler}
                  error={!!validationErrors.rate_unit}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  type="date"
                  label="Start Date"
                  name="start_date"
                  value={formData.start_date}
                  onChange={changeHandler}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  error={!!validationErrors.start_date}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  type="date"
                  label="End Date"
                  name="end_date"
                  value={formData.end_date}
                  onChange={changeHandler}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  error={!!validationErrors.end_date}
                />
              </Grid>
              {/* {company_type === "service" && (
                    <Grid item xs={12} md={3}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="subscriptionStatus">
                          Subscription Status
                        </InputLabel>
                        <Select
                          id="subscriptionStatus"
                          size="small"
                          sx={{ backgroundColor: "white" }}
                          name="SubscriptionStatus"
                          value={formData.SubscriptionStatus || ""}
                          onChange={changeHandler}
                          label="Subscription Status"
                        >
                          <MenuItem value="Monthly">Monthly</MenuItem>
                          <MenuItem value="Yearly">Yearly</MenuItem>
                          <MenuItem value="Trial">Trial</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )} */}

              {/* {formData.item_type === "composite" && (
                    <Grid
                      container
                      alignItems="flex-end"
                      justifyContent="flex-end"
                      mt={2}
                      spacing={1}
                      sx={{
                        borderRadius: 2,
                        border: "1px solid #33333322",
                        marginTop: 2,
                        marginLeft: 2,
                      }}
                    >
                      {associatedItem.map((item, index) => (
                        <Stack
                          key={item.id}
                          direction="row"
                          width="100%"
                          justifyContent="space-between"
                          spacing={2}
                          padding={2}
                        >
                          <FormControl sx={{ width: "100%" }} size="small">
                            <InputLabel>Item</InputLabel>
                            <Select
                              placeholder="Item Name"
                              label="Item Name"
                              sx={{
                                width: "100%",
                                backgroundColor: "white",
                              }}
                              name="item_id"
                              onChange={(e) => {
                                handleChangeAssociatedItem(item.id, e);
                              }}
                              value={item.item_id}
                            >
                              {tableData.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                  {item.item_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            label="Shift"
                            size="small"
                            type="text"
                            sx={{
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            name="shift_name"
                            onChange={(e) => {
                              handleChangeAssociatedItem(item.id, e);
                            }}
                            value={item.qty}
                          />
                          <TextField
                            label="Quantity"
                            size="small"
                            type="number"
                            sx={{
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            name="qty"
                            onChange={(e) => {
                              handleChangeAssociatedItem(item.id, e);
                            }}
                            value={item.qty}
                          />
                          <Stack direction="row" spacing={2}>
                            {associatedItem.length > 1 && (
                              <Tooltip title="Remove" arrow>
                                <Button
                                  color="danger"
                                  size="sm"
                                  variant="outlined"
                                  onClick={() => {
                                    delAssociatedItem(item.id);
                                  }}
                                >
                                  <RemoveCircleOutlineIcon />
                                </Button>
                              </Tooltip>
                            )}
                            <Tooltip title="Add" arrow>
                              <Button
                                color="info"
                                onClick={addAssociatedItem}
                                size="sm"
                                variant="solid"
                              >
                                <AddIcon />
                              </Button>
                            </Tooltip>
                          </Stack>
                        </Stack>
                      ))}
                    </Grid>
                  )} */}

              {
                formData.item_type === "composite" && (
                  <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    mt={2}
                    spacing={1}
                    sx={{
                      borderRadius: 2,
                      border: "none",
                      marginTop: 2,
                      marginLeft: 2,
                    }}
                  >
                    <Grid item xs={12} md={12} justifyContent={"flex-start"}>
                      <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                        Shift Detail
                      </Typography>
                    </Grid>
                    {shifts.map((shift, index) => (
                      <Grid container spacing={2} key={index}>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            sx={{ backgroundColor: 'white' }}
                            label="Shift Name"
                            value={shift.shift_name}
                            onChange={(e) => handleChange(index, 'shift_name', e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            type="time"
                            sx={{ backgroundColor: 'white' }}
                            label="Time Start"
                            value={shift.start_time}
                            onChange={(e) => handleChange(index, 'start_time', e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            type="time"
                            sx={{ backgroundColor: 'white' }}
                            label="Time End"
                            value={shift.end_time}
                            onChange={(e) => handleChange(index, 'end_time', e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          {
                            <IconButton onClick={handleAddShift}>
                              <AddBoxIcon color="success" />
                            </IconButton>
                          }
                          {
                            (shifts.length > 1) && (
                              <IconButton onClick={() => handleRemoveShift(index)}>
                                <DeleteIcon color="error" />
                              </IconButton>
                            )
                          }
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )
              }

              <Grid
                container
                my={2}
                spacing={1}
              >
                <Grid item xs={6} md={3}>
                  <FormControlLabel
                    name='status'
                    control={<Checkbox checked={Boolean(formData.status) || false} />}
                    onChange={checkHandler}
                    label="Status"
                    style={{ marginRight: '20px' }}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControlLabel
                    name='isFirstStage'
                    control={<Checkbox checked={Boolean(formData.isFirstStage) || false} />}
                    onChange={checkHandler}
                    label="Is First Stage?"
                    style={{ marginRight: '20px' }}
                  />
                </Grid>
                <Grid item xs={0} md={2}></Grid>
                <Grid container item xs={12} md={2}>
                  <Button
                    fullWidth
                    color="secondary"
                    onClick={clear}
                    variant="contained"
                    title={"Reset"}
                    startIcon={<RotateLeftIcon />}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid container item xs={12} md={2}>
                  <Button
                    fullWidth
                    color={isEdit ? 'success' : 'primary'}
                    onClick={handleSubmit}
                    variant="contained"
                    startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Grid>
                {/* {isEdit === true && (
                          <Button
                            color="success"
                            onClick={() => {
                              navigate("advance", { state: formData });
                            }}
                            autoFocus
                            variant="solid"
                            sx={{ minWidth: "15%" }}
                          >
                            Advance Options
                          </Button>
                        )} */}
              </Grid>
            </Grid>
          </motion.div>
        </Container>
      )}

      <Container>
        <ItemTableCard
          handleEdit={handleEdit}
          setIsChanged={setIsChanged}
          isChanged={isChanged}
        />
      </Container>
    </>
  );
};
export default memo(Items);
