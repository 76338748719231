import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { NavLink } from 'react-router-dom';

//Icon Imports
import SummarizeIcon from '@mui/icons-material/Summarize';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Zoom from '@mui/material/Zoom'
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListIcon from '@mui/icons-material/List';
import PublicIcon from '@mui/icons-material/Public';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CategoryIcon from '@mui/icons-material/Category';
import SellIcon from '@mui/icons-material/Sell';
import Person4Icon from '@mui/icons-material/Person4';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ScaleIcon from '@mui/icons-material/Scale';
import SiteLogo from '../Assets/images/DovetailCenterLogo_removebg_preview.png';
import { useSelector, useDispatch } from 'react-redux';
import { trigger } from '../../redux/reducers/SideExpand';
import MenuListCard from '../MenuListCard/MenuListCard';
import { motion, AnimatePresence } from 'framer-motion'
import { Button, Tooltip } from '@mui/material';
import CheckAndReturn from '../../utility/CheckAndReturn';
import { changeLightMode } from '../../redux/reducers/GlobalVariables';

const drawerWidth = 270;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const NavToolTip = ({ text, children }) => {
    return (
        <Tooltip TransitionComponent={Zoom} leaveDelay={200} title={text} placement="right" arrow sx={{ fontSize: '16px' }}>
            {children}
        </Tooltip>
    )
}

export default function MiniDrawer() {
    //#region code
    const dispatch = useDispatch();
    const open = useSelector((state) => state.sideExpand.open);
    const { is_super, permissions } = useSelector((state) => state.GlobalVariables);
    const { company_type } = useSelector((state) => state.admin);

    const [isMenuExpanded, setIsMenuExpanded] = React.useState(false)
    const [selectedMenu, setSelectedMenu] = React.useState(0)

    const theme = useTheme();
    //   const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        // setOpen(true);
        dispatch(trigger())
    };

    const handleDrawerClose = () => {
        // setOpen(false);
        dispatch(trigger())
    };

    //   React.useEffect(()=>{
    //     console.log('sidebar expanded:',open)
    //   },[open])

    const handleExpand = (number) => {
        setSelectedMenu(number)
        setIsMenuExpanded(!isMenuExpanded)
    }
    //#endregion

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                open={open}
                PaperProps={{
                    sx: {
                        backgroundColor: "#333",
                    }
                }}
            >
                <DrawerHeader>
                    <img src={SiteLogo} style={{ filter: 'invert(1)' }} alt="logo" width='150' hidden={open ? false : true} />
                    <IconButton onClick={handleDrawerClose}>
                        {!open ? <MenuIcon sx={{ color: 'white' }} /> : <ChevronLeftIcon sx={{ color: 'white' }} />}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                <NavLink to='/dashboard' style={{ textDecoration: 'none', color: '#FFF4F4' }}>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <NavToolTip text='Dashboard'><DashboardIcon sx={{ color: 'white' }} /></NavToolTip>
                            </ListItemIcon>
                            <ListItemText primary={'Dashboard'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>

                <Divider variant='middle' sx={{
                    backgroundColor: '#6270e488',
                    marginBottom: 2,
                }} />

                {(CheckAndReturn.PermissionHandler('access-master-entry', permissions) === true) &&
                    <><MenuListCard number={0} text={'Master Entry'} icon={<NavToolTip text='Master Entry'><ListIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />} isExpandable={true} handleExpand={handleExpand} /><AnimatePresence>
                        {(isMenuExpanded && selectedMenu === 0) && (<Box
                            component={motion.div}
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: '100%', opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            exit={{ height: 0, opacity: 0, transition: { duration: 0.3 } }}
                        >
                            <Divider sx={{ backgroundColor: 'black', marginLeft: 5 }} />
                            <MenuListCard url='/master-entry/brand' nested={true} fSize='0.9rem' text={'Brand'} icon={<NavToolTip text='Brand'><Person4Icon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                            {/* <MenuListCard url='/master-entry/batch' nested={true} fSize='0.9rem' text={'Batch'} icon={<NavToolTip text='Batch'><BatchPredictionIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} /> */}
                            <MenuListCard url='/master-entry/category' nested={true} fSize='0.9rem' text={'Category'} icon={<NavToolTip text='Category'><CategoryIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                            <MenuListCard url='/master-entry/group' nested={true} fSize='0.9rem' text={'Group'} icon={<NavToolTip text='Group'><SellIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                            <MenuListCard url='/master-entry/units' nested={true} fSize='0.9rem' text={'Unit'} icon={<NavToolTip text='Unit'><ScaleIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                            <MenuListCard url='/master-entry/item' nested={true} fSize='0.9rem' text={'Exams'} icon={<NavToolTip text='Exams'><ViewInArIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                            {/* <MenuListCard url='/master-entry/hsn' nested={true} fSize='0.9rem' text={'HSN'} icon={<NavToolTip text='HSN'><CheckBoxOutlineBlankIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} /> */}
                            {/* <MenuListCard url='/updateProduct' nested={true} fSize='0.9rem' number={2} text={'Update Product'} icon={<NavToolTip text='UpdateProduct'><Inventory2Icon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} /> */}
                            {/* <MenuListCard url='/super-category' nested={true} fSize='0.9rem' number={2} text={'Super Category'} icon={<NavToolTip text='Super Category'><ClassIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} /> */}
                            {/* <div hidden={is_super === 1 ? false : true}> */}
                            <MenuListCard url='/master-entry/location' nested={true} fSize='0.9rem' number={14} text={'Location'} icon={<NavToolTip text='Location'><LocationOnIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                            {/* </div> */}
                            <Divider sx={{ backgroundColor: 'black', marginLeft: 5 }} />
                        </Box>)}
                    </AnimatePresence></>}
                {/* <p>{selectedMenu}</p> */}
                {(CheckAndReturn.PermissionHandler('access-student', permissions) === true) && <MenuListCard url='/candidate' number={1} text={'Registration & Attendance'} icon={<NavToolTip text='Registration & Attendance'><GroupAddIcon HowToRegIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />}
                {(CheckAndReturn.PermissionHandler('access-student', permissions) === true) && <MenuListCard url='/verified-candidate' number={1} text={'Verified Candidate'} icon={<NavToolTip text='Verified Candidate'><GroupAddIcon HowToRegIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />}
                {(CheckAndReturn.PermissionHandler('access-enrollment', permissions) === true) && <MenuListCard url='/enroll' number={1} text={'Enrollment'} icon={<NavToolTip text='Enrollment'><HowToRegIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />}
                {(CheckAndReturn.PermissionHandler('access-access-master', permissions) === true) && <MenuListCard url='/access-master' number={1} text={'Access Master'} icon={<NavToolTip text='Access Master'><PublicIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />}
                {/* <MenuListCard url='/invoice' number={3} text={'Invoice'} icon={<NavToolTip text='Invoice'><ReceiptIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} /> */}
                {/* <MenuListCard url='/supplier' number={2} text={'Suppliers'} icon={<NavToolTip text='Suppliers'><Inventory2Icon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} /> */}
                {/* <div hidden={company_type === 'product'}>
                    <MenuListCard url='/candidate' number={15} text={'Subscriptions'} icon={<AssessmentIcon sx={{color:'white'}} />} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                    <MenuListCard url='/subscription' number={2} text={'Subscriptions'} icon={<NavToolTip text='Subscriptions'><AssessmentIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                </div>
                <MenuListCard url='/catalog' number={3} text={'Catalog'} icon={<NavToolTip text='Catalog'><MenuBookIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                <MenuListCard url='/sales-order' number={4} text={'Sales Order'} icon={<NavToolTip text='Sales Order'><MonetizationOnIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                <MenuListCard number={5} text={'Transaction'} icon={<NavToolTip text='Transaction'><AccountBalanceIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />} isExpandable={true} handleExpand={handleExpand} />
                <AnimatePresence>
                    {(isMenuExpanded && selectedMenu === 5) && (<Box
                        component={motion.div}
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: '100%', opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        exit={{ height: 0, opacity: 0, transition: { duration: 0.3 } }}
                    >
                        <Divider sx={{ backgroundColor: 'black', marginLeft: 5 }} />
                        <MenuListCard url='/transaction/create-receipts' nested={true} fSize='0.9rem' text={'Create Reciepts'} icon={<NavToolTip text='Create Reciepts'><CategoryIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                        <MenuListCard url='/transaction/payments' nested={true} fSize='0.9rem' text={'Payments'} icon={<NavToolTip text='Payments'><SellIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                        <MenuListCard url='/transaction/purchase-order' nested={true} fSize='0.9rem' text={'Purchase Order'} icon={<NavToolTip text='Purchase Order'><Person4Icon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                        <MenuListCard url='/transaction/purchase-bill' nested={true} fSize='0.9rem' text={'Purchase Bill'} icon={<NavToolTip text='Purchase Bill'><ViewInArIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                        <MenuListCard url='/transaction/credit-note' nested={true} fSize='0.9rem' text={'Credit Note'} icon={<NavToolTip text='Credit Note'><ScaleIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                        <MenuListCard url='/transaction/debit-note' nested={true} fSize='0.9rem' text={'Debit Note'} icon={<NavToolTip text='Debit Note'><CheckBoxOutlineBlankIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                        <Divider sx={{ backgroundColor: 'black', marginLeft: 5 }} />
                    </Box>)}
                </AnimatePresence>
                <MenuListCard url='/dispatch-details' number={6} text={'Dispatch Details'} icon={<NavToolTip text='Dispatch Details'><LocalShippingIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                <MenuListCard url='/account-master' number={7} text={'Account Master'} icon={<NavToolTip text='Account Master'><CreditCardIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                <MenuListCard url='/unregister-online-payment' number={7} text={'Unregister Online Payments'} icon={<NavToolTip text='Unregister Online Payments'><SavingsIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                <MenuListCard number={9} text={'Reports'} icon={<NavToolTip text='Reports'><SummarizeIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />} isExpandable={true} handleExpand={handleExpand} />
                <AnimatePresence>
                    {(isMenuExpanded && selectedMenu === 9) && (<Box
                        component={motion.div}
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: '100%', opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        exit={{ height: 0, opacity: 0, transition: { duration: 0.3 } }}
                    >
                        <Divider sx={{ backgroundColor: 'black', marginLeft: 5 }} />
                        <MenuListCard url='/report/srs' nested={true} fSize='0.9rem' text={'Sales Register Summary'} icon={<NavToolTip text='Sales Register Summary'><CategoryIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                        <MenuListCard url='/report/sri' nested={true} fSize='0.9rem' text={'Sales Register Itemwise'} icon={<NavToolTip text='Sales Register Itemwise'><SellIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                        <MenuListCard url='/report/order-report' nested={true} fSize='0.9rem' text={'Order Report Itemwise'} icon={<NavToolTip text='Order Report Itemwise'><Person4Icon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                        <MenuListCard url='/report/customer-report' nested={true} fSize='0.9rem' text={'Customer Report'} icon={<NavToolTip text='Customer Report'><ViewInArIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                        <MenuListCard url='/report/purchase-report' nested={true} fSize='0.9rem' text={'Purchase Report'} icon={<NavToolTip text='Purchase Report'><ScaleIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                        <Divider sx={{ backgroundColor: 'black', marginLeft: 5 }} />
                    </Box>)}
                </AnimatePresence> */}
                {(CheckAndReturn.PermissionHandler('access-center', permissions) === true) &&
                    <MenuListCard url='/center' number={10} text={'Center'} icon={<NavToolTip text='Center'><AppRegistrationIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                }
                {/* <MenuListCard url='/configuration' number={11} text={'Configuration'} icon={<NavToolTip text='Configuration'><SettingsIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} /> */}
                <MenuListCard number={12} url='/profile' text={'Profile'} icon={<NavToolTip text='Profile'><AccountBoxIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                {/* <MenuListCard number={13} url='/change-password' text={'Change Password'} icon={<NavToolTip text='Change Password'><PasswordIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} /> */}
                {(CheckAndReturn.PermissionHandler('access-exam-report', permissions) === true) &&
                    <MenuListCard number={12} url='/exam-report' text={'Exam Report'} icon={<NavToolTip text='Exam Report'><SummarizeIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                }
                <Divider />

            </Drawer>

        </Box >
    );
}