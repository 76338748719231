import React, { useState } from "react";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import Header from "../../Components/header/Header/Header";
import Footer from "../../Components/Footer/Footer";
import MiniDrawer from "../../Components/sidebar/SideBar";
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";
import UpdateIcon from '@mui/icons-material/Update';
import {
  Stack,
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import configServ from "../../services/config";

//FUNCTION
function ChangePassword() {
  //#region code
  const { encoded_data } = useSelector((state) => state.GlobalVariables);
  const open = useSelector((state) => state.sideExpand.open);
  const token = encoded_data;
  const decode = jwtDecode(token);
  const userEmail = decode.data.email;
  const initialError = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const initialPasswords = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [errors, setErrors] = useState(initialError);
  const [success, setSuccess] = useState(false);
  const [passwords, setPasswords] = useState(initialPasswords);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  const isValidPassword = async (passwords) => {
    if (!passwords.currentPassword) {
      setErrors({ currentPassword: "Current password is required" });
      return false;
    }

    if (!passwords.newPassword) {
      setErrors({ newPassword: "New password is required" });
      return false;
    }

    if (passwords.newPassword.length < 8) {
      setErrors({ newPassword: "New password must be at least 8 characters" });
      return false;
    }

    if (!passwords.confirmPassword) {
      setErrors({ confirmPassword: "Confirm password is required" });
      return false;
    }

    if (passwords.confirmPassword.length < 8) {
      setErrors({
        confirmPassword: "Confirm password must be at least 8 characters",
      });
      return false;
    }

    // Password strength check
    // const hasUpperCase = /[A-Z]/.test(passwords.newPassword);
    // const hasLowerCase = /[a-z]/.test(passwords.newPassword);
    // const hasDigit = /\d/.test(passwords.newPassword);
    // const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(passwords.newPassword);

    //   if (!(hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar)) {
    //     setErrors({
    //         newPassword: "New password must include at least one uppercase letter, one lowercase letter, one digit, and one special character"
    //     });
    //     return false;
    // }

    if (passwords.newPassword !== passwords.confirmPassword) {
      setErrors({
        newPassword: "Passwords do not match",
        confirmPassword: "Passwords do not match",
      });
      return false;
    }

    // Password is valid
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let cleanPassword = await isValidPassword(passwords);
      if (!cleanPassword) {
        return;
      }
      else {
        setErrors({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });

        const data = {
          email: userEmail,
          current_password: passwords.currentPassword,
          new_password: passwords.newPassword,
          new_password_confirmation: passwords.confirmPassword,
        };
        const res = await configServ.resetCustomerPassword(data);
        if (res.status === 200) {
          handleSuccess();
          setDialogOpen(true)
        }
        else {
          setErrors({
            currentPassword: "Error",
            newPassword: "Error",
            confirmPassword: "Error",
          });
          console.log(res);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSuccess = () => {
    setSuccess(true);
    setErrors(initialError);
    setPasswords(initialPasswords);
  };
  //#endregion

  return (
    <>
      <Typography
        variant="h5"
        color={'#1e61b4'}
      >
        RESET PASSWORD
      </Typography>

      {success && (
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>
            Alert
          </DialogTitle>
          <DialogContent>
            Password changed successfully.
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="success" onClick={() => setDialogOpen(false)}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <TextField
        required
        fullWidth
        label="Current Password"
        type="password"
        name="currentPassword"
        sx={{ backgroundColor: "white" }}
        value={passwords.currentPassword}
        onChange={handleChange}
        error={!!errors.currentPassword}
        helperText={errors.currentPassword}
      />

      <TextField
        required
        fullWidth
        label="New Password"
        type="password"
        name="newPassword"
        sx={{ backgroundColor: "white" }}
        value={passwords.newPassword}
        onChange={handleChange}
        error={!!errors.newPassword}
        helperText={errors.newPassword}
      />
      <TextField
        required
        fullWidth
        label="Confirm New Password"
        type="password"
        name="confirmPassword"
        sx={{ backgroundColor: "white" }}
        value={passwords.confirmPassword}
        onChange={handleChange}
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword}
      />

      <Button variant="contained" color="primary" onClick={handleSubmit} startIcon={<UpdateIcon />}>
        Update
      </Button>
    </>
  );
}

export default ChangePassword;
