import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { motion } from "framer-motion";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import axios from "axios";
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import config from '../../services/config'
import { useSelector } from "react-redux";
import configServ from "../../services/config";
import CheckAndReturn from "../../utility/CheckAndReturn";

//FUNCTION
const EnrollmentCard = ({ open, isChanged, setIsChanged, editData, setEditData }) => {
  //#region code
  const { company_id, user_id, permissions } = useSelector((state) => state.GlobalVariables);

  const initialFormData = {
    status: true,
    roll_no: "",
    full_name: "",
    exam_id: "",
    center_id: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [examList, setExamList] = useState([]);
  const [centreList, setCentreList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState('Alert');
  const [dialogContent, setDialogContent] = useState('');
  const [buttonType, setButtonType] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    roll_no: "",
    full_name: "",
    exam_id: "",
    center_id: "",
  });
  const [cannotAdd, setCannotAdd] = useState(true);
  const [cannotUpdate, setCannotUpdate] = useState(true);

  const checkPermissions = () => {
    try {
      const isAdd = CheckAndReturn.PermissionHandler('create-access-master', permissions);
      setCannotAdd(!isAdd);
      const isUpdate = CheckAndReturn.PermissionHandler('update-access-master', permissions);
      setCannotUpdate(!isUpdate);
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle('Alert');
    setDialogContent('');
    setButtonType(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
    if(name === 'exam_id' && value > 0){
      const exam_code = examList.filter(x => x.id === value)[0].item_code;
      setFormData((state) => ({
        ...state,
        'exam_code': exam_code, 
      }));
    }
    if(name === 'center_id' && value > 0){
      const center_code = centreList.filter(x => x.id === value)[0].CompanyCode;
      setFormData((state) => ({
        ...state,
        'center_code': center_code, 
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      const error = handleFormValidation();
      if (error === false) {
        return false;
      }
      const dataToSend = {
        ...formData,
        user_id: user_id
      }
      const res = await configServ.updateSubscriptions(dataToSend);
      if (res.status === 200) {
        setIsChanged(!isChanged);
        clear();
        handleOpenDialog('Success', res.message, 'success');
      }
      else {
        handleOpenDialog('Error', res.message || "Failed to update candidate enrollment.", 'error');
      }

    } catch (err) {
      console.log(err);
    }
  };

  const fetchExams = async () => {
    try {
      const response = await config.getItemList();
      if (response.status === 200) {
        setExamList(response.data);
      }
    }
    catch (error) {
      console.error("Error fetching exams:", error);
    }
  };

  const fetchCentres = async () => {
    try {
      const response = await config.getAllCompanyList();
      if (response.status === 200) {
        setCentreList(response.data);
      }
    }
    catch (error) {
      console.error("Error fetching centers:", error);
    }
  };

  const clear = () => {
    setFormData(initialFormData);
    setEditData({});
    setIsEdit(false);
  };

  useEffect(() => {
    checkPermissions();
    fetchExams();
    fetchCentres();
  }, []);

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setFormData((prev) => ({
        ...prev,
        id: editData.id,
        student_id: editData.students.length > 0 ? editData.students[0].id : '',
        roll_no: editData.roll_no ?? '',
        full_name: editData.students.length > 0 ? editData.students[0].full_name : '',
        exam_id: editData.exam_id,
        center_id: editData.center_id,
        status: editData.status
      }));
      //setFormData(editData);
      setIsEdit(true);
    }
  }, [editData]);

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  const handleFormValidation = () => {
    const errors = {};

    // Validation logic for roll_no
    if (!formData.roll_no) {
      errors.roll_no = "Roll No is required";
    }

    // Validation logic for full_name
    if (!formData.full_name) {
      errors.full_name = "Student Name is required";
    }

    // Validation logic for exam_id
    if (!formData.exam_id) {
      errors.exam_id = "Exam is required";
    }

    // Validation logic for center_id
    if (!formData.center_id) {
      errors.center_id = "Centre is required";
    }

    setValidationErrors(errors);

    return Object.values(errors).every(error => error === "");
  };
  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      {open && (
        <motion.div
          style={{
            width: "100%",
            backgroundColor: "#e3f3fc",
            marginTop: 20,
            padding: 20,
            borderRadius: "10px",
            border: "none",
          }}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.1, delay: 0.1 }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bolder" }}>
                Enrollment Details
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>Roll No</span>}
                name="roll_no"
                value={formData.roll_no || ""}
                error={!!validationErrors.roll_no}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>Student Name</span>}
                name="full_name"
                value={formData.full_name || ""}
                error={!!validationErrors.full_name}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="exam-label">Exam</InputLabel>
                <Select
                  disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                  sx={{ backgroundColor: 'white' }}
                  labelId="exam-label"
                  id="exam_id"
                  name="exam_id"
                  value={formData.exam_id || ""}
                  error={!!validationErrors.exam_id}
                  onChange={handleChange}
                >
                  {examList.map((exam) => (
                    <MenuItem key={exam.id} value={exam.id}>
                      {exam.item_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} >
              <FormControl fullWidth size="small">
                <InputLabel id="centre-label">Centre</InputLabel>
                <Select
                  disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                  sx={{ backgroundColor: 'white' }}
                  labelId="centre-label"
                  id="center_id"
                  name="center_id"
                  value={formData.center_id || ""}
                  error={!!validationErrors.center_id}
                  onChange={handleChange}
                >
                  {centreList.map((centre) => (
                    <MenuItem key={centre.id} value={centre.id}>
                      {centre.CompanyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container xs={12} md={12} mt={2}>
            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              <FormControlLabel
                control={<Checkbox />}
                name="status"
                checked={Boolean(formData.status)}
                onChange={handleChangeCheckbox}
                label="Status"
                sx={{ fontWeight: "bold" }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                    onClick={clear}
                    variant="contained"
                    color="secondary"
                    startIcon={<UpdateIcon />}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                    onClick={handleSubmit}
                    autoFocus
                    variant="contained"
                    startIcon={<UpdateIcon />}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </motion.div>
      )}
    </>
  );
};

export default EnrollmentCard;