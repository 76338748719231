import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  IconButton,
  Typography,
  Stack,
  Grid,
  InputLabel,
  NativeSelect,
  TextField,
  FormControl,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// import NestedTable from './NestedTable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';

//FUNCTION
const SupplierTable = ({ data, handleEdit, pagination = true }) => {
  //#region code
    const rowsPerPageOptions = [5,10,25];
    const [supplierList, setSupplierList] = useState(data);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [count, setCount] = useState(data !== null && data !== undefined? data.length:0);
    const [searchBy, setSearchBy] = useState('fname');

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

  const headers = ['SUPPLIER NAME', 'MAILING ADDRESS', 'EMAIL ID', 'MOBILE NO.', 'AMOUNT', 'STATUS','ACTION'];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, supplierList.length - page * rowsPerPage);

  const paginatedData = pagination
    ? supplierList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : supplierList;

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: 'bold',
    backgroundColor:'black',
    color:'white',
  };
  
  const evenRowStyle = {
    background: '#f5f5f5',
  };

  const oddRowStyle = {
    background: 'white',
  };
  
  const rowStyle = {
    '&:hover': {
      background: '#f1f9fe',
    },
  };

  const handleSearchBy = (event) => {
    try{
        setSearchBy(event.target.value);
    }
    catch(error){
        console.log(error);
    }
  }

  const handleSearch = (event) => {
    try{
        const search = event.target.value;
        let dataList = [];
        if(search === ''){
          dataList = data;
        }
        else if(searchBy === 'fname'){
          dataList = data.filter(x=>x.fname.toLowerCase().includes(search.toLowerCase()))
        }
        else if(searchBy === 'email'){
          dataList = data.filter(x=>x.email.toLowerCase().includes(search.toLowerCase()))
        }
        else if(searchBy === 'phone'){
          dataList = data.filter(x=>x.phone.toLowerCase().includes(search.toLowerCase()))
        }

        setSupplierList(dataList);
        setCount(dataList.length);
    }
    catch(error){
        console.log(error);
    }
  }
//#endregion

  return (
    <>
    {/* search starts here */}
    <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10, paddingTop: 0 }} spacing={1} direction={'row'}>
      <Grid item xs={12} md={6} ml={4} justifyContent={'flex-start'}>
          <Typography sx={{fontSize: '18px', fontWeight: 'bold'}}>Suppliers List :{data.length}</Typography>
      </Grid>
      <Grid item xs={4} md={2}>
      <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
          <FormControl 
          fullWidth
          >
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Search By
          </InputLabel>
          <NativeSelect
            
              onChange={handleSearchBy}
              inputProps={{
              name: 'searchBy',
              id: 'uncontrolled-native',
              }}
          >
              <option value={'fname'}>Supplier Name</option>
              <option value={'email'}>Email</option>
              <option value={'phone'}>Phone</option>
          </NativeSelect>
          </FormControl>
      </Box>
      </Grid>
      <Grid item xs={6} md={3} mr={{xs:0,md:4}} justifyContent={'flex-end'}>
      <TextField
          id="outlined-basic"
          size="small"
          fullWidth
          placeholder="search"
          variant="outlined"
          onChange={handleSearch}
          InputProps={{
              startAdornment: (
                  <InputAdornment position="start">
                  <SearchIcon style={{ fill: 'grey' }} />
                  </InputAdornment>
              ),
          }}
      />
      </Grid>
    </Grid>
    {/* search ends here */}
  
    <TableContainer component={Paper} elevation={0}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {/* <TableCell style={headerCellStyle}>
              <IconButton disabled>
                <VisibilityIcon />
              </IconButton>
            </TableCell> */}
            <TableCell style={headerCellStyle}>Sr. No.</TableCell>
            {headers.map((header, index) => (
              <TableCell key={index} style={headerCellStyle}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((row, rowIndex) => (
            <TableRow key={rowIndex}
            sx={{
              ...rowStyle,
              ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
            }}
            >
            {/* <TableCell style={iconCellStyle}>
                <Tooltip title='View details' arrow>
                    <IconButton onClick={()=>{handleNestedTable(row.id)}}>
                        <VisibilityIcon color='primary'  />
                    </IconButton>
                </Tooltip>
            </TableCell> */}
            <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
            <TableCell>{row.fname}</TableCell>
            <TableCell>{row.address}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.phone}</TableCell>
            <TableCell>{row.openingamount}</TableCell>
            <TableCell>{row.status === 1?<CheckCircleIcon color='success'/> : <CancelIcon color='error'/>}</TableCell>
            <TableCell>
              <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0.5, ":hover":{backgroundColor: '#99999955'} }}
              onClick={()=>{handleEdit(row);scrollToTop()}}
              >
                <Tooltip title='Edit' arrow>
                    <EditIcon color='primary'/>
                </Tooltip>
              </Box>
            </TableCell>
          </TableRow>
          ))}
          {/* {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={headers.length + 2} />
            </TableRow>
          )} */}
        </TableBody>
      </Table>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
    </>
  );
};

export default SupplierTable;
