import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import './Login.css'
import { useDispatch, useSelector } from "react-redux";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";
import { assignType } from '../../redux/reducers/Admin'
import Cookies from "universal-cookie/cjs/Cookies";
import { decodeToken } from 'react-jwt'
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import jwtDecode from 'jwt-decode';
import LoginIcon from '@mui/icons-material/Login';
import bk from '../../Components/Assets/images/wbk.png';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { Box, Typography, Container, TextField, FormControlLabel, Grid, Checkbox, Button, CircularProgress, InputAdornment, IconButton } from "@mui/material";
import configServ from "../../services/config";
import { rSetCompanyId, rSetCompanyName, rSetCompanyCode, rSetCurrency, rSetEncodedData, rSetIsSuper, rSetToken, rSetUserId, rSetUserPermissions } from "../../redux/reducers/GlobalVariables";
import OuterHeader1 from "../../Components/header/Header/OuterHeader1";

const AdminLogin = () => {
    //#region code
    const lightMode = useSelector(state=>state.GlobalVariables.lightMode);
    //const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const [formData, setFormData] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [loginError, setLoginError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    // const {isUserAdmin} = useSelector((state) => state.user)
    const { company_type } = useSelector((state) => state.admin)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cookie = new Cookies();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // to fetch data from text box
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const validate = async() => {
      try{
        const errors = {};
        if(!formData.email){
            errors.email = "required"
        }
        if(!formData.password){
            errors.password = "required"
        }

        setValidationErrors(errors);

        return Object.values(errors).every(error => error === "");
      }
      catch(e){
        console.log(e);
      }
    }

    // to handle login
    const handleLogin = async (e) => {
        e.preventDefault();
        const error = await validate();
        if(error === false){
            return false;
        }
        dispatch(triggerLoader());
        try {
            const res = await configServ.admin_login(formData);
            if (res.status === 200) {
                const result = res.result;
                localStorage.setItem("token", result.token);

                dispatch(rSetUserPermissions(result.permissions))
                dispatch(rSetUserId(result.userId));
                dispatch(rSetCompanyId(result.company_id));
                // const data = {
                //     id: result.company_id,
                // }
                // const companyRes = await configServ.getCompanyById(data);
                // if (companyRes.status === 200) {
                //     dispatch(rSetCompanyName(companyRes.data.CompanyName));
                // }
                // else{
                //     dispatch(rSetCompanyName('Select Center'));
                // }
                const compName = result.company_name?result.company_name:'Select Center';
                const compCode = result.company_code?result.company_code:'';
                dispatch(rSetCompanyName(compName));
                dispatch(rSetCompanyCode(compCode));
                dispatch(assignType(result.company_type));
                dispatch(rSetCurrency(result.currency));
                dispatch(rSetIsSuper(result.is_super));
                dispatch(rSetEncodedData(result.encoded_data));

                const decodedToken = decodeToken(result.token)
                cookie.set('dcrm_token', result.token, {
                    expires: new Date(decodedToken.exp * 1000)
                })
                cookie.set('userType', 'admin', {
                    expires: new Date(decodedToken.exp * 1000)
                })
                // const type = await configServ.getCompany({ "company_id": result.company_id });
                // if(type.status === 200){
                //     dispatch(assignType(type.data));
                // }
                // else{
                //     dispatch(assignType('service'));
                // }
                
                navigate('/Dashboard', { replace: true })
            }
            else{
                setLoginError(true);
            }
        }
        catch (err) {
            console.log(err);
            setLoginError(true);
        }
        finally{
            dispatch(triggerLoader())
        }
    }

    // const checkedLoggedIn = () => {
    //     if (cookie.get('dcrm_token') !== undefined) {
    //         //const decode = jwtDecode(cookie.get('dcrm_token'));
    //         if (user_id === null) {
    //             return;
    //         }
    //         if (cookie.get('userType') === 'customer') {
    //             navigate('/customer-dashboard')
    //         } else {
    //             navigate('/Dashboard')
    //         }
    //     }
    // }

    // useEffect(() => {
    //     checkedLoggedIn()
    // }, [])
    //#endregion

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    width: '100vw',
                    height: '100vh',
                    backgroundImage: `url(${bk})`,
                    //background: lightMode ? '#ffffff' : '#090e34',//'linear-gradient(to bottom, #F8F6F4, #D2E9E9)',
                    opacity: 1,
                    zIndex: -1
                }}
            >
                <AlertDialog open={loginError} setOpen={setLoginError} title={'Login Error'} message={'Invalid Login Credentials'} buttonTitle={'Close'} buttonType={'error'} />
                <Box
            sx={{
                //position:'absolute',
                //top:0,
                width:'100vw',
                height:'100vh',
                backgroundImage: `url(${bk})`,
                background: lightMode ? '#ffffff' : '#090e34',//'linear-gradient(to bottom, #F8F6F4, #D2E9E9)',
                opacity:1,
                zIndex:-2  
            }}
        >
            <OuterHeader1 />
                <Container component="main" maxWidth="sm">
                    <Box
                        sx={{
                            boxShadow: 3,
                            borderRadius: 2,
                            px: 4,
                            py: 6,
                            marginTop: 20,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            backgroundColor: '#ffffff'
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            <AccountCircleTwoToneIcon />Login
                        </Typography>
                        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={handleChange}
                                error={!!validationErrors.email}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                                error={!!validationErrors.password}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />


                            {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                <LoginIcon sx={{ margin: '5px 10px' }} />Login
                            </Button>

                            {/* <Grid container spacing={21}>
                            <Grid item>
                                <Link to='/register'>
                                    <Typography variant="body2" color='primary'>Don't have an account? Register</Typography>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to='/forgetPassword'>
                                    <Typography variant="body2" color='primary'>Forgot Password</Typography>
                                </Link>
                            </Grid>
                        </Grid> */}

                        </Box>
                    </Box>
                </Container>
                </Box>
            </Box>
        </>
    );
};
export default AdminLogin;