import React, { useState, useEffect, memo } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import configServ from "../../../services/config";
import UpdateIcon from "@mui/icons-material/Update";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch, useSelector } from "react-redux";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import { FormControl, InputLabel } from "@mui/material";

//FUNCTION
const EnrollmentBulkUpload = ({ selectedRows, setSelectedRows, onClose, action }) => {
    //#region code
    const dispatch = useDispatch();
    const { user_id } = useSelector((state) => state.GlobalVariables);
    const [examList, setExamList] = useState([]);
    const [centreList, setCentreList] = useState([]);
    const [selectedExam, setSelectedExam] = useState('');
    const [selectedCenter, setSelectedCenter] = useState('');
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        fetchExams();
        fetchCentres();
    }, []);

    const fetchExams = async () => {
        try {
            const response = await configServ.getItemList();
            if (response.status === 200) {
                setExamList(response.data);
            }
        } catch (error) {
            console.error("Error fetching exams:", error);
        }
    };

    const fetchCentres = async () => {
        try {
            const response = await configServ.getAllCompanyList();
            if (response.status === 200) {
                setCentreList(response.data);
            }
        } catch (error) {
            console.error("Error fetching centers:", error);
        }
    };

    const handleExamChange = (event) => {
        const { value } = event.target;
        setSelectedExam(value);

        const examCode = examList.filter(x => x.id === value)[0].item_code;

        // Update selectedRows with the selected exam ID
        const updatedRows = selectedRows.map(row => ({
            ...row,
            exam_id: value,
            exam_code: examCode,
        }));

        setSelectedRows(updatedRows);
    };

    const handleCenterChange = (event) => {
        const { value } = event.target;
        setSelectedCenter(value);

        const centerCode = centreList.filter(x => x.id === value)[0].CompanyCode;

        // Update selectedRows with the selected center ID
        const updatedRows = selectedRows.map(row => ({
            ...row,
            center_id: value,
            center_code: centerCode,
        }));
        setSelectedRows(updatedRows);
    };

    const handleUpdate = async () => {
        try {
            dispatch(triggerLoader());
            const res = await configServ.updateBulkSubscriptions(selectedRows);
            if (res.status === 200) {
                setUpdate(true);
            }
        }
        catch (error) {
            console.error("Error updating students:", error);
        }
        finally {
            dispatch(triggerLoader());
        }
    };

    const handlePromotion = async () => {
        try {
            dispatch(triggerLoader());
            const dataToSend = {
                user_id: user_id,
                exam_id: selectedRows[0].exam_id,
                center_id: selectedRows[0].center_id,
                data: selectedRows
            }
            const res = await configServ.promoteBulkSubscriptions(dataToSend);
            if (res.status === 200) {
                setUpdate(true);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            dispatch(triggerLoader());
        }
    }
    //#endregion

    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
            {update ? (
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CheckCircleOutlineIcon color='success' style={{ fontSize: 80 }} />
                    </div>
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>Candidates Enrolled Successfully</Typography>
                    <Grid container justifyContent="flex-end">
                        <Button variant="contained" color="success" onClick={onClose}>OK</Button>
                    </Grid>
                </DialogContent>
            ) : (
                <>
                    <DialogTitle>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Typography>
                                Selected Candidates: {selectedRows.length}
                            </Typography>
                            <IconButton color="error" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} direction="column">
                            <Grid item>
                                <FormControl fullWidth>
                                    <InputLabel>EXAMINATION</InputLabel>
                                    <Select
                                        variant="standard"
                                        size="small"
                                        fullWidth
                                        value={selectedExam}
                                        onChange={handleExamChange}
                                    >
                                        {examList.map((exam, index) => (
                                            <MenuItem key={index} value={exam.id}>{exam.item_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth>
                                    <InputLabel>CENTER</InputLabel>
                                    <Select
                                        variant="standard"
                                        size="small"
                                        fullWidth
                                        value={selectedCenter}
                                        onChange={handleCenterChange}
                                    >
                                        {centreList.map((center, index) => (
                                            <MenuItem key={index} value={center.id}>{center.CompanyName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item container justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                    <Button variant="contained" color="error" onClick={onClose} startIcon={<CloseIcon />}>Close</Button>
                                </Grid>
                                <Grid item>
                                    {
                                        action === "update"?
                                        <Button variant="contained" color="primary" onClick={handleUpdate} startIcon={<UpdateIcon />} disabled={!selectedExam || !selectedCenter}>Update</Button>
                                        :
                                        <Button variant="contained" color="primary" onClick={handlePromotion} startIcon={<BrowserUpdatedIcon />} disabled={!selectedExam || !selectedCenter}>Promote</Button>
                                    }                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </>
            )}
        </Dialog>

    );
};

export default memo(EnrollmentBulkUpload);
