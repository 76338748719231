import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


const ExistingImage = ({ existingImage,capturedAt, primary_remark }) => {
  return (
    <Grid item xs={12} md={6}>
      {existingImage ? (<>
        <img src={existingImage} alt="Existing" style={{ width: '100%', borderRadius: '8px' }} />
        {
          (capturedAt !== null && capturedAt !== undefined) &&
          <div style={{ marginTop: '8px' }}><b>CAPTURED</b>: {capturedAt}</div>
        }
        {
            (primary_remark !== null && primary_remark !== undefined) &&
            <div style={{ marginTop: '8px' }}><b>REMARK</b>: {primary_remark}</div>
        }
</>
      ) : (
        <Typography color="error" fontWeight={500}>Primary Image not found.</Typography>
      )}
    </Grid>
  );
};

export default ExistingImage;
