import React from "react";
import { Outlet } from "react-router-dom";

const MasterEntery = () => {
    return (
        <>
            <Outlet />
        </>
    )
}
export default MasterEntery;