import React, { memo } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

//FUNCTION
const ExcelEnrollDataPreview = ({ open, handleClose, excelData, handleSubmit, examList, examName, handleExamChange }) => {
  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid container>
          <Grid item xs={6} md={6} style={{ alignSelf: 'flex-start' }}>
            Total Candidates {excelData.length - 1}
          </Grid>
          {/* <Grid item xs={6} md={6} style={{ alignSelf: 'flex-end', marginBottom: '10px'  }}>
      <FormControl fullWidth size="small">
        <InputLabel id="exam-label">Exam Name</InputLabel>
        <Select
          fullWidth
          sx={{ backgroundColor: 'white' }}
          labelId="exam-label"
          label="Exam Name"
          id="exam_id"
          name="exam_id"
          value={examName}
          onChange={handleExamChange}
        >
          {examList.map((exam) => (
            <MenuItem key={exam.id} value={exam}>
              {exam.item_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid> */}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
              {excelData && excelData[0] && (
                <TableRow>
                  {Object.values(excelData[0]).map((cell, cellIndex) => (
                    <TableCell key={cellIndex} style={{ position: 'sticky', top: 0, backgroundColor: 'white', fontWeight: 'bold', paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0 }}>{cell}</TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {excelData?.slice(1).map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.values(row).map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='contained' startIcon={<CancelIcon />} color='error'>Close</Button>
        <Button onClick={handleSubmit} disabled={!examName} variant='contained' startIcon={<SaveIcon />}>Submit</Button>
      </DialogActions>
    </Dialog>

  );
};

export default memo(ExcelEnrollDataPreview);
