import React, { useState, useEffect } from "react";
import EnrollmentCard from './EnrollmentCard';
import { Box, Stack, Paper, Divider, Typography, Button, Grid, List, ListItem, ListItemText, Link } from "@mui/material";
import { useSelector } from 'react-redux';
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import EnrollmentTable from "../../Components/Card/Enrollment/EnrollmentTable";
import CheckAndReturn from "../../utility/CheckAndReturn";
import ExcelEnrollDialogBox from "../../Components/Card/Excel/ExcelEnrollDialogBox";
import { handleExcelDownload, validateExcelFile, readExcelFile, formatExcelData, createErrorLogFile } from "../../Components/Card/Excel/enrollExcelUtils";
import configServ from "../../services/config";
import ExcelEnrollDataPreview from "../../Components/Card/Excel/ExcelEnrollDataPreview";
import AlertDialog from "../../Components/AlertDialog/AlertDialog";

const Enrollment = () => {
    //#region code
    const { company_id, user_id, permissions } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const [openDialog, setOpenDialog] = React.useState(false);
    const [isChanged, setIsChanged] = useState(false)
    const [editData, setEditData] = useState({})
    const [openImageHandler, setOpenImageHandler] = useState(false);
    const [imageData, setImageData] = useState(null);
    //ALERT
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);
    //EXCEL
    const [excelDialog, setExcelDialog] = useState(null);
    const [examList, setExamList] = useState([]);
    const [examName, setExamName] = useState(null);
    const [centreList, setCentreList] = useState([]);
    const [centerName, setCenterName] = useState(null);
    const [openExcelDialog, setOpenExcelDialog] = useState(null);
    const [excelData, setExcelData] = useState(null);

    useEffect(() => {
        fetchExams();
        fetchCentres();
    }, []);

    const handleClickOpen = () => {
        setOpenDialog(!openDialog);
    };
    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleEdit = (data) => {
        console.log("row", data)
        setEditData(data);
        setOpenDialog(true);
    }

    const handleImage = (data) => {
        setImageData(data)
        setOpenImageHandler(true)
    }

    //EXCEL
    const handleExcelDialog = () => {
        setExcelDialog(!excelDialog)
    }

    const handleExamChange = (e) => {
        const selectedExamObject = e.target.value;
        setExamName(selectedExamObject)
    };

    const handleCenterChange = (e) => {
        const selectedCenterObject = e.target.value;
        setCenterName(selectedCenterObject)
    };

    const handleDownload = () => {
        const instructions = (
            <div>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Download Instructions:</Typography>
                <List>
                    <ListItem>
                        <ListItemText primary={<Typography variant="body1"><strong>File Name:</strong> The file name should include 'candidateEnrollmentTemplate'.</Typography>} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<Typography variant="body1"><strong>File Extension:</strong> Ensure the file has the '.xlsx' extension.</Typography>} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<Typography variant="body1"><strong>Header Integrity:</strong> Do not change the headers in the file.</Typography>} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<Typography variant="body1"><strong>Row Limit:</strong> The number of rows in the file should not exceed 1000.</Typography>} />
                    </ListItem>
                </List>
            </div>
        );
        setExamName(null)
        setCenterName(null)
        setExcelDialog(false)
        handleOpenDialog('Info', instructions, 'primary');
        handleExcelDownload(examName, centerName)
    }


    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
        setOpenExcelDialog(false)
        setExamName(null)
        setCenterName(null)
        setExcelDialog(false)
    };

    const handleCloseExcelDialog = () => {
        setExcelDialog(false)
        setExamName(null)
        setCenterName(null)
    }

    const handleExcelUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        try {
            await validateExcelFile(file, examName, centerName);
            const data = await readExcelFile(file);
            setExcelData(data);
            setOpenExcelDialog(true)
        }
        catch (error) {
            console.log(error);
            handleOpenDialog('Error', createErrorMessage(error.errorFileUrl, "Some Error Occured"), 'error');
        }
    };

    const createErrorMessage = (errorFileUrl, heading) => (
        <Box textAlign="center">
            <Typography variant="body1">{heading}</Typography>
            <Typography variant="body1">
                <Link href={errorFileUrl} download="error_log.txt">Click here</Link> for more info.
            </Typography>
        </Box>
    )

    const fetchExams = async () => {
        try {
            const response = await configServ.getItemList();
            if (response.status === 200) {
                setExamList(response.data);
            }
        }
        catch (error) {
            console.error("Error fetching exams:", error);
        }
    };

    const fetchCentres = async () => {
        try {
            const response = await configServ.getAllCompanyList();
            if (response.status === 200) {
                setCentreList(response.data);
            }
        }
        catch (error) {
            console.error("Error fetching centers:", error);
        }
    };

    const handleSubmit = async () => {
        try {
            const formattedExcel = await formatExcelData(excelData, examName, user_id);
            const response = await configServ.bulkEnrollStudent(formattedExcel);
            if (response.status === 200) {
                setExcelData(null)
                if (response.CandidatesWithRegistrationNotExists.length > 0 || response.duplicateRegNo.length > 0) {
                    const message = "Some roll number have already been enrolled for the same exam and have been skipped. Some candidates with following registration numbers do not exists and have been skipped. Please review the following roll numbers and registration numbers:";
                    const errorFileUrl = createErrorLogFile({
                        CandidatesWithRollAlreadyEnrolled: response.CandidatesWithRollAlreadyEnrolled,
                        CandidatesWithRegistrationNotExists: response.CandidatesWithRegistrationNotExists
                    }, message);
                    handleOpenDialog("Warning", createErrorMessage(errorFileUrl, "Upload Successful! Please Review: Duplicate Roll Numbers Detected for the Same Exam. These candidates Have Been Skipped."), "warning")
                }
                else {
                    handleOpenDialog('Success', 'Candidates Enrolled Successfully', 'success');
                }
            }
            else {
                handleOpenDialog('Error', 'Failed to upload file', 'error');
            }
        }
        catch (error) {
            console.error('Error:', error);
            handleOpenDialog('Error', 'Failed to upload file', 'error');
        }
        finally {
            setOpenExcelDialog(false);
        }
    };

    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />
            <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'Candidate Enrollment'} handlePopup={handleClickOpen} create={openDialog} hideAddButton={!CheckAndReturn.PermissionHandler('create-enrollment', permissions)} from={"RegCandidate"} handleExcelDialog={handleExcelDialog} isCreate={false} />
            <EnrollmentCard handleClose={handleClose} open={openDialog} isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} />
            <EnrollmentTable handleEdit={handleEdit} handleImage={handleImage} isChanged={isChanged} />
            <ExcelEnrollDialogBox open={excelDialog} onClose={handleCloseExcelDialog} handleDownload={handleDownload} handleExcelUpload={handleExcelUpload} examList={examList} centreList={centreList} examName={examName} centerName={centerName} handleExamChange={handleExamChange} handleCenterChange={handleCenterChange} />
            {
                excelData &&
                <ExcelEnrollDataPreview open={openExcelDialog} handleClose={handleCloseDialog} excelData={excelData} handleSubmit={handleSubmit} examList={examList} examName={examName} centerName={centerName} handleExamChange={handleExamChange} handleCenterChange={handleCenterChange} />
            }
        </>
    )
}
export default Enrollment