import http from "./http";
import axios from "axios";

//---------------------------------------------Customer API----------------------------------------------------------------
const login = (data) => {
    return http.post('/api/login', data)
}
const dashboardsummary = (data) => {
    return http.post('/api/dashboardsummary', data)
}
const resetAdminPassword = (data) => {
    return http.post('/api/admin-reset-password', data)
}
const resetCustomerPassword = (data) => {
    return http.post('/api/reset-password', data)
}
const profiledetail = (data) => {
    return http.post('/api/profiledetail', data)
}
const invoicelist = (data) => {
    return http.post('/api/invoicelist', data)
}
const invoice_detail = (data) => {
    return http.post('/api/invoice_detail', data)
}
const getInvoiceById = (data) => {
    return http.post('/api/get-invoice', data)
}
const customer_order_list = (data) => {
    return http.post('/api/customer_order_list', data)
}
const order_details = (data) => {
    return http.post('/api/order_details', data)
}
const payment_list = (data) => {
    return http.post('/api/payment_list', data)
}
const getsubs = (data) => {
    return http.post('/api/getsubs', data)
}
const getitem = (data) => {
    return http.post('/api/admin/getitem', data)
}
const getcategory = (data) => {
    return http.post('/api/get-category', data)
}
const getcategoryBySuperCategory = (data) => {
    return http.post('/api/admin/getcategory-by-super-category', data)
}
const getcategoryByBrand = (data) => {
    return http.post('/api/admin/getcategory-by-brand', data)
}
const getBrand = (data) => {
    return http.post('/api/get-brand', data)
}
const getGroup = (data) => {
    return http.post('/api/get-group', data)
}
const groupByCategory = (data) => {
    return http.post('/api/get-group-by-category', data)
}
const getUnit = (data) => {
    return http.post('/api/get-unit', data)
}
const get_item_img = (data) => {
    return http.post('/api/admin/get_item_img', data)
}
const getitemdetail = (data) => {
    return http.post('/api/admin/getitemdetail', data)
}
const getrecenttrans = (data) => {
    return http.post('/api/getrecenttrans', data)
}
const order = (data) => {
    return http.post('/api/order_entry', data)
}
const orderItem = (data) => {
    return http.post('/api/order_item_entry', data)
}
const placeOrder = (data) => {
    return http.post('/api/place-order', data)
}
const company_details = (data) => {
    return http.post('/api/company', data)
}
const company_logo = (data) => {
    return http.post('/api/company-logo', data)
}
const getItemDetailsByCompId = (data) => {
    return http.post('/api/getitemdetailsbycomp', data)
}
const getItemDetailsByCompIdPage = (data) => {
    return http.post('/api/getitemdetailsbycomppage', data)
}
const getCatalogCustomer = (data) => {
    return http.post('/api/get-catalog-customer', data)
}
const getDueAmouont = (data) => {
    return http.post('/api/due-amount', data)
}
const makePayment = (data) => {
    return http.post('/api/make-payment', data)
}
const getCompanyById = (data) => {
    return http.post('/api/get-company-details', data)
}
const getStudent = (data) => {
    return http.post('/api/get-student', data)
}
const getUnenrolledStudent = (data) => {
    return http.post('/api/get-unenrolled-student', data)
}
const getActiveExamStudent = (data) => {
    return http.post('/api/get-active-student', data)
}
const getUpcomingExamStudent = (data) => {
    return http.post('/api/get-upcoming-exam-students', data)
}
const getVerifiedStudents = (data) => {
    return http.post('/api/get-verified-student', data)
}
const editStudent = (data) => {
    return http.post('/api/update-student', data)
}
const addImage=(data)=>{
    return http.post('/api/add-student-image', data)
}
const addPrimaryStudentImage=(data)=>{
    return http.post('/api/add-primary-student-image', data)
}
const addPrimaryStudentFingerprint=(data)=>{
    return http.post('/api/add-primary-student-fingerprint', data)
}
const addStudentFingerprint=(data)=>{
    return http.post('/api/add-student-fingerprint', data)
}
const getPrimaryStudentImage=(data)=>{
    return http.post('/api/get-primary-student-image', data)
}
const getPrimaryStudentFingerprint=(data)=>{
    return http.post('/api/get-primary-student-fingerprint', data)
}
const getVerifiedImage=(data)=>{
    return http.post('/api/get-verified-student-image', data)
}


//-----------------------------------------------Admin API--------------------------------------------------------------------------------
const admin_login = (data) => {
    return http.post('/api/admin-login', data)
}
const recentInvoiceList = (company_id) => {
    return http.get(`/api/admin/invoice-list-recent?company_id=${company_id}`)
}
const invoiceListAdmin = (data) => {
    return http.post(`/api/admin/invoice-list`, data)
}
const itemDetails = (data) => {
    return http.get(`/api/admin/item_details?id=${data}`)
}
const salesOrderList = (data) => {
    return http.post('/api/admin/order_list', data)
}
const getCustomerById = (data) => {
    return http.post('/api/admin/customer-detail', data)
}
const getCompany = (data) => {
    return http.post('/api/company', data)
}
const getGstDetails = () => {
    return http.get('/api/gst-details')
}
const createItem = (data) => {
    return http.post('/api/create-item', data)
}
const updateItem = (data) => {
    return http.post('/api/update-item', data)
}
const getItemList = (data) => {
    return http.post('/api/get-item-list', data)
}
const getActiveExamList = (data) => {
    return http.post('/api/get-active-item-list', data)
}
const getUpcomingExamList = (data) => {
    return http.post('/api/get-upcoming-exam-list', data)
}
const AddItemImage = (data) => {
    return http.post('/api/admin/add_item_img', data)
}
const AddBase64ItemImage = (data) => {
    return http.post('/api/add_base64_item_img', data)
}
const addItemSize = (data) => {
    return http.post('/api/add_item_size', data)
}
const getItemSize = (data) => {
    return http.post('/api/get_item_size', data)
}
const updateItemSize = (data) => {
    return http.post('/api/update_item_size', data)
}
const updateItemImage = (data) => {
    return http.post('/api/update_item_image', data)
}
const get_customer = (data) => {
    return http.post('/api/get-customer', data)
}
const getShipToAddress = (data) => {
    return http.post('/api/get-shiptoaddress', data)
}
const addGroup = (data) => {
    return http.post('/api/add-group', data)
}
const editGroup = (data) => {
    return http.post('/api/edit-group', data)
}
const addCategory = (data) => {
    return http.post('/api/add-category', data)
}
const editCategory = (data) => {
    return http.post('/api/edit-category', data)
}
const addBrand = (data) => {
    return http.post('/api/add-brand', data)
}
const editBrand = (data) => {
    return http.post('/api/edit-brand', data)
}
const addUnit = (data) => {
    return http.post('/api/add-unit', data)
}
const editUnit = (data) => {
    return http.post('/api/edit-unit', data)
}
const getSimpleUnit = (data) => {
    return http.post('/api/get-simple-unit', data)
}
const addHsn = (data) => {
    return http.post('/api/add-hsn', data)
}
const editHsn = (data) => {
    return http.post('/api/edit-hsn', data)
}
const createInvoice = (data) => {
    return http.post('/api/create-invoice', data)
}
const editInvoice = (data) => {
    return http.post('/api/edit-invoice', data)
}
const getAssociateItem = (data) => {
    return http.post('/api/get-associated-item', data)
}
const getCustomerListForAdmin = (data) => {
    return http.post('/api/get-customer-list', data)
}
const createSaleOrder = (data) => {
    return http.post('/api/create_sales_order', data)
}
const editSaleOrder = (data) => {
    return http.post('/api/edit_sales_order', data)
}
const addCustomer = (data) => {
    return http.post('/api/add-customer', data)
}
const addStudent = (data) => {
    return http.post('/api/add-student', data)
}
const bulkAddStudent = (data) => {
    return http.post('/api/bulk-add-student', data)
}
const bulkEnrollStudent = (data) => {
    return http.post('/api/bulk-enroll-student', data)
}
const editCustomer = (data) => {
    return http.post('/api/edit-customer', data)
}
const getSuppliers = (data) => {
    return http.post('/api/suppliers', data)
}
const addSupplier = (data) => {
    return http.post('/api/add-supplier', data)
}
const editSupplier = (data) => {
    return http.post('/api/edit-supplier', data)
}
const createPurchaseOrder = (data) => {
    return http.post('/api/purchaseordercreate', data)
}
const editPurchaseOrder = (data) => {
    return http.post('/api/purchaseorderedit', data)
}
const getUserDetail = (data) => {
    return http.post('/api/get-user-detail', data)
}
const purchaseOrderList = (data) => {
    return http.post('/api/purchaseorderList', data)
}
const getCompanyList = (data) => {
    return http.post('/api/get-company-list', data)
}
const getAllCompanyList = (data) => {
    return http.post('/api/get-all-company-list', data)
}
const setActiveCompany = (data) => {
    return http.post('/api/set-active-company', data)
}
const addLocation = (data) => {
    return http.post('/api/add-location', data)
}
const editLocation = (data) => {
    return http.post('/api/edit-location', data)
}
const getLocation = (data) => {
    return http.post('/api/get-location', data)
}
const getLocationById = (data) => {
    return http.post('/api/get-location-by-id', data)
}
const getSubscriptionList = (data) => {
    return http.post('/api/get-subscription-list', data)
}
const getOrderDetails = (data) => {
    return http.post('/api/get-order-details', data)
}
const printSalesOrder = (data) => {
    return http.post('/api/print-order', data)
}
const addCompany = (data) => {
    return http.post('/api/create-company', data)
}
const editCompany = (data) => {
    return http.post('/api/edit-company', data)
}
const getAdminList = () => {
    return http.get('/api/get-admin-list')
}
const getStateCodeByname = (data) => {
    return http.post('/api/state-code-by-name', data)
}
const getStateByCidAddress = (data) => {
    return http.post('/api/state-by-cid-address', data)
}
const getCustomerContact = (data) => {
    return http.post('/api/get-contact', data)
}
const addCustomerContact = (data) => {
    return http.post('/api/add-contact', data)
}
const editCustomerContact = (data) => {
    return http.post('/api/edit-contact', data)
}
const getLocationByCompanyType = (data) => {
    return http.post('/api/location-compid', data)
}
const getLocationAdminComp = (data) => {
    return http.post('/api/location-compid-adminid', data)
}
const getDashboardCount = (data) => {
    return http.post('/api/dashboard-count', data)
}
const createCatalog = (data) => {
    return http.post('/api/create-catalog', data)
}
const getCatalog = (data) => {
    return http.post('/api/get-catalog', data)
}
const editCatalog = (data) => {
    return http.post('/api/edit-catalog', data)
}
const printInvoice = (data) => {
    return http.post('/api/print_invoice2', data)
}
const getDispatch = (data) => {
    return http.post('/api/get-dispatch', data)
}
const createDispatch = (data) => {
    return http.post('/api/create-dispatch', data)
}
const editDispatch = (data) => {
    return http.post('/api/edit-dispatch', data)
}
const getpaymentListAdmin = (data) => {
    return http.post('/api/get-paymentlist-admin', data)
}
const getCompanyDetails = (data) => {
    return http.post('/api/get-company', data)
}
const getNeighbourhoodType = (data) => {
    return http.post('/api/get-neighbourhood-type', data)
}
const addNeighbourhood = (data) => {
    return http.post('/api/add-neighbourhood', data)
}
const getNeighbourhood = (data) => {
    return http.post('/api/get-neighbourhood', data)
}
const editNeighbourhood = (data) => {
    return http.post('/api/edit-neighbourhood', data)
}
const addLocationImg = (data) => {
    return http.post('/api/add-location-img', data)
}
const editLocationImg = (data) => {
    return http.post('/api/edit-location-img', data)
}
const getLocationImg = (data) => {
    return http.post('/api/get-location-img', data)
}
const createSuperCategory = (data) => {
    return http.post('/api/create-super-category', data)
}
const editSuperCategory = (data) => {
    return http.post('/api/edit-super-category', data)
}
const getSuperCategory = (data) => {
    return http.post('/api/get-super-category', data)
}
const deleteSuperCategory = (data) => {
    return http.post('/api/del-super-category', data)
}
const getApplication = () => {
    return http.get('/api/get-application')
}
const getCountry = () => {
    return http.post('/api/get-country')
}
const getState = (data) => {
    return http.post('/api/get-state',data)
}
const getCity = (data) => {
    return http.post('/api/get-city',data)
}
const getAllCities = (data) => {
    return http.get('/api/get-all-city',data)
}
const addCity = (data) => {
    return http.post('/api/add-city',data)
}
const updateCity = (data) => {
    return http.post('/api/update-city',data)
}
const addDistrict = (data) => {
    return http.post('/api/add-district',data)
}
const updateDistrict = (data) => {
    return http.post('/api/update-district',data)
}
const getDistrict = (data) => {
    return http.post('/api/get-district',data)
}
const getDistrictByState = (data) => {
    return http.post('/api/get-district-by-state',data)
}
const getStates = () => {
    return http.post('/api/get-all-states')
}
const addMailConfiguration = (data) => {
    return http.post('/api/add-mail-configuration',data)
}
const getMailConfigurations = (data) => {
    return http.post('/api/get-mail-configuration',data)
}
const editMailConfigurations = (data) => {
    return http.post('/api/edit-mail-configuration',data)
}
const getRoles = (data) => {
    return http.post('/api/get-roles',data)
}
const createRole = (data) => {
    return http.post('/api/create-role',data)
}
const updateRole = (data) => {
    return http.post('/api/update-role',data)
}
const getPermissions = (data) => {
    return http.post('/api/get-permissions',data)
}
const createAccessMasterUser = (data) => {
    return http.post('/api/create-access-master-user',data)
}
const updateAccessMasterUser = (data) => {
    return http.post('/api/update-access-master-user',data)
}
const getAccessMasterUser = (data) => {
    return http.post('/api/get-access-master-user',data)
}
const createLineItems = (data) => {
    return http.post('/api/create-line-item',data)
}
const DeactivateLineItems = (data) => {
    return http.post('/api/deactivate-line-item',data)
}
const getLineItems = (data) => {
    return http.post('/api/get-line-items',data)
}
const getLineItemQR = (data) => {
    return http.post('/api/get-line-item-qr',data)
}
const getBatch = (data) => {
    return http.post('/api/get-batch', data)
}
const createBatch = (data) => {
    return http.post('/api/create-batch', data)
}
const updateBatch = (data) => {
    return http.post('/api/update-batch', data)
}
const deactivateBatch = (data) => {
    return http.post('/api/deactivate-batch', data)
}
const createSubscriptions = (data) => {
    return http.post('/api/create-student-subscription', data)
}
const updateSubscriptions = (data) => {
    return http.post('/api/update-student-subscription', data)
}
const getSubscriptions = (data) => {
    return http.post('/api/get-student-subscriptions', data)
}
const getUnenrolledSubscriptions = (data) => {
    return http.post('/api/get-unenrolled-student-subscriptions', data)
}
const promoteBulkSubscriptions = (data) => {
    return http.post('/api/promote-students-subscriptions', data)
}
const updateBulkSubscriptions = (data) => {
    return http.post('/api/update-students-subscriptions', data)
}
const verifyPrimaryStudentFingerprint = (data) => {
    return http.post('/api/verify-primary-student-fingerprint', data)
}
const getVerificationType = () => {
    return http.get('/api/get-verification-type')
}
const getVerificationTypeByCategory = (data) => {
    return http.post('/api/get-verification-type-by-category', data)
}
const getExamReport = (data) => {
    return http.post('/api/get-exam-report', data)
}
const examReport = (data) => {
    return http.post('/api/exam-report', data)
}
//-----------------------------------------------Outside dcrm-----------------------------------------------------------
const createEInvoice = async (data) => {
    const baseUrl = 'http://localhost:37597'
    // const baseUrl = 'http://egstapi.d-crm.in'
    const url = '/api/Invoice/GenerateIRN'
    const response = await axios.post(`${baseUrl}${url}`, data);
    return response
    // response.then((res) => {
    //     // console.log('Response:', res);
    //     return res
    // }).catch((err) => {
    //     // console.error('Error:', err);
    //     return err
    // });
}




const configServ = {
    //-----------------------------------------------Customer-----------------------------------------------------------
    login,
    dashboardsummary,
    resetAdminPassword,
    resetCustomerPassword,
    profiledetail,
    invoicelist,
    invoice_detail,
    getInvoiceById,
    customer_order_list,
    order_details,
    payment_list,
    getsubs,
    getitem,
    getcategory,
    getcategoryBySuperCategory,
    getcategoryByBrand,
    getBrand,
    getGroup,
    groupByCategory,
    getUnit,
    get_item_img,
    getitemdetail,
    getrecenttrans,
    order,
    orderItem,
    placeOrder,
    company_details,
    company_logo,
    getItemDetailsByCompId,
    getItemDetailsByCompIdPage,
    getCatalogCustomer,
    getDueAmouont,
    makePayment,
    getSuperCategory,
    deleteSuperCategory,
    getCompanyById,

    //-------------------------------------------------Admin---------------------------------------------------------------     
    admin_login,
    recentInvoiceList,
    invoiceListAdmin,
    itemDetails,
    salesOrderList,
    getCustomerById,
    getCompany,
    getGstDetails,
    createItem,
    updateItem,
    getItemList,
    getActiveExamList,
    getUpcomingExamList,
    AddItemImage,
    AddBase64ItemImage,
    addItemSize,
    getItemSize,
    updateItemSize,
    updateItemImage,
    get_customer,
    getShipToAddress,
    addGroup,
    editGroup,
    addCategory,
    editCategory,
    addBrand,
    editBrand,
    addUnit,
    editUnit,
    getSimpleUnit,
    addHsn,
    editHsn,
    createInvoice,
    editInvoice,
    getAssociateItem,
    getCustomerListForAdmin,
    createSaleOrder,
    editSaleOrder,
    addCustomer,
    editCustomer,
    getSuppliers,
    addSupplier,
    editSupplier,
    createPurchaseOrder,
    editPurchaseOrder,
    getUserDetail,
    purchaseOrderList,
    getCompanyList,
    getAllCompanyList,
    setActiveCompany,
    addLocation,
    editLocation,
    getLocation,
    getLocationById,
    getSubscriptionList,
    getOrderDetails,
    printSalesOrder,
    addCompany,
    editCompany,
    getAdminList,
    getStateCodeByname,
    getStateByCidAddress,
    getCustomerContact,
    addCustomerContact,
    editCustomerContact,
    getLocationByCompanyType,
    getLocationAdminComp,
    getDashboardCount,
    createCatalog,
    getCatalog,
    editCatalog,
    printInvoice,
    getDispatch,
    createDispatch,
    editDispatch,
    getpaymentListAdmin,
    getCompanyDetails,
    getNeighbourhoodType,
    addNeighbourhood,
    getNeighbourhood,
    editNeighbourhood,
    addLocationImg,
    editLocationImg,
    getLocationImg,
    createSuperCategory,
    editSuperCategory,
    getApplication,
    getCountry,
    getState,
    getCity,
    getAllCities,
    addCity,
    updateCity,
    addDistrict,
    updateDistrict,
    getDistrict,
    getDistrictByState,
    getStates,
    addMailConfiguration,
    getMailConfigurations,
    editMailConfigurations,
    getRoles,
    createRole,
    updateRole,
    getPermissions,
    createAccessMasterUser,
    updateAccessMasterUser,
    getAccessMasterUser,
    createLineItems,
    DeactivateLineItems,
    getLineItems,
    getLineItemQR,
    getBatch,
    createBatch,
    updateBatch,
    deactivateBatch,
    createSubscriptions,
    updateSubscriptions,
    getSubscriptions,
    getUnenrolledSubscriptions,
    addStudent,
    bulkAddStudent,
    bulkEnrollStudent,
    getStudent,
    getUnenrolledStudent,
    getActiveExamStudent,
    getUpcomingExamStudent,
    getVerifiedStudents,
    editStudent,
    addImage,
    addPrimaryStudentImage,
    addPrimaryStudentFingerprint,
    addStudentFingerprint,
    getPrimaryStudentImage,
    getPrimaryStudentFingerprint,
    promoteBulkSubscriptions,
    updateBulkSubscriptions,
    getVerifiedImage,
    verifyPrimaryStudentFingerprint,
    getVerificationType,
    getVerificationTypeByCategory,
    getExamReport,
    examReport,
    //-----------------------------------------------Outside dcrm-----------------------------------------------------------
    createEInvoice
}

export default configServ;