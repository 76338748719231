import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const DownloadFirstStageReport = async (reportData, verification_type, reportName) => {
    if (verification_type === 1) {
        // Define the header row for the Excel file
        const headers = [
            'Registration No',
            'Roll No',
            'Full Name',
            'Exam Code',
            'Exam Name',
            'Center Code',
            'Center Name',
            'Is Present',
            'Primary Image',
            'Primary Image Id',
            'Secondary Image',
            'Secondary Image Id',
            'Secondary Image Verified',
            'Secondary Image Remark',
            'Primary Fingerprint',
            'Primary Fingerprint Id',
            'Primary Fingerprint Remark',
            'Sync At'
        ];

        // Map reportData to an array of rows
        const data = reportData.map(item => [
            item.registration_no,
            item.roll_no,
            item.full_name,
            item.exam_code,
            item.exam_name,
            item.center_code,
            item.center_name,
            item.is_present,
            item.primary_image,
            item.primary_image_path,
            item.secondary_image,
            item.secondary_image_path,
            item.secondary_image_verified,
            item.secondary_image_remark,
            item.primary_fingerprint,
            item.primary_fingerprint_path,
            item.primary_fingerprint_remark,
            item.sync_at
        ]);

        // Create a new workbook
        const wb = XLSX.utils.book_new();
        // Add a new worksheet to the workbook
        const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, reportName);

        // Convert the workbook to a binary string
        const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });

        // Create a Blob from the binary string
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

        // Save the Blob as a file
        saveAs(blob, reportName+'.xlsx');
    }
    else if (verification_type === 2) {
        // Define the header row for the Excel file
        const headers = [
            'Registration No',
            'Roll No',
            'Full Name',
            'Exam Code',
            'Exam Name',
            'Center Code',
            'Center Name',
            'Is Present',
            'Primary Image',
            'Primary Image Id',
            'Secondary Image',
            'Secondary Image Id',
            'Secondary Image Verified',
            'Secondary Image Remark',
            'Primary Iris',
            'Primary Iris Id',
            'Primary Iris Remark',
            'Sync At'
        ];

        // Map reportData to an array of rows
        const data = reportData.map(item => [
            item.registration_no,
            item.roll_no,
            item.full_name,
            item.exam_code,
            item.exam_name,
            item.center_code,
            item.center_name,
            item.is_present,
            item.primary_image,
            item.primary_image_path,
            item.secondary_image,
            item.secondary_image_path,
            item.secondary_image_verified,
            item.secondary_image_remark,
            item.primary_iris,
            item.primary_iris_path,
            item.primary_iris_remark,
            item.sync_at
        ]);

        // Create a new workbook
        const wb = XLSX.utils.book_new();
        // Add a new worksheet to the workbook
        const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Report');

        // Convert the workbook to a binary string
        const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });

        // Create a Blob from the binary string
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

        // Save the Blob as a file
        saveAs(blob, 'report.xlsx');
    }
};

// Convert a string to ArrayBuffer
const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
};

export default DownloadFirstStageReport;
