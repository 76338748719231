import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import ImageUpload from "../../Components/Card/ImageUpload/ImageUpload";
import Biometric from "../../Components/Card/Biometric/Biometric";
import VerifiedStudentsTable from "../../Components/Card/Customers/VerifiedStudentsTable";
import VerifiedStudentCard from "./VerifiedStudentCard";
import Iris from "../../Components/Card/Iris/Iris";

const VerifiedStudents = () => {
    //#region code
    const { user_id, company_id, permissions } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const [openDialog, setOpenDialog] = React.useState(false);
    const [isChanged, setIsChanged] = useState(false)
    const [editData, setEditData] = useState({})
    const [openImageHandler, setOpenImageHandler] = useState(false);
    const [openBiometricHandler, setOpenBiometricHandler] = useState(false);
    const [openIrisHandler, setOpenIrisHandler] = useState(false);
    const [imageData, setImageData] = useState(null)
    const [biometricData, setBiometricData] = useState(null)
    const [irisData, setIrisData] = useState(null)
    const [exam_id, setExam_id] = useState(null);
    const [from, setFrom] = useState('')
    const handleClickOpen = () => {
        setOpenDialog(!openDialog);
    };
    const handleClose = () => {
        setFrom("")
        setOpenDialog(false);
    };

    const handleEdit = (data) => {
        setEditData(data);
        setOpenDialog(true);
    }
    const handleImage = (data, from) => {
        setFrom(from)
        setImageData(data)
        setOpenImageHandler(true)
    }
    const handleBiometric = (data, from) => {
        setFrom(from)
        setBiometricData(data)
        setOpenBiometricHandler(true)
    }

    const handleIris = (data, verified, examId) => {

        setIrisData(data)
        setOpenIrisHandler(true)

    }

    const handleBiometricClose = () => {
        setFrom("")
        setOpenBiometricHandler(false);
    }

    const handleIrisClose = () => {
        setOpenIrisHandler(false)
    }
    const onClose = () => {
        setFrom("")
        setOpenImageHandler(false)
    }
    //#endregion

    return (
        <>
            <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'Verified Candidates'} handlePopup={handleClickOpen} create={openDialog} hideAddButton={true} />

            <VerifiedStudentCard handleClose={handleClose} open={openDialog} isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} />
            <VerifiedStudentsTable handleEdit={handleEdit} handleImage={handleImage} handleBiometric={handleBiometric} handleIris={handleIris} isChanged={isChanged} examId={setExam_id} />
            <ImageUpload open={openImageHandler} imageData={imageData} onClose={onClose} from={from} exam_id={exam_id} />
            <Biometric open={openBiometricHandler} biometricData={biometricData} onClose={handleBiometricClose} match={false} />
            <Iris open={openIrisHandler} irisData={irisData} onClose={handleIrisClose} />
        </>
    )
}

export default VerifiedStudents;