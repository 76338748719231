import React, { useEffect, useState } from 'react';
import DashboardCard from '../../Components/Card/DashboardCard';
import { useSelector } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';
import configServ from '../../services/config';
// icons
import PeopleIcon from '@mui/icons-material/People';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import { useNavigate } from 'react-router-dom'

function Dashboard() {
    //#region code
    const { user_id, company_id, is_super } = useSelector((state) => state.GlobalVariables);
    const navigate = useNavigate();
    const open = useSelector((state) => state.sideExpand.open)

    const [count, setCount] = useState({});

    const fetchDashboardCount = async () => {
        try {
            const userId = user_id;
            if (userId === null) {
                navigate('/');
            }

            const res = await configServ.getDashboardCount({ "user_id": user_id, "company_id": company_id, "is_super": is_super });
            if (res.status === 200) {
                setCount(res);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchDashboardCount()
    }, [company_id]);

    const cardData = [
        {
            title: 'UPCOMING EXAMS',
            number: count.upcomingExams,
            icon: <AutoStoriesIcon sx={{ color: '#fb6340' }} />
        },
        {
            title: 'STUDENTS REGISTERED',
            number: count.registeredCandidates,
            icon: <PeopleIcon sx={{ color: '#fb6340' }} />
        },
        {
            title: 'EXAMS DONE',
            number: count.examsDone,
            icon: <OfflinePinIcon sx={{ color: '#fb6340' }} />
        },
    ]
    //#endregion

    return (
        <>
            <Box sx={{
                backgroundColor: '#6270e4',
                width: "100%",
                // height:'200vh'
            }}>
                <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    spacing={{
                        xs: 0,
                        sm: 0,
                        md: 4
                    }}
                    gap={{
                        xs: 2,
                        sm: 2,
                        md: 0
                    }}
                    py={5}
                    sx={{
                        flexDirection: {
                            xs: 'column',
                            sm: 'column',
                            md: 'row'
                        },
                    }}
                >
                    {cardData.map((item, index) => {
                        return (
                            <DashboardCard key={index} data={item} />
                        )
                    })}
                </Stack>
            </Box>
        </>
    )
}

export default Dashboard;