import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import configServ from '../../../services/config';
import AlertDialog from '../../AlertDialog/AlertDialog';
import UploadedSuccessfullyComponent from './UploadedSuccesfully';
import CaptureOrUploadFingerprint from './CaptureOrUploadFingerprint';
import ExistingFingerprint from './ExistingFingerprint';

const Biometric = ({ open, biometricData, onClose, from, verified, exam_id, firstStage, match }) => {
    const [fingerprints, setFingerprints] = useState([]);
    const [secondaryFingerPrint, setSecondaryFingerPrint] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [captured, setCaptured] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(false);
    const [existingImage, setExistingImage] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);
    const [capturing, setCapturing] = useState(false);
    const [remark, setRemark] = useState(null);
    const [isTested, setTested] = useState(false);
    const [isMatched, setIsMatched] = useState(false);

    const { user_id } = useSelector((state) => state.GlobalVariables);
    const uri = "https://localhost:8003/mfs100/";

    useEffect(() => {
        const fetchExistingImage = async () => {
            try {
                const primary_fingerprint = biometricData.primary_fingerprint;
                const secondary_fingerprint = biometricData.secondary_fingerprint;
                if (primary_fingerprint !== null) {
                    setExistingImage(`data:image/jpg;base64,${primary_fingerprint.image}`);
                    setFingerprints([
                        {
                            'isoTemplate': primary_fingerprint.iso_template,
                            'remark': primary_fingerprint.remark,
                            'capturedAt': formatDateTime(primary_fingerprint.created_at),
                            'updatedAt': formatDateTime(primary_fingerprint.updated_at)
                        },
                    ]);
                }
                if (secondary_fingerprint !== null) {
                    setSecondaryFingerPrint(secondary_fingerprint);
                }

            }
            catch (error) {
                console.error('Error fetching existing image:', error);
            }
            finally {
                setLoading(false);
            }
        };

        if (open) {
            fetchExistingImage();
        }
    }, [open]);

    const formatDateTime = (dateTime) => {
        if (!dateTime) return 'Not available';
        const date = new Date(dateTime);
        const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true });
        return `${formattedDate} - ${time}`;
    };

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
        handleClose();
    };

    const handleClose = () => {
        setFingerprints([null]);
        setCaptured(false);
        setUploadStatus(false);
        setExistingImage(null);
        setError(null);
        setLoading(true);
        setCapturing(false);
        setTested(false);
        setIsMatched(false);
        setRemark(null);
        onClose();
    };

    const handleCaptureFinger = async () => {
        setCapturing(true);
        try {
            await captureFinger(100, 30);
        } 
        catch (error) {
            setError(error.message);
        } 
        finally {
            setLoading(false);
            setCapturing(false);
        }
    };

    const handleMatchFinger = async () => {
        setCapturing(true);
        try {
            await MatchFinger(100, 30);
        }
        catch (error) {
            setError(error.message);
        }
        finally {
            setLoading(false);
            setCapturing(false);
        }
    };

    const handleRemark = (data) => {
        try {
            if (data === "") {
                setRemark(null);
            }
            else {
                setRemark(data);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleRecaptureFinger = () => {
        setFingerprints([null]);
        setCaptured(false);
        setCapturing(false);
    };

    const captureFinger = async (quality, timeout) => {
        const requestData = {
            Quality: quality,
            TimeOut: timeout
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(requestData),
        };

        const response = await fetch(uri + "capture", requestOptions);
        const data = await response.json();
        if (data.ErrorCode !== "0") {
            handleOpenDialog('Error', data.ErrorDescription, 'error');
            setCaptured(false);
            setCapturing(false);
            return false;
        } else if (data.ErrorCode === "0" && data.Quality < 50) {
            handleOpenDialog('Warning', 'Poor quality. Recapture and Try Again.', 'warning');
            setCaptured(false);
            setCapturing(false);
            return false;
        }
        setFingerprints([data]);
        setCaptured(true);
        setError(null);
    };

    const captureSecondaryFinger = async (quality, timeout) => {
        const requestData = {
            Quality: quality,
            TimeOut: timeout
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(requestData),
        };

        const response = await fetch(uri + "capture", requestOptions);
        const data = await response.json();
        return data;
    };

    const MatchFinger = async (quality, timeout) => {
        
        var MFS100Request = {
            "Quality": quality,
            "TimeOut": timeout,
            "GalleryTemplate": fingerprints[0].isoTemplate,
            "BioType": "FMR"
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(MFS100Request),
        };

        const response = await fetch(uri + "match", requestOptions);
        const data = await response.json();
        
        if (data.Status) {
            setTested(true);
            setIsMatched(true);
            await handleSecondaryFingerPrintUpload(true);
            setLoading(false);
            setCapturing(false);
        }
        else if (data.ErrorCode === "-1321") {
            handleOpenDialog('Warning', "Device already started or already stopped. Try dsconnecting and reconnecting.", 'warning');
            setLoading(false);
            setCapturing(false);
        }
        else {
            setTested(true);
            setIsMatched(false);
            setLoading(false);
        }
    };

    const handleSecondaryFingerPrintUpload = async (is_verified) => {
        try {
            setLoading(true);
            const matchFingerPrint = await captureSecondaryFinger(100, 30);
            setLoading(false);
            //const dateTime = CheckAndReturn.getCurrentDateTimeFormatted();
            const dataToSend = {
                image: `data:image/jpeg;base64,${matchFingerPrint.BitmapData}`,
                type: 'fingerprint',
                student_id: biometricData.id,
                iso_template: matchFingerPrint.IsoTemplate,
                registration_no: biometricData.registration_no,
                exam_id: exam_id,
                status: Boolean(biometricData.status),
                user_id: user_id,
                is_verified: Boolean(is_verified),
                remark: remark,
                created_at: null,
            };

            const result = await configServ.addStudentFingerprint(dataToSend);
            if (result.status === 200) {
                setExistingImage(null);
                setUploadStatus(true);
            }
            else if (result.status === 400) {
                handleOpenDialog('Error', result.message || "Fingerprint already exists.", 'error');
            }
            else {
                handleOpenDialog('Error', "Failed to save fingerprint data.", 'error');
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setLoading(false);
        }
    }

    const handleUnMatchedFingerprintUpload = async () => {
        try {
            await handleSecondaryFingerPrintUpload(isMatched);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleUploadFinger = async () => {
        //const dateTime = CheckAndReturn.getCurrentDateTimeFormatted();
        const dataToSend = {
            image: `data:image/jpeg;base64,${fingerprints[0].BitmapData}`,
            type: 'fingerprint',
            student_id: biometricData.id,
            iso_template: fingerprints[0].IsoTemplate,
            registration_no: biometricData.registration_no,
            exam_id: exam_id,
            status: Boolean(biometricData.status),
            user_id: user_id,
            is_verified: true,
            created_at: null,
        };

        try {
            const result = await configServ.addPrimaryStudentFingerprint(dataToSend);
            if (result.success === 200) {
                setUploadStatus(true);
            }

        }
        catch (error) {
            setError(error.message);
        }
        finally {
            setLoading(false);
            setFingerprints([null]);
            setCaptured(false);
            setError(null);
        }
    };


    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType} />

            <Dialog open={open} onClose={handleClose} style={{ minWidth: '20rem', minHeight: '20rem' }}>
                {loading ? (
                    <Grid container justifyContent="center" alignItems="center" style={{ minWidth: '20rem', minHeight: '20rem' }}>
                        <CircularProgress size={100} />
                    </Grid>
                ) : (
                    existingImage ? (
                        <ExistingFingerprint
                            firstStage={firstStage}
                            existingImage={existingImage}
                            handleMatchFinger={handleMatchFinger}
                            handleClose={handleClose}
                            loading={capturing}
                            verified={verified}
                            primaryFingerPrint={fingerprints[0]}
                            secondaryFingerPrint={secondaryFingerPrint}
                            match={match}
                            isTested={isTested}
                            isMatched={isMatched}
                            handleUnMatchedFingerprintUpload={handleUnMatchedFingerprintUpload}
                            remark={remark}
                            handleRemark={handleRemark}
                        />
                    ) : (
                        !uploadStatus ? (
                            <CaptureOrUploadFingerprint
                                fingerprints={fingerprints}
                                captured={captured}
                                handleUploadFinger={handleUploadFinger}
                                handleRecaptureFinger={handleRecaptureFinger}
                                handleCaptureFinger={handleCaptureFinger}
                                handleClose={handleClose}
                                loading={capturing}
                                error={error}
                                from={from}
                                remark={remark}
                                handleRemark={handleRemark}
                            />
                        ) : (
                            <UploadedSuccessfullyComponent handleClose={handleClose} />
                        )
                    )
                )}
            </Dialog>
        </>
    );

}
export default Biometric;
