import React, { useState, useEffect } from 'react'
import Header from '../../Components/header/Header/Header'
import Footer from '../../Components/Footer/Footer'
import MiniDrawer from '../../Components/sidebar/SideBar'
import { Box, Paper, Typography, Card, Stack, Divider, TextField, FormControl, InputLabel, Select, MenuItem, Button, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import http from '../../services/http'
import StateList from '../../Components/List/StateList'
import ProfilePicture from '../../Components/Assets/images/profilePicture.jpg'
import configServ from '../../services/config'
import { SessionVerificationAdmin } from '../../Components/SessionManagement/SessionManagementAdmin'
import SaveIcon from '@mui/icons-material/Save';

//FUNCTION
function MyAccount() {
    //#region code
    const { user_id } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const [formData, setFormData] = useState({})

    const changeHandler = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }));
    }

    const submitHandler = (e) => {
        console.log(formData)
    }

    const fetchUser = async () => {
        const id = {
            "user_id": user_id
        }
        try {
            const result = await configServ.getUserDetail(id);
            setFormData(result)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchUser()
    }, []);
    //#endregion

    return (
        <>
            <Box sx={{
                // marginLeft: {
                //     xs: 7,
                //     sm: 8,
                // },
                // ...(open && { marginLeft: 30 }),
                transition: '200ms',
                overflowX: 'hidden',
                minHeight: '77vh',
            }}>

                <Stack
                    direction={{
                        md: 'row',
                        sm: 'column-reverse',
                        xs: 'column-reverse'
                    }}
                    display={'flex'}
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        p: 5,
                        pt: 0,
                    }}
                    spacing={2}
                >
                    <Paper
                        elevation={1}
                        sx={{
                            width: {
                                md: '80%'
                            },
                            padding: 4,
                            backgroundColor: '#f5f2fa'
                        }}
                    >
                        <Typography color='#1e61b4' variant='h5' display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            ACCOUNT INFORMATION
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                        <Stack
                            direction='column'
                            spacing={2}
                            sx={{
                                alignItems: 'left',
                            }}
                        >
                            <Typography variant='body' fontSize='small' color='text.secondary'>USER INFORMATION</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label='Username'
                                        name='name'
                                        value={formData.user_name || ''}
                                        onChange={changeHandler}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ backgroundColor: "white" }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label='Email Address'
                                        type='email'
                                        name='email'
                                        value={formData.email || ''}
                                        onChange={changeHandler}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ backgroundColor: "white" }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label='Name'
                                        name='name'
                                        value={formData.name || ''}
                                        onChange={changeHandler}
                                        sx={{ backgroundColor: "white" }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label='Mobile'
                                        type='number'
                                        name='mobile'
                                        value={formData.mobile || ''}
                                        onChange={changeHandler}
                                        sx={{ backgroundColor: "white" }}
                                    />
                                </Grid>
                            </Grid>

                            <Divider />
                            <Typography variant='body' fontSize='small' color='text.secondary'>ADDRESS INFORMATION</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label='Address'
                                        name='address'
                                        value={formData.address || ''}
                                        onChange={changeHandler}
                                        sx={{ backgroundColor: "white" }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel>State</InputLabel>
                                        <Select
                                            name='state'
                                            label="Select"
                                            value={formData.state || ''}
                                            onChange={changeHandler}
                                            sx={{ backgroundColor: "white" }}
                                        >
                                            {StateList.map((item, index) => {
                                                return <MenuItem key={index} value={item}>{item}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label='Pin'
                                        type='number'
                                        name='pincode'
                                        value={formData.pincode || ''}
                                        onChange={changeHandler}
                                        sx={{ backgroundColor: "white" }}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    sx={{ width: '100px' }}
                                    color='primary'
                                    variant='contained'
                                    onClick={submitHandler}
                                    startIcon={<SaveIcon />}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Stack>
                    </Paper>

                    {/* <Paper
                        sx={{
                            width: {
                                md: '35%'
                            },
                            padding: 4
                        }}
                    >
                        <Stack
                            direction='column'
                            spacing={2}
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <img
                                src={formData.image || ProfilePicture}
                                alt='Profile'
                                width='50%'
                                style={{
                                    borderRadius: 150
                                }}
                            />
                            <input
                                type='file'
                                style={{
                                    border: '1px solid #33333355',
                                    width: '80%'
                                }}
                            />
                            <Button
                                variant='contained'
                                sx={{
                                    width: {
                                        xs: '60%',
                                        sm: '40%',
                                        md: '30%'
                                    }
                                }}
                            >
                                Upload
                            </Button>
                        </Stack>
                    </Paper> */}

                </Stack>

            </Box>
            {/* <Footer /> */}

        </>
    )
}

export default MyAccount;