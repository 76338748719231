import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import SiteLogo from '../../Assets/images/DovetailLogo.svg'
import LogoInitial from '../../Assets/images/SiteLogoInitial.png'
import { NavLink } from 'react-router-dom';
import { Stack, Button } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { userType } from '../../../redux/reducers/userData';

function OuterHeader() {
    const dispatch = useDispatch()

    const isUserAdminAssign = (data) => {
        dispatch(userType(data))
    }

    return (
        <Box sx={{ display: 'flex', mb: 10 }}>
            <CssBaseline />
            <AppBar component="nav" elevation={1} sx={{
                backgroundColor: 'white'
            }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Box sx={{
                        width: '12%'
                    }}>
                        <Box component={'img'} src={SiteLogo} alt='Dovetail' width={{ xs: '10rem' }} display={{ xs: 'none', sm: 'block' }} />
                        <Box component={'img'} src={LogoInitial} alt='Dovetail' width={{ xs: '4rem' }} display={{ xs: 'block', sm: 'none' }} />
                    </Box>
                    <Stack direction='row' spacing={1}>
                        <NavLink to='/login' ><Button variant='text'
                            sx={{
                                color: '#333'
                            }}

                            onClick={() => { isUserAdminAssign(false) }}
                        >
                            Login
                        </Button></NavLink>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Box component="nav">
            </Box>
        </Box>
    );
}

export default OuterHeader;