import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import configServ from '../../services/config';
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import { motion } from "framer-motion";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    // DialogContentText,
    DialogActions,
    TextField,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    Checkbox,
    IconButton,
    Typography,
    Stack,
    Tooltip,
    Grid,
    Divider
} from '@mui/material'
import { Container, Row, Col, Form, Button as ButtonB, FormCheck } from 'react-bootstrap';
// import http from '../../services/http';
import axios from 'axios';
import { useSelector } from 'react-redux';

//FUNCTION
function SupplierCard({ isChanged, setIsChanged, editData, setEditData, role }) {
    //#region code
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const [formData, setFormData] = useState({
        openingamount: 0
    });
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [isDone, setIsDone] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }));
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }));
    }

    const assignContactName = () => {
        if (!isDone) {
            setFormData((state) => ({
                ...state,
                contact_name: formData.fname || ''
            }));
        }
    }

    const handleSubmit = async (e) => {
        const dataToSend = {
            ...formData,
            company_id: company_id,
            user_id: user_id
        }
        if (formData.is_credit_days === false) {
            delete dataToSend.credit_period
            delete dataToSend.credit_limit
        }
        try {
            if (!isEdit) {
                const result = await configServ.addSupplier(dataToSend);
                setIsChanged(!isChanged)
                clear();
                handleOpenDialog(result.message)
            } else {
                const result = await configServ.editSupplier(dataToSend);
                setIsChanged(!isChanged)
                clear()
                handleOpenDialog(result.message);
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const fetchCounties = async () => {
        const res = await axios.get('https://countriesnow.space/api/v0.1/countries/capital')
        if (!res.error) {
            setCountryList(res.data.data);
        }
    }

    const fetchStates = async () => {
        const res = await axios.post('https://countriesnow.space/api/v0.1/countries/states', { "country": `${formData.country}` })
        if (!res.error) {
            setStateList(res.data.data.states);
        }
    }

    useEffect(() => {
        fetchCounties()
    }, []);

    useEffect(() => {
        if (formData.country !== undefined) {
            fetchStates()
        }
    }, [formData]);

    useEffect(() => {
        if (Object.keys(editData).length !== 0) {
            setFormData(editData)
            setIsEdit(true);
        }
    }, [editData]);

    const clear = () => {
        setFormData({})
        setEditData({})
        setIsDone(false)
        setIsEdit(false)
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />

            <motion.div
                style={{
                    width: "100%",
                    backgroundColor: "#e3f3fc",
                    marginTop: 20,
                    padding: 20,
                    borderRadius: "10px",
                    border: "none",
                }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1, delay: 0.1 }}
            >
                <Divider sx={{my:1}}><b>{role === "" || role === null? "":role.toUpperCase() } DETAIL</b></Divider>
                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    spacing={1}
                >
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Supplier Name'
                            name='fname'
                            onChange={handleChange}
                            value={formData.fname || ''}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Contact Name'
                            name='contact_name'
                            onChange={handleChange}
                            onClick={() => { assignContactName(); setIsDone(true) }}
                            value={formData.contact_name || ''}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth size='small'>
                            <InputLabel>Country</InputLabel>
                            <Select
                                required
                                name='country'
                                label='select'
                                value={formData.country || ''}
                                onChange={handleChange}
                                sx={{ backgroundColor: 'white' }}
                            >
                                {countryList.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth size='small'>
                            <InputLabel>State</InputLabel>
                            <Select
                                required
                                name='state'
                                label='select'
                                value={formData.state || ''}
                                onChange={handleChange}
                                sx={{ backgroundColor: 'white' }}
                            >
                                {stateList.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Email'
                            name='email'
                            onChange={handleChange}
                            value={formData.email || ''}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Mobile Number'
                            name='phone'
                            type='number'
                            onChange={handleChange}
                            value={formData.phone || ''}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Mailing Address'
                            name='address'
                            onChange={handleChange}
                            value={formData.address || ''}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            type='number'
                            size='small'
                            label='Opening Amount'
                            name='openingamount'
                            onChange={handleChange}
                            value={formData.openingamount || ''}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    {/* <Col sm={4}>
                            <FormControl
                                fullWidth
                                size='small'
                            >
                                <InputLabel id="customer-type">Customer Type</InputLabel>
                                <Select
                                    labelId="customer-type"
                                    label="Customer Type"
                                    name='type'
                                    value={formData.type || ''}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'Standard'}>Standard </MenuItem>
                                    <MenuItem value={'Previleged'}>Previleged</MenuItem>
                                    <MenuItem value={'Staff'}>Staff</MenuItem>
                                    <MenuItem value={'Discount'}>Discount </MenuItem>
                                </Select>
                            </FormControl>
                        </Col> */}

                    <Grid item xs={12} sm={6} md={4}>
                        {/* <Col sm={4}>
                            <TextField
                                fullWidth
                                type='number'
                                size='small'
                                label='Pin'
                                name='Pin'
                                onChange={handleChange}
                                value={formData.Pin || ''}
                            />
                        </Col> */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Form.Label>Tax Detail</Form.Label>
                        <TextField
                            fullWidth
                            size='small'
                            label='PAN/NO'
                            name='pan_no'
                            onChange={handleChange}
                            value={formData.pan_no || ''}
                            sx={{ backgroundColor: 'white' }}
                        /><br /><br />
                        <TextField
                            fullWidth
                            size='small'
                            label='GSTIN/UIN'
                            name='gst_uin'
                            onChange={handleChange}
                            value={formData.gst_uin || ''}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <div style={{ border: "solid lightgrey 1px", width: "60%", borderRadius: 5, padding: 10, paddingTop: 20, margin: 10, marginTop: 20 }}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <FormControlLabel control={<Checkbox />} name='is_credit_days' checked={Boolean(formData.is_credit_days)} onChange={handleChangeCheckbox} label='Check credit days' /><br />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    label='Credit Period'
                                    size='small'
                                    name='credit_period'
                                    InputProps={{
                                        endAdornment: 'd'
                                    }}
                                    disabled={formData.is_credit_days === true ? false : true}
                                    onChange={handleChange}
                                    value={formData.credit_period || ''}
                                    sx={{ backgroundColor: 'white' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    label='Credit Limmit'
                                    size='small'
                                    name='credit_limit'
                                    disabled={formData.is_credit_days === true ? false : true}
                                    onChange={handleChange}
                                    value={formData.credit_limit || ''}
                                    sx={{ backgroundColor: 'white' }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container>
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel control={<Checkbox />} name='status' checked={Boolean(formData.status)} onChange={handleChangeCheckbox} label='Status' />
                        </Grid>

                        <Grid container spacing={1} item xs={12} sm={8} justifyContent="flex-end">
                            <Grid item>
                                <Button variant='contained' onClick={clear} color='secondary' startIcon={<RotateLeftIcon />}>
                                    Reset
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleSubmit} variant='contained' startIcon={isEdit?<UpdateIcon/>:<SaveIcon/>} color={isEdit?'success':'primary'}>
                                    {isEdit ? 'Update' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </motion.div>
        </>
    )
}

export default SupplierCard;