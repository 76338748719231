import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import configServ from '../../../services/config';
import ExamReportComp from '../../../Components/Report/ExamReportComp';

//FUNCTION EXAM REPORT
function ExamReport() {
    //#region code
    const { user_id } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open);
    const [formData, setFormData] = useState({});
    const [tabValue, setTabValue] = useState(0);

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setFormData((state) => ({
            ...state,
            [name]: value,
        }));
    };

    const submitHandler = (e) => {
        console.log(formData);
    };

    const fetchUser = async () => {
        const id = {
            user_id: user_id,
        };
        try {
            const result = await configServ.getUserDetail(id);
            setFormData(result);
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    //#endregion

    return (
        <>
            <ExamReportComp />
        </>
    );
}

export default ExamReport;
