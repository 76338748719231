import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import SiteLogo from '../../Assets/images/DovetailCenterLogo_removebg_preview.png';
import LogoInitial from '../../Assets/images/SiteLogoInitial.png';
import { NavLink, useNavigate } from 'react-router-dom';
import { Stack, Button, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import { changeLightMode } from '../../../redux/reducers/GlobalVariables';

function OuterHeader1() {
  const dispatch = useDispatch();
  const lightMode = useSelector(state => state.GlobalVariables.lightMode);
  const navigate = useNavigate();
  // const isUserAdminAssign = (data) => {
  //   dispatch(userType(data));
  // };

  return (
    <Box sx={{ display: 'flex', mb: 10 }}>
      <CssBaseline />
      <AppBar component="nav" elevation={0} sx={{
        backgroundColor: 'transparent',//lightMode ? '#ffffff' : '#090e34',
        p: '10px'
      }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ width: '12%' }}>
            <Box
              component={'img'}
              src={SiteLogo}
              sx={{ filter: lightMode ? 'invert(0)' : 'invert(1)' }}
              alt="Dovetail"
              width={{ xs: '10rem' }}
              display={{ xs: 'none', sm: 'block' }}
            />
            <Box
              component={'img'}
              src={LogoInitial}
              sx={{ filter: lightMode ? 'invert(0)' : 'invert(1)' }}
              alt="Dovetail"
              width={{ xs: '4rem' }}
              display={{ xs: 'block', sm: 'none' }}
            />
          </Box>
          <Stack direction="row" spacing={4}>
            <NavLink to="/">
              <Button
                variant="text"
                sx={{
                  color: lightMode ? '#333' : '#ffffff',
                  '&:hover': {
                    color: '#959cb1',
                  },
                }}
              // onClick={() => {
              //   isUserAdminAssign(false);
              // }}
              >
                Home
              </Button>
            </NavLink>
            {/* <NavLink>
              <Button
                variant="text"
                sx={{ color: lightMode?'#333':'#ffffff', 
                '&:hover': {
                    color: '#959cb1',
                  },
                }}
              >
                About
              </Button>
            </NavLink>
            <NavLink>
              <Button
                variant="text"
                sx={{ color: lightMode?'#333':'#ffffff', 
                '&:hover': {
                    color: '#959cb1',
                  },
                }}
              >
                Support
              </Button>
            </NavLink> */}
            <NavLink to="/admin-login">
              <Button
                variant="text"
                sx={{
                  color: lightMode ? '#333' : '#ffffff',
                  '&:hover': {
                    color: '#959cb1',
                  },
                }}
                onClick={() => {
                  navigate('/admin-login');
                }}
              >
                Login
              </Button>
            </NavLink>
            <NavLink >
              <Button
                variant="text"
                sx={{
                  color: lightMode ? '#333' : '#ffffff',
                  '&:hover': {
                    color: '#959cb1',
                  },
                }}
                onClick={() => {
                  dispatch(changeLightMode());
                }}
              >
                {lightMode ? <Tooltip title='Dark Mode'>
                  <DarkModeOutlinedIcon />
                </Tooltip> :
                  <Tooltip title='Light Mode'>
                    <LightModeOutlinedIcon />
                  </Tooltip>
                }
              </Button>
            </NavLink>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box component="nav"></Box>
    </Box>
  );
}

export default OuterHeader1;
