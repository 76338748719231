import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    lightMode: false,
    noRoomService: '',
    id: null,
    name: null,
    address: null,
    company_id: null,
    companyName: null,
    companyCode: null,
    user_id: null,
    user_name: null,
    email: null,
    currency: null,
    is_super: null,
    //token: null,
    encoded_data: null,
    permissions: [],
}

export const GlobalVariableSlice = createSlice({
    name: 'GlobalVariables',
    initialState,
    reducers: {
        changeLightMode: (state) => {
            state.lightMode = !state.lightMode;
        },
        changeNoRoomService: (state, action) => {
            state.noRoomService = action.payload;
        },
        rSetId: (state, action) => {
            state.id = action.payload;
        },
        rSetName: (state, action) => {
            state.name = action.payload;
        },
        rSetAddress: (state, action) => {
            state.address = action.payload;
        },
        rSetCompanyId: (state, action) => {
            state.company_id = action.payload;
        },
        rSetCompanyName: (state, action) => {
            state.companyName = action.payload;
        },
        rSetCompanyCode: (state, action) => {
            state.companyCode = action.payload;
        },
        rSetUserId: (state, action) => {
            state.user_id = action.payload;
        },
        rSetUserName: (state, action) => {
            state.user_name = action.payload;
        },
        rSetEmail: (state, action) => {
            state.email = action.payload;
        },
        rSetCurrency: (state, action) => {
            state.currency = action.payload;
        },
        rSetIsSuper: (state, action) => {
            state.is_super = action.payload;
        },
        // rSetToken:(state,action)=>{
        //     state.token = action.payload;
        // },
        rSetEncodedData: (state, action) => {
            state.encoded_data = action.payload;
        },
        rSetUserPermissions: (state, action) => {
            state.permissions = action.payload;
        },
    }
});

export const { 
    changeLightMode, 
    changeNoRoomService, 
    rSetId, 
    rSetName, 
    rSetAddress, 
    rSetCompanyId, 
    rSetCompanyName,
    rSetCompanyCode,
    rSetUserId, 
    rSetUserName,
    rSetEmail,
    rSetCurrency, 
    rSetIsSuper, 
    // rSetToken, 
    rSetEncodedData,
    rSetUserPermissions
 } = GlobalVariableSlice.actions;

 export default GlobalVariableSlice.reducer;
