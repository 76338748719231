import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  CircularProgress,
  InputAdornment,
  TextField,
  Grid,
  FormControl,
  NativeSelect,
  InputLabel,
  Typography,
  IconButton,
  Select,
  MenuItem
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from 'react';
import configServ from '../../../services/config';
import { useSelector } from 'react-redux';
import ImageUpload from '../ImageUpload/ImageUpload';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CheckAndReturn from '../../../utility/CheckAndReturn';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';

//FUNCTION
const VerifiedStudentsTable = ({ handleEdit, handleImage, handleBiometric,handleIris, isChanged, examId }) => {
  //#region code
  const { user_id, company_id, is_super, permissions } = useSelector((state) => state.GlobalVariables);
  const rowsPerPageOptions = [25, 100, 200, 500];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [customerList, setCustomerList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [exam_id, setExam_id] = useState(null);
  const [searchBy, setSearchBy] = useState('full_name');
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cannotAdd, setCannotAdd] = useState(true);
  const [cannotUpdate, setCannotUpdate] = useState(true);
  const [isFirstStage, setIsFirstSatge] = useState(false);
  const [verificationType, setVerificationType] = useState(0);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const checkPermissions = () => {
    try {
      const isAdd = CheckAndReturn.PermissionHandler('create-student', permissions);
      setCannotAdd(!isAdd);
      const isUpdate = CheckAndReturn.PermissionHandler('update-student', permissions);
      setCannotUpdate(!isUpdate);
    }
    catch (e) {
      console.log(e);
    }
  }

  const fetchExams = async () => {
    try {
      const response = await configServ.getItemList();
      if (response.status === 200) {
        setExamList(response.data);
      }
    }
    catch (error) {
      console.error("Error fetching exams:", error);
    }
  };

  useEffect(() => {
    checkPermissions();
    fetchExams();
  }, []);

  useEffect(() => {
    if (exam_id !== null) {
      fetchCustomerList();
    }
  }, [isChanged, page, rowsPerPage, search, exam_id]);

  const fetchCustomerList = async () => {
    try {
      const dataToSend = {
        "user_id": user_id,
        "is_super": is_super,
        "company_id": company_id,
        "exam_id": exam_id,
        "search_by": searchBy,
        "search": search,
        "per_page": rowsPerPage,
        "page": page
      }
      setLoading(true);
      const res = await configServ.getVerifiedStudents(dataToSend);
      if (res.status === 200) {
        setCustomerList(res.data);
        setCount(res.total);
      }
      else if (res.status === 400) {
        setCustomerList([]);
        setCount(0);
      }
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  const handleExam = (params) => {
    try {
      const data = parseInt(params.target.value)
      if (data > 0) {
        setExam_id(data);

        const selectedExam = examList.find(x => x.id === data);
        setIsFirstSatge(selectedExam.isFirstStage === 0 ? false : true);
        setVerificationType(selectedExam.verification_type);

        examId(data);
      }
      else {
        setExam_id(null);
        setIsFirstSatge(false);
        setVerificationType(0);
        examId(null);
      }
      setPage(0);
      setSearch('');
    }
    catch (e) {
      console.log(e);
    }
  }

  const headers = ['Registration ID', 'Roll No', 'Full Name', 'Photo', 'Biometric', 'Action'];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: 'bold',
    backgroundColor: 'black',
    color: 'white',
    padding: 5,
  };

  const evenRowStyle = {
    background: '#f5f5f5',
  };

  const oddRowStyle = {
    background: 'white',
  };

  const rowStyle = {
    '&:hover': {
      background: '#f1f9fe',
    },
  };

  const cellStyle = {
    py: 0,
    my: 0
  }

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleSearch = (event) => {
    try {
      const data = event.target.value;
      if (data === "") {
        setSearch(null);
      }
      else {
        setSearch(data);
      }
    }
    catch (error) {
      console.log(error);
    }
  }
  //#endregion

  return (
    <>
      <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ paddingBottom: 10, paddingTop: 0 }} spacing={1} direction={'row'}>
        <Grid item xs={12} md={12} justifyContent={'flex-start'}>
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Verified Candidate List:{count}</Typography>
        </Grid>
        <Grid item xs={12} md={6} justifyContent={'flex-start'}>
          <Box sx={{ maxWidth: 220 }}>
            <FormControl
              fullWidth
            >
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Examination
              </InputLabel>
              <NativeSelect
                defaultValue={0}
                onChange={handleExam}
                inputProps={{
                  name: 'Examination',
                  id: 'uncontrolled-native',
                }}
              >
                <option key={0} value={0}>Select Exam</option>
                {examList.map((exam) => (
                  <option key={exam.id} value={exam.id}>{exam.item_name}</option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ maxWidth: 220 }}>
            <FormControl
              fullWidth
            >
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Search By
              </InputLabel>
              <NativeSelect
                defaultValue={'full_name'}
                onChange={handleSearchBy}
                inputProps={{
                  name: 'searchBy',
                  id: 'uncontrolled-native',
                }}
              >
                <option value={'full_name'}>Student Name</option>
                <option value={'registration_no'}>Registration No</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} mr={{ xs: 0, md: 0 }} justifyContent={'flex-end'}>
          <TextField
            id="outlined-basic"
            size="small"
            fullWidth
            placeholder="search"
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: 'grey' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper} elevation={0}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={headerCellStyle}>Sr. No.</TableCell>
              {headers.map((header, index) => (
                <TableCell key={index} style={headerCellStyle}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading === false && <TableBody>
            {
              (exam_id !== null) ?
                (customerList.length > 0 ?
                  customerList.map((row, rowIndex) => (
                    <TableRow key={rowIndex}
                      sx={{
                        ...rowStyle,
                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                      }}
                    >
                      <TableCell sx={cellStyle}>{page * rowsPerPage + rowIndex + 1}</TableCell>
                      <TableCell
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {row.registration_no}
                      </TableCell>
                      <TableCell sx={cellStyle}>
                        {row.subscriptions.roll_no}
                      </TableCell>
                      <TableCell sx={cellStyle}>{row.full_name}</TableCell>
                      <TableCell sx={cellStyle}>
                        {row.primary_image !== null ? (
                          <>
                            <img
                              src={`data:image/jpg;base64,${row.primary_image.image}`}
                              alt=""
                              style={{ width: "50px", height: "50px", borderRadius: "2px" }}
                            />
                            {row.secondary_image !== null ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
                          </>
                        ) : (
                          <>
                            <PersonIcon />
                            {row.secondary_image !== null ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
                          </>
                        )}
                      </TableCell>
                      {
                        verificationType === 1 &&
                        <TableCell sx={cellStyle}>
                          {row.primary_fingerprint !== null ? (
                            <>
                              <img
                                src={`data:image/jpg;base64,${row.primary_fingerprint.image}`}
                                alt=""
                                style={{ width: "50px", height: "50px", borderRadius: "2px" }}
                              />
                              {row.secondary_fingerprint !== null ? <CheckCircleIcon color='success' /> : (isFirstStage === false ? <CancelIcon color='error' /> : '')}
                            </>
                          ) : (
                            <>
                              <FingerprintIcon color='primary' />
                              {row.secondary_fingerprint !== null ? <CheckCircleIcon color='success' /> : (isFirstStage === false ? <CancelIcon color='error' /> : '')}
                            </>
                          )}
                        </TableCell>
                      }
                      {
                        verificationType === 2 &&
                        <TableCell sx={cellStyle}>
                          {row.primary_iris !== null ? (
                            <>
                              <img
                                src={`data:image/jpg;base64,${row.primary_iris.image}`}
                                alt=""
                                style={{ width: "50px", height: "50px", borderRadius: "2px" }}
                              />
                              {row.secondary_iris !== null ? <CheckCircleIcon color='success' /> : (isFirstStage === false ? <CancelIcon color='error' /> : '')}
                            </>
                          ) : (
                            <>
                              <FingerprintIcon color='primary' />
                              {row.secondary_iris !== null ? <CheckCircleIcon color='success' /> : (isFirstStage === false ? <CancelIcon color='error' /> : '')}
                            </>
                          )}
                        </TableCell>
                      }
                      <TableCell sx={cellStyle}>
                        <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0, }}>
                          {
                            cannotUpdate === false &&
                            <IconButton title='Edit' onClick={() => { handleEdit(row); scrollToTop() }} arrow>
                              <EditIcon color='primary' />
                            </IconButton>
                          }
                          <IconButton title='Camera' onClick={() => { handleImage(row, "fromVerified"); scrollToTop() }} arrow>
                            <CameraAltIcon color='primary' />
                          </IconButton>
                          {
                            verificationType === 0 && <>
                              <IconButton title='Fingerprint' arrow>
                                <FingerprintIcon color='primary' />
                              </IconButton>
                              <IconButton title='Iris' arrow>
                                <VisibilityIcon color='primary' />
                              </IconButton>
                            </>
                          }
                          {
                            verificationType === 1 && <>
                              <IconButton title='Biometric' onClick={() => { handleBiometric(row, "fromVerified"); scrollToTop() }} arrow>
                                <FingerprintIcon color='primary' />
                              </IconButton>
                            </>
                          }
                          {
                            verificationType === 2 && <>
                             <IconButton title='Iris' onClick={()=>{ handleIris(row, row.secondary_iris === null ? false : true, exam_id); scrollToTop() }} arrow>
                                <VisibilityIcon color='primary' />
                              </IconButton>
                            </>
                          }
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                  ) :
                  <TableRow>
                    <TableCell colSpan={12} sx={{ textAlign: 'center', color: 'red' }}>
                      <div>
                        <p><b>No Candidates Found.</b></p>
                      </div>

                    </TableCell>
                  </TableRow>
                ) :
                (
                  <TableRow>
                    <TableCell colSpan={12} sx={{ textAlign: 'center', color: 'red' }}>
                      <div>
                        <p><b>Please select an examination.</b></p>
                      </div>
                    </TableCell>
                  </TableRow>
                )
            }
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={headers.length + 2} />
              </TableRow>
            )} */}
          </TableBody>}
          {loading === true &&
            <TableRow>
              <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                <div>
                  <CircularProgress />
                </div>

              </TableCell>
            </TableRow>
          }
        </Table>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
  // }
};

export default VerifiedStudentsTable;
