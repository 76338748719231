import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { persistor } from '../../redux/store';

export default function Logout({open, setOpen}) {
    const navigate = useNavigate()
    const cookie = new Cookies() 

  const logout = () => {
    persistor.purge(); // This will remove all data persisted by redux-persist
    localStorage.clear()
    cookie.remove('token') 
    cookie.remove('userType') 
    navigate('/') 
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth='xs'
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant='body' fontSize={'large'}>Log Out</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Typography >Are you sure?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button  variant='contained' onClick={()=>{handleClose();logout()}}>
                Log Out
            </Button>
            <Button  variant='outlined' onClick={handleClose} autoFocus>
                Cancel
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}