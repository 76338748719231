import React, { useEffect, useState } from 'react'
import configServ from '../../services/config';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Grid,
} from '@mui/material'
import { motion } from 'framer-motion';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import { useSelector } from 'react-redux';
import RolePopup from "../../Components/Card/CreateRoles/RolePopup"
import Supplier from '../../Components/supplier/Supplier';
import CustomUser from '../../Components/customUser/CustomUser';
import AssignRolesPage from '../../Components/AccessMaster/AssignRolesPage';
import CheckAndReturn from '../../utility/CheckAndReturn';

//FUNCTION
const AccessMasterCard = ({ open }) => {
    //#region code
    // const app = useSelector((state) => state.GlobalVariables.noRoomService);
    const { user_id, company_id, is_super, permissions } = useSelector((state) => state.GlobalVariables);
    const [role, setRole] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [openCreateRole, setOpenCreateRole] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [isSupplier, setIsSupplier] = useState(false);
    const [isManufacturer, setIsManufacturer] = useState(false);
    const [isCustomer, setIsCustomer] = useState(false);

    useEffect(() => {
        fetchRoles();
    }, []);

    useEffect(() => {
        if (role.toLowerCase().includes('supplier')) {
            setIsCustomer(false);
            setIsManufacturer(false);
            setIsSupplier(true);
        }
        else if (role.toLowerCase().includes('manufacturer')) {
            setIsCustomer(false);
            setIsManufacturer(true);
            setIsSupplier(false);
        }
        else if (role === '') {
            setIsCustomer(false);
            setIsManufacturer(false);
            setIsSupplier(false);
        }
        else {
            setIsCustomer(true);
            setIsManufacturer(false);
            setIsSupplier(false);
        }
    }, [role]);

    const handleCreateRoleOpen = () => {
        setOpenCreateRole(true);
    };

    const handleCreateRoleClose = () => {
        fetchRoles();
        setOpenCreateRole(false);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent("");
    };

    const fetchRoles = async () => {
        try {
            const result = await configServ.getRoles({ "user_id": user_id, "is_super": is_super })
            if (result.status === 200) {
                setRoleList(result.data);
            }
            else {
                setRoleList([]);
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />

            <RolePopup
                open={openCreateRole}
                handleClose={handleCreateRoleClose}
            />

            {open && (
                <>
                    {/* <motion.div
                        style={{
                            width: "100%",
                            backgroundColor: "#e6e6e4",
                            marginTop: 20,
                            padding: 10,
                            borderRadius: "10px",
                            border: "none",
                        }}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.1, delay: 0.1 }}
                    >
                        <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="flex-start"
                            spacing={1}
                        >
                            <Grid container item xs={12} md={12} justifyContent="space-between" alignItems="center" spacing={1}>
                                <Grid item xs={12} md={4} container justifyContent={'flex-start'}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>
                                        ADD USER
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4} container justifyContent={'center'}>
                                    <FormControl sx={{ width: '200px' }} size="small">
                                        <InputLabel htmlFor="role">Role*</InputLabel>
                                        <Select
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                            label="Role*"
                                            name='role'
                                            inputProps={{
                                                name: 'role',
                                                id: 'role',
                                            }}
                                            sx={{ backgroundColor: 'white' }}
                                        >
                                            <MenuItem value={''}>Select</MenuItem>
                                            {roleList.map((item) => (
                                                <MenuItem value={item.name}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} container justifyContent={'flex-end'}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        onClick={handleCreateRoleOpen}
                                        startIcon={<PersonAddIcon />}
                                    >
                                        Create Roles
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </motion.div> */}

                    {/* {role === "" && (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '200px' }}>
                            <Typography variant='h6'>Please choose a role.</Typography>
                        </div>
                    )} */}

                    {/* {isCustomer && ( */}
                    { (CheckAndReturn.PermissionHandler('access-role',permissions) === true) &&
                        <Grid container item xs={12} md={12} justifyContent={'flex-end'}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={handleCreateRoleOpen}
                                startIcon={<PersonAddIcon />}
                                sx={{mt:1}}
                            >
                                Create Roles
                            </Button>
                        </Grid>
                    }
                    <CustomUser role={role} />
                    {/* )} */}

                    {isSupplier && (
                        <Supplier role={role} />
                    )}

                </>
            )}

            {/* <AssignRolesPage /> */}
        </>
    );
};

export default AccessMasterCard;
