import React, { memo, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Box,
    Tooltip,
    IconButton,
    Grid,
    Typography,
    Button,
    NativeSelect,
    InputLabel,
    TextField,
    InputAdornment,
    FormControl,
    CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import configServ from '../../../services/config';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSelector } from 'react-redux';
import CheckAndReturn from '../../../utility/CheckAndReturn';
//import AddLineItem from '../../masterEntry/ItemEditOptions/AddLineItem';

//FUNCTION
const ItemTableCard = ({ handleEdit, isChanged, pagination = true }) => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const rowsPerPageOptions = [5, 10, 20, 50];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [itemList, setItemList] = useState([]);
    // [filterItemList, setFilterItemList] = useState([]);
    const [searchBy, setSearchBy] = useState('item_name');
    const [search, setSearch] = useState('');
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    //const [openGenerateLineItems, setOpenGenerateLineItems] = useState(false);
    //const [lineItem, setLineItem] = useState({});

    useEffect(() => {
        fetchItemList();
    }, [isChanged, page, rowsPerPage, search]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const fetchItemList = async () => {
        try {
            const dataToSend = {
                "company_id": company_id,
                "search_by": searchBy,
                "search": search,
                "per_page": rowsPerPage,
                "page": page
              }
            const res = await configServ.getItemList(dataToSend);
            if (res.status === 200) {
                setItemList(res.data);
                setCount(res.total);
            }
        } 
        catch (error) {
            console.log(error);
        }
        finally{   
            setLoading(false);
        }
    }

    const headers = ['Exam Name', 'Exam Code', 'Start', 'End', 'First Stage','Status', 'Action'];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    // const paginatedData = pagination
    //     ? filterItemList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //     : filterItemList;

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: 'black', // Set the header background color to blue
        color: 'white',
        padding: 5,
        textAlign:'center'
    };

    const evenRowStyle = {
        background: '#f5f5f5',
    };

    const oddRowStyle = {
        background: 'white',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    
    const cellStyle = {
        py: 0,
        my: 0,
        textAlign:'center'
      }

    // const FetchLocationName = ({ id }) => {
    //     const [loc, setLoc] = useState()

    //     useEffect(() => {
    //         if (id !== 0) {
    //             locName()
    //         }
    //     }, [])

    //     const locName = async () => {
    //         try {
    //             const result = await configServ.getLocationById({ "id": id })
    //             setLoc(result.location_name)
    //         } catch (err) {
    //             console.log(err)
    //         }
    //     }

    //     return (
    //         <TableCell>{loc || 'N/A'}</TableCell>
    //     )
    // }

    const handleSearchBy = (event) => {
        try {
            setSearchBy(event.target.value);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleSearch = (event) => {
        try {
            const data = event.target.value;
            if (data === "") {
                setSearch(null);
            } 
            else {
                setSearch(data);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    // const handleOpenLineItems = () => {
    //     setOpenGenerateLineItems(true);
    // };

    // const handleCloseLineItems = () => {
    //     setOpenGenerateLineItems(false);
    // };

    // const handleLineItems = (params) => {
    //     try {
    //         setLineItem(params);
    //         handleOpenLineItems();
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    //#endregion

    if (loading) {
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <CircularProgress />
            </Box>
        )
    }
    else {
        return (
            <>
                <Grid container alignItems="flex-end" justifyContent="flex-start" sx={{ py: 1 }} spacing={1} direction={'row'}>
                    <Grid item xs={12} md={6} justifyContent={'flex-start'}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Exam List:{count}</Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
                            <FormControl
                                fullWidth
                            >
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Search By
                                </InputLabel>
                                <NativeSelect
                                    defaultValue={'contact_name'}
                                    onChange={handleSearchBy}
                                    inputProps={{
                                        name: 'searchBy',
                                        id: 'uncontrolled-native',
                                    }}
                                >
                                    <option value={'item_name'}>Exam Name</option>
                                    <option value={'item_code'}>Exam Code</option>
                                    {/* <option value={'location_name'}>Location</option> */}
                                </NativeSelect>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} mr={{ xs: 0, md: 0 }} justifyContent={'flex-end'}>
                        <TextField
                            id="outlined-basic"
                            size="small"
                            fullWidth
                            placeholder="search"
                            variant="outlined"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ fill: 'grey' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ px: 1 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellStyle}>Sr. No.</TableCell>
                                {headers.map((header, index) => (
                                    <TableCell key={index} style={headerCellStyle}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {itemList.map((row, rowIndex) => (
                                <TableRow key={rowIndex}
                                    sx={{
                                        ...rowStyle,
                                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                    }}>
                                    <TableCell sx={cellStyle}>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                    <TableCell sx={cellStyle}>{row.item_name}</TableCell>
                                    <TableCell sx={cellStyle}>{row.item_code}</TableCell>
                                    {/* <FetchLocationName id={row.location_id} /> */}
                                    {/* <TableCell>{row.unit}</TableCell>
                                    <TableCell>{row.actualquantity}</TableCell> */}
                                    <TableCell sx={cellStyle}>{CheckAndReturn.transformToDate2(row.start_date)}</TableCell>
                                    <TableCell sx={cellStyle}>{CheckAndReturn.transformToDate2(row.end_date)}</TableCell>
                                    <TableCell sx={cellStyle}>{row.isFirstStage === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                                    <TableCell sx={cellStyle}>{row.status === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                                    <TableCell sx={cellStyle}>
                                        <div>
                                            <Tooltip title='Edit' placement='top' arrow>
                                                <IconButton onClick={() => { handleEdit(row); scrollToTop() }} sx={{ '&hover': { backgroundColor: '#99999955' } }}>
                                                    <EditIcon color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                            {/* <Tooltip title='Generate Line Item' placement='top' arrow>
                                                <IconButton onClick={() => handleLineItems(row)} sx={{ '&hover': { backgroundColor: '#99999955' } }}>
                                                    <Inventory2Icon color='secondary' />
                                                </IconButton>
                                            </Tooltip> */}
                                        </div>

                                    </TableCell>
                                </TableRow>
                            ))}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={headers.length + 2} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    </Table>
                    {pagination && (
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </TableContainer>
            </>
        );
    }
};

export default memo(ItemTableCard);
