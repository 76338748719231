import React, { useState } from 'react';
import { Button, ButtonGroup, IconButton, Grid, Box, FormControl, InputLabel, Select, MenuItem, Typography, Divider } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { changeNoRoomService } from '../../../redux/reducers/GlobalVariables';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
//FUNCTION
const CardHeadMenu = ({ prefix, suffix, title, handleReset, handleSave, handlePopup, isEdit, handleOTI, create, from, handleExcelDialog, isCreate = true }) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.GlobalVariables.noRoomService);
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleApplication = (params) => {
        dispatch(changeNoRoomService(params));
    }

    return (
        <>
            <Grid container alignItems="center" sx={{ pt: 0 }} spacing={1}>
                <Grid container item xs={3} sm={3} md={3} justifyContent={'flex-start'} alignItems={'center'}>
                    {
                        from === "RegCandidate" && (
                            <Button
                                variant="contained"
                                color={"success"}
                                size='small'
                                onClick={handleExcelDialog}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center', // Center content horizontally
                                    alignItems: 'center', // Center content vertically
                                    fontSize: '12px',
                                }}
                                title={'Bulk Upload'}
                                startIcon={<PublishRoundedIcon />}
                            >
                                {!isMobile && "Bulk Upload"}
                            </Button>
                        )
                    }
                    {/* <FormControl fullWidth variant="standard" sx={{ mb: 1 }}>
                        <InputLabel id="demo-simple-select-standard-label">Application</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={app}
                            label="Application"
                            onChange={(e) => handleApplication(e.target.value)}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'dcrm'}>DCRM</MenuItem>
                            <MenuItem value={'noroom'}>NoRoom</MenuItem>
                        </Select>
                    </FormControl> */}
                </Grid>
                <Grid container item xs={6} sm={6} md={6} justifyContent={'center'} alignItems={'center'}>
                    <Typography
                        variant='button'
                        sx={{
                            textAlign: 'center',
                            fontWeight: 'bolder', // Add the fontWeight property to make the text bolder
                            fontSize: { xs: '14px', sm: '14px', md: '24px' }, // Add the fontSize property to increase the text size
                            color: '#1e61b4',
                        }}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.1 }}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid container item xs={3} sm={3} md={3} justifyContent={'flex-end'} alignItems={'center'} >
                    {
                        isCreate === true && <>
                            <Button
                                variant="contained"
                                color={!create ? "primary" : "error"}
                                size='small'
                                onClick={handlePopup}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center', // Center content horizontally
                                    alignItems: 'center', // Center content vertically
                                    fontSize: '12px',
                                }}
                                title={!create ? 'Add' : 'Close'}
                                startIcon={!create ? <AddIcon /> : <CloseIcon />}
                            >
                                {!isMobile && (!create ? 'Add' : 'Close')}
                            </Button>
                        </>
                    }
                </Grid>
            </Grid>
        </>
    );
};

export default CardHeadMenu;
