import React, { useEffect, useState, useRef, memo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import configServ from "../../services/config";
import CustomerContact from "../../Components/Customers/CustomerContact";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  IconButton,
  Typography,
  Stack,
  Tooltip,
  Box,
  Paper,
  Grid,
} from "@mui/material";
import { motion } from "framer-motion";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import ButtonMUI from "@mui/material/Button";
// import http from '../../services/http';
import axios from "axios";
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import { useSelector } from "react-redux";

import CheckAndReturn from "../../utility/CheckAndReturn";

//FUNCTION
const VerifiedStudentCard = ({
  handleClose,
  open,
  isChanged,
  setIsChanged,
  editData,
  setEditData,
}) => {
  //#region code
  const { user_id, company_id, permissions } = useSelector((state) => state.GlobalVariables);

  const initialFormData = {
    status: true,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isDone, setIsDone] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openCustContact, setOpenCustContact] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState('Alert');
  const [dialogContent, setDialogContent] = useState('');
  const [buttonType, setButtonType] = useState(null);
  const [cannotAdd, setCannotAdd] = useState(true);
  const [cannotUpdate, setCannotUpdate] = useState(true);

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle('Alert');
    setDialogContent('');
    setButtonType(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  const handleSubmit = async () => {
    const dataToSend = {
      ...formData,
      full_name: formData.middle_name !== "" && formData.middle_name !== undefined ? `${formData.first_name || ""} ${formData.middle_name || ""} ${formData.last_name || ""}`
        : `${formData.first_name || ""} ${formData.middle_name || ""} ${formData.last_name || ""}`,
      user_id: user_id,
      company_id: company_id
    };
    
    try {
      const error = handleFormValidation();
      if (error === false) {
        return false;
      }
      setValidationErrors({});

      if (!isEdit) {
        const res = await configServ.addStudent(dataToSend);
        if (res.status === 200) {
          setIsChanged(!isChanged);
          clear();
          handleOpenDialog('Success', res.message, 'success');
        }
        else {
          handleOpenDialog('Error', res.message || "Failed to create candidate enrollment.", 'error');
        }
      } 
      else {
        const res = await configServ.editStudent(dataToSend);
        if (res.status === 200) {
          setIsChanged(!isChanged);
          clear();
          handleOpenDialog('Success', res.message, 'success');
        }
        else {
          handleOpenDialog('Error', res.message || "Failed to update candidate enrollment.", 'error');
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setFormData(editData);
      setFormData((prev)=>({
        ...prev,
        roll_no: editData.subscriptions !== null ? editData.subscriptions.roll_no : '',
      }));
      setIsEdit(true);
    }
  }, [editData]);

  const clear = () => {
    setFormData(initialFormData);
    setEditData({});
    setIsDone(false);
    setIsEdit(false);
    setValidationErrors({});
  };

  const handleCustContactOpen = () => {
    setOpenCustContact(true);
  };

  const handleCustContactClose = () => {
    setOpenCustContact(false);
  };

  const handleFormValidation = () => {
    const errors = {};
    if (!formData.student_uniq_id) {
      errors.student_uniq_id = "required";
    }
    if (!formData.roll_no) {
      errors.roll_no = "required";
    }
    if (!formData.registration_no) {
      errors.registration_no = "required";
    }
    if (!formData.first_name) {
      errors.first_name = "required";
    }
    if (!formData.last_name) {
      errors.last_name = "required";
    }

    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };



  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      <CustomerContact
        handleClose={handleCustContactClose}
        open={openCustContact}
        customer_id={formData.id}
        customer_code={formData.customer_code}
      />

      {open && (
        <motion.div
          style={{
            width: "100%",
            backgroundColor: "#e3f3fc",
            marginTop: 20,
            padding: 20,
            borderRadius: "10px",
            border: "none",
          }}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.1, delay: 0.1 }}
        >
          <Grid
            container
            alignItems="flex-end"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={12} md={12} justifyContent={"flex-start"}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bolder" }}>
                Candidate's Detail
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>Student Id</span>}
                name="student_uniq_id"
                type="number" // Set input type to "number" for numeric input
                onChange={handleChange}
                value={formData.student_uniq_id || ""}
                error={!!validationErrors.student_uniq_id}
                sx={{ backgroundColor: "white" }}
                required // Make the field required
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Allow only numeric input
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>Roll No</span>}
                name="roll_no"
                type="number" // Set input type to "number" for numeric input
                onChange={handleChange}
                value={formData.roll_no || ""}
                error={!!validationErrors.roll_no}
                sx={{ backgroundColor: "white" }}
                required // Make the field required
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Allow only numeric input
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>Registration No</span>}
                name="registration_no"
                type="number" // Set input type to "number" for numeric input
                onChange={handleChange}
                value={formData.registration_no || ""}
                error={!!validationErrors.registration_no}
                sx={{ backgroundColor: "white" }}
                required // Make the field required
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Allow only numeric input
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>First Name</span>}
                name="first_name"
                onChange={handleChange}
                value={formData.first_name || ""}
                error={!!validationErrors.first_name}
                sx={{ backgroundColor: "white" }}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>Middle Name</span>}
                name="middle_name"
                onChange={handleChange}
                value={formData.middle_name || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>Last Name</span>}
                name="last_name"
                onChange={handleChange}
                value={formData.last_name || ""}
                error={!!validationErrors.last_name}
                sx={{ backgroundColor: "white" }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <TextField
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                fullWidth
                size="small"
                label={<span>Full Name</span>}
                name="full_name"
                value={formData.middle_name !== "" && formData.middle_name !== null && formData.middle_name !== undefined ? `${formData.first_name || ""} ${formData.middle_name || ""} ${formData.last_name || ""}`
                  : `${formData.first_name || ""} ${formData.last_name || ""}`}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
              {" "}
              <FormControlLabel
                disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                control={<Checkbox />}
                name="status"
                checked={Boolean(formData.status)}
                onChange={handleChangeCheckbox}
                label="Status"
                sx={{ fontWeight: "bold" }}
              />
            </Grid>

            <Grid container spacing={2} justifyContent="space-between" px={2}>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                    onClick={clear}
                    variant="contained"
                    color="secondary"
                    startIcon={<RotateLeftIcon />}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={isEdit ? !!cannotUpdate : !!cannotAdd}
                    onClick={handleSubmit}
                    variant="contained"
                    startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </motion.div>
      )}
    </>
  );
};

export default memo(VerifiedStudentCard);
