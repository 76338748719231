
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
  Paper, Box, Tooltip, Grid, Typography, NativeSelect, InputLabel, TextField, InputAdornment,
  FormControl, CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import configServ from "../../services/config";
import { useSelector } from "react-redux";


const LocationTable = ({ handleEditData, isChanged, pagination = true }) => {
  //#region code
  const { company_id } = useSelector((state) => state.GlobalVariables);
  const rowsPerPageOptions = [25, 100, 200, 500];
  const [locationDataList, setLocationDataList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [searchBy, setSearchBy] = useState('location_name');
  const [count, setCount] = useState(0);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    fetchLocationList();
  }, [isChanged]);

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleSearch = (event) => {
    try {
      const search = event.target.value;
      let data = [];
      if (search === '') {
        data = locationDataList;
      }
      else if (searchBy === 'location_name') {
        data = locationDataList.filter(x => x.location_name.toLowerCase().includes(search.toLowerCase()))
      }
      else if (searchBy === 'city') {
        data = locationDataList.filter(x => x.city.toLowerCase().includes(search.toLowerCase()))
      }
      else if (searchBy === 'state') {
        data = locationDataList.filter(x => x.state.toLowerCase().includes(search.toLowerCase()))
      }
      setLocationList(data);
      setCount(data.length);
    }
    catch (error) {
      console.log(error);
    }
  }

  const headers = ['LOCATION', 'ADDRESS', 'CITY', 'STATE', 'STATUS', 'ACTION'];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const paginatedData = pagination
    ? locationList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : locationList;

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: 'bold',
    backgroundColor: 'black', // Set the header background color to blue
    color: 'white',
  };

  const evenRowStyle = {
    background: '#f5f5f5',
  };

  const oddRowStyle = {
    background: 'white',
  };

  const rowStyle = {
    '&:hover': {
      background: '#f1f9fe',
    },
  };

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent('');
  };

  const fetchLocationList = async () => {
    try {
      const res = await configServ.getLocation({
        company_id: company_id,
      });
      const result = res.map((item, index) => ({
        sn: index + 1,
        id: item.id,
        company_type: item.company_type,
        location_name: item.location_name,
        latitude: item.latitude,
        longitude: item.longitude,
        address: item.address,
        city: item.city,
        state: item.state,
        country: item.country,
        city_id: item.city_id,
        state_id: item.state_id,
        country_id: item.country_id,
        pin: item.pin,
        status: item.status,
      }));
      setLocationDataList(result);
      setLocationList(result);
      setCount(result.length);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrint = (params) => {
    handleOpenDialog(JSON.stringify(params));
  };

  const handleEdit = (params) => {
    const data = locationDataList.filter((x) => x.id === params);
    handleEditData(data);
  };
  //#endregion

  if (loading) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </>
    );
  }
  else {
    return (
      <>
        <AlertDialog
          open={dialogOpen}
          setOpen={handleCloseDialog}
          title="Alert"
          message={dialogContent}
          buttonTitle="Ok"
        />

        <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10, paddingTop: 0 }} spacing={1} direction={'row'}>
          <Grid item xs={12} md={6} ml={0} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Location List:{count}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ minWidth: 120, marginLeft: '0px' }}>
              <FormControl
                fullWidth
              >
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Search By
                </InputLabel>
                <NativeSelect
                  defaultValue={'contact_name'}
                  onChange={handleSearchBy}
                  inputProps={{
                    name: 'searchBy',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value={'location_name'}>Location Name</option>
                  <option value={'city'}>City</option>
                  <option value={'state'}>State</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} mr={{ xs: 0, md: 0 }} justifyContent={'flex-end'}>
            <TextField
              id="outlined-basic"
              size="small"
              fullWidth
              placeholder="search"
              variant="outlined"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fill: 'grey' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ px: 1 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={headerCellStyle}>Sr. No.</TableCell>
                {headers.map((header, index) => (
                  <TableCell key={index} style={headerCellStyle}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((row, rowIndex) => (
                <TableRow key={rowIndex}
                  sx={{
                    ...rowStyle,
                    ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                  }}>
                  <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                  <TableCell>{row.location_name}</TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.city}</TableCell>
                  <TableCell>{row.state}</TableCell>
                  <TableCell>{row.status === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                  <TableCell>
                    <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0.5, ":hover": { backgroundColor: '#99999955' } }}
                      onClick={() => { handleEdit(row.id); scrollToTop() }}
                    >
                      <Tooltip title='Edit' arrow>
                        <EditIcon color='primary' />
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={headers.length + 2} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
          {pagination && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </>
    );
  }
};

export default LocationTable;
