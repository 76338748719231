import React, { useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import configServ from '../../../services/config';
import CheckAndReturn from '../../../utility/CheckAndReturn';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InvoiceOrientation from '../../Dialog/InvoiceOrientation';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Calculation from '../../../utility/Calculations';
import { IconButton, Tooltip, Stack, TextField, InputAdornment, FormControl, InputLabel, NativeSelect } from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import PrintTwoToneIcon from '@mui/icons-material/PrintTwoTone';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import Pagination from '../../Pagination/Pagination';
import SearchIcon from '@mui/icons-material/Search';

const InvoiceTable = ({ handleEditData, isChanged, handleClickOpen, handleDialogOpen }) => {
    //#region code
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const [invoiceDataList, setInvoiceDataList] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [searchBy, setSearchBy] = useState('contact_name');
    const [loading, setLoading] = useState(true);
    const [showInvoiceList, setShowInvoiceList] = useState([]);
    const [open, setOpen] = useState(false);
    const [printData, setPrintData] = useState({});
    const [count, setCount] = useState(0);
    const [paginating, setPaginating] = useState({
        start: 0,
        end: 5
    });

    useEffect(() => {
        fetchRecentInvoiceList();
    }, [isChanged]);

    const fetchRecentInvoiceList = async () => {
        try {
            const res = await configServ.recentInvoiceList(company_id);
            const result = res.map((item, index) => ({
                sn: index + 1,
                id: item.id,
                contact_name: item.contact_name,
                invoice_number: item.invoice_number,
                date: CheckAndReturn.transformToDate(item.date),
                total_amount: Number(item.total_amount),
                dispatch: Boolean(item.dispatch) ? 'Dispatched' : 'Pending',
            }));
            setInvoiceDataList(res || []);
            setInvoiceList(result || []);
            setShowInvoiceList(result || []);
            if (result.length > 0) {
                setCount(result.length);
            }
            else {
                setCount(0);
            }
        }
        catch (error) {
            console.log(error);
            setInvoiceDataList([]);
            setInvoiceList([]);
            setShowInvoiceList([]);
            setCount(0);
        }
        finally{
            setLoading(false);
        }
    }

    const printInvoice = async (inv) => {
        const data = {
            "id": inv.id,
            "invoice_no": inv.invoice_number,
            "user_id": user_id,
            "company_id": company_id,
        }
        setOpen(true);
        setPrintData(data)
    }

    const handlePrint = (params) => {
        const data = invoiceDataList.filter(x => x.id === params.id);
        printInvoice(data[0])
    }

    const handleEdit = (params) => {
        scrollToTop()
        const data = invoiceDataList.filter(x => x.id === params.id);
        // calculating amount without tax
        data[0].items.map((item) => (
            item.amountWithoutTax = item.qty * item.rate
        ));

        handleEditData(data)
    }

    const handleEOptions = (params) => {
        const data = invoiceDataList.filter(x => x.id === params.id);
        handleClickOpen(data[0]);
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const cols = ['S.No.', 'Customer Name', 'Invoice No.', 'Invoice Date', 'Total Amount', 'Dispatch Detail', 'Actions'];

    const handleSearchBy = (event) => {
        try {
            setSearchBy(event.target.value);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleSearch = (event) => {
        try {
            const search = event.target.value;
            let data = [];
            if (search === '') {
                data = invoiceList;
            }
            else if (searchBy === 'contact_name') {
                data = invoiceList.filter(x => x.contact_name.toLowerCase().includes(search.toLowerCase()))
            }
            else if (searchBy === 'invoice_number') {
                data = invoiceList.filter(x => x.invoice_number.toLowerCase().includes(search.toLowerCase()))
            }
            setShowInvoiceList(data);
            if (data.length > 0) {
                setCount(data.length);
            }
            else {
                setCount(0);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const customTableHead = {
        backgroundColor: '#04AA6D',
        color: 'white'
    }
    //#endregion

    if (loading) {
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <CircularProgress />
            </Box>
        )
    }
    else {
        return (
            <>
                <InvoiceOrientation open={open} setOpen={setOpen} data={printData} />
                <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10 }} spacing={1} direction={'row'}>
                    <Grid item xs={10} md={3}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Invoice List: {count}</Typography>
                    </Grid>
                    <Grid item xs={2} md={1}>
                        {/* <Tooltip title="View all invoices" placement="top">
                            <Button  variant="contained" size="large" onClick={handleDialogOpen}>View</Button>
                        </Tooltip> */}
                    </Grid>
                    <Grid item xs={0} md={3}>

                    </Grid>
                    <Grid item xs={4} md={2}>
                        <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
                            <FormControl
                                fullWidth
                            >
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Search By
                                </InputLabel>
                                <NativeSelect
                                    defaultValue={'contact_name'}
                                    onChange={handleSearchBy}
                                    inputProps={{
                                        name: 'searchBy',
                                        id: 'uncontrolled-native',
                                    }}
                                >
                                    <option value={'contact_name'}>Customer Name</option>
                                    <option value={'invoice_number'}>Invoice Number</option>
                                </NativeSelect>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={7} md={3}>
                        <TextField
                            id="outlined-basic"
                            size="small"
                            fullWidth
                            placeholder="search"
                            variant="outlined"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ fill: 'grey' }} />
                                    </InputAdornment>
                                ),
                            }}
                        //sx={{ width: { xs: '300px', md: '500px' } }}
                        />
                    </Grid>
                </Grid>
                {/* <Stack direction="row" alignItems="center" justifyContent="flex-start" my={2}>
                    
                    <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
                        <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Search By
                        </InputLabel>
                        <NativeSelect
                            defaultValue={'contact_name'}
                            onChange={handleSearchBy}
                            inputProps={{
                            name: 'searchBy',
                            id: 'uncontrolled-native',
                            }}
                        >
                            <option value={'contact_name'}>Customer Name</option>
                            <option value={'invoice_number'}>Invoice Number</option>
                        </NativeSelect>
                        </FormControl>
                    </Box>
                    <TextField
                        id="outlined-basic"
                        size="small"
                        fullWidth
                        placeholder="search"
                        variant="outlined"
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <SearchIcon style={{ fill: 'grey' }} />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ width: { xs: '300px', md: '500px' } }}
                    />
                </Stack> */}

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <StyledTableRow key={'invtblhd'} style={customTableHead}>
                                {cols.map((item, index) => (
                                    <StyledTableCell align="left" key={index} sx={{ fontSize: '16px' }}>{item}</StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {showInvoiceList.slice(paginating.start, paginating.end).map((row, index) => (
                                <StyledTableRow key={index} sx={{ ':hover': { backgroundColor: '#f1f9fe' } }}>
                                    <StyledTableCell component="th" scope="row">{row.sn}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row" sx={{ fontWeight: 'bolder' }}>{row.contact_name}</StyledTableCell>
                                    <StyledTableCell align="left">{row.invoice_number}</StyledTableCell>
                                    <StyledTableCell align="left">{Calculation.formatDate(row.date.toString())}</StyledTableCell>
                                    <StyledTableCell align="left">₹{row.total_amount}</StyledTableCell>
                                    <StyledTableCell align="left">{row.dispatch}</StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div>
                                            <Tooltip title="Print" placement="top">
                                                <IconButton onClick={() => handlePrint(row)}>
                                                    <PrintTwoToneIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit" placement="top">
                                                <IconButton>
                                                    <EditTwoToneIcon onClick={() => handleEdit(row)} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="E-Options" placement="top">
                                                <IconButton onClick={() => handleEOptions(row)}>
                                                    <MiscellaneousServicesIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container fullWidth alignContent={'center'} justifyContent={'flex-end'} >
                    <Pagination
                        count={count}
                        paginating={setPaginating}
                    />
                </Grid>

            </>
        );
    }
};

export default InvoiceTable;
