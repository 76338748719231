import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import configServ from '../../services/config';
import CustomerContactTable from '../Card/Customers/CustomerContactTable';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    // DialogContentText,
    DialogActions,
    TextField,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    Checkbox,
    IconButton,
    Typography,
    Stack,
    Tooltip,
    Box,
    Divider,
    Grid
} from '@mui/material'

import ButtonMUI from '@mui/material/Button'

import { Container, Row, Col, Form, Button as ButtonB, FormCheck } from 'react-bootstrap';
// import http from '../../services/http';
import axios from 'axios';
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import { useSelector } from 'react-redux';

function UserContact({ handleClose, open, customer_id, customer_code }) {
    //#region code
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const initialFormData = {
        status: true,
    }
    const [formData, setFormData] = useState(initialFormData)
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [isDone, setIsDone] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const [validationErrors, setValidationErrors] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    const handleSubmit = async (e) => {
        // e.preventDefault()
        const dataToSend = {
            ...formData,
            company_id: company_id,
            user_id: user_id,
            customer_code: customer_code,
            customer_id: customer_id
        }
        //console.log(dataToSend)
        try {
            const error = handleFormValidation();
            if (error === false) {
                return false;
            }
            setValidationErrors({});
            if (!isEdit) {
                const result = await configServ.addCustomerContact(dataToSend);
                console.log(result)
                setIsChanged(!isChanged)
                clear()
                // console.log(dataToSend)
                handleOpenDialog(result.message)
            } else {
                const result = await configServ.editCustomerContact(dataToSend);
                console.log(result)
                setIsChanged(!isChanged)
                clear()
                handleOpenDialog(result.message)
                // console.log(dataToSend)
            }
        } catch (err) {
            console.log(err)
        }

    }

    const fetchCounties = async () => {
        const res = await axios.get('https://countriesnow.space/api/v0.1/countries/capital')
        if (!res.error) {
            setCountryList(res.data.data)
            // console.log(res.data.data)
        }
    }

    const fetchStates = async () => {
        const res = await axios.post('https://countriesnow.space/api/v0.1/countries/states', { "country": `${formData.country}` })
        if (!res.error) {
            setStateList(res.data.data.states)
            // console.log(res.data.data.states)
        }
    }

    useEffect(() => {
        fetchCounties()
    }, [])

    useEffect(() => {
        if (formData.country !== undefined) {
            fetchStates()
        }
    }, [formData])

    // useEffect(() => {
    //     if (Object.keys(editData).length !== 0) {
    //         setFormData(editData)
    //         setIsEdit(true)
    //         // console.log(Object.keys(editData))
    //     }
    // }, [editData])


    const handleEditData = (data) => {
        // console.log(data[0])
        setFormData(data[0])
        setIsEdit(true)
    }

    const handleFormValidation = () => {
        const errors = {};
        if (!formData.contact_name) {
            errors.contact_name = "required";
        }
        if (!formData.contact_person_name) {
            errors.contact_person_name = "required";
        }
        if (!formData.mobile) {
            errors.mobile = "required";
        }
        if (!formData.address) {
            errors.address = "required";
        }
        if (!formData.country) {
            errors.country = "required";
        }
        if (!formData.State) {
            errors.State = "required";
        }
        if (!formData.Pin) {
            errors.Pin = "required";
        }
        if (!formData.contact_type) {
            errors.contact_type = "required";
        }

        setValidationErrors(errors);
        return Object.values(errors).every((error) => error === "");
    };

    const clear = () => {
        setFormData(initialFormData);
        // setEditData({})
        // setIsDone(false)
        setIsEdit(false);
        setValidationErrors({});
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
                fullWidth={true}
                scroll='paper'
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='h5'>
                            Add Contact Details
                            <span style={{ color: 'red', marginLeft: '20px' }}>
                                {Object.values(validationErrors).every((error) => error === "") ? '' : 'Fill the required fields.'}
                            </span>
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    {/* <Button onClick={abc}>abc</Button> */}
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ backgroundColor: '#aaaaaa22', padding: 2, borderRadius: 2 }}>
                        <Grid container spacing={{ xs: 1, sm: 1, md: 1, lg: 1 }} alignItems={'center'} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ paddingBottom: '10px' }}>
                            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label='Contact Name*'
                                    name='contact_name'
                                    onChange={handleChange}
                                    value={formData.contact_name || ''}
                                    error={!!validationErrors.contact_name}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label='Contact Person Name*'
                                    name='contact_person_name'
                                    onChange={handleChange}
                                    // onClick={() => { assignContactName(); setIsDone(true) }}
                                    value={formData.contact_person_name || ''}
                                    error={!!validationErrors.contact_person_name}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label='Mobile Number*'
                                    name='mobile'
                                    type='number'
                                    onChange={handleChange}
                                    value={formData.mobile || ''}
                                    error={!!validationErrors.mobile}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label='Email'
                                    name='email'
                                    onChange={handleChange}
                                    value={formData.email || ''}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel>Country*</InputLabel>
                                    <Select required name='country'
                                        label='select' value={formData.country || ''}
                                        onChange={handleChange}
                                        error={!!validationErrors.country}
                                    >
                                        {countryList.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel>State*</InputLabel>
                                    <Select required name='State'
                                        label='select' value={formData.State || ''}
                                        onChange={handleChange}
                                        error={!!validationErrors.State}
                                    >
                                        {stateList.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label='Address*'
                                    name='address'
                                    onChange={handleChange}
                                    value={formData.address || ''}
                                    error={!!validationErrors.address}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
                                <TextField
                                    fullWidth
                                    type='number'
                                    size='small'
                                    label='Pin*'
                                    name='Pin'
                                    onChange={handleChange}
                                    value={formData.Pin || ''}
                                    error={!!validationErrors.Pin}
                                />
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
                                <FormControl
                                    fullWidth
                                    size='small'
                                >
                                    <InputLabel id="contact-type">Contact Type*</InputLabel>
                                    <Select
                                        labelId="contact-type"
                                        label="Contact Type"
                                        name='contact_type'
                                        value={formData.contact_type || ''}
                                        onChange={handleChange}
                                        error={!!validationErrors.contact_type}
                                    >
                                        <MenuItem value={'Office'}>Office </MenuItem>
                                        <MenuItem value={'Home'}>Home</MenuItem>
                                        <MenuItem value={'Branch'}>Branch</MenuItem>
                                        <MenuItem value={'Relative'}>Relative </MenuItem>
                                        <MenuItem value={'DiscOtherount'}>Other </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label='PAN/NO'
                                    name='pan_no'
                                    onChange={handleChange}
                                    value={formData.pan_no || ''}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label='GSTIN/UIN'
                                    name='gst_no'
                                    onChange={handleChange}
                                    value={formData.gst_no || ''}
                                />
                            </Grid>
                        </Grid>
                        <Row>
                            <Col sm={12}>
                                <FormControlLabel control={<Checkbox />} name='status' checked={Boolean(formData.status)} onChange={handleChangeCheckbox} label='Status' />
                            </Col>
                        </Row>
                    </Box>
                    <Divider
                        sx={{
                            my: '2rem',
                            backgroundColor: '#33333377'
                        }}
                    />

                    <CustomerContactTable handleEditData={handleEditData} isChanged={isChanged} customer_id={customer_id} />


                </DialogContent>
                <DialogActions sx={{ px: 2 }}>

                    <Tooltip title='Reset'>
                        <IconButton onClick={clear} >
                            <RotateLeftIcon />
                        </IconButton>
                    </Tooltip>
                    <Button
                        autoFocus
                        variant='contained'
                        onClick={handleSubmit}
                    >
                        {isEdit ? 'Update' : 'Save'}
                    </Button>

                </DialogActions>
            </Dialog>

        </>
    )
}

export default UserContact