import React, { useState } from 'react';
import { Grid, Typography, Button, CircularProgress, FormControlLabel, Checkbox, TextField } from '@mui/material';

const CaptureOrUploadFingerprint = ({ fingerprints, captured, handleUploadFinger, handleRecaptureFinger, handleCaptureFinger, handleClose, loading, error, from, remark,
    handleRemark }) => {
    const [isRemark, setIsRemark] = useState(false);
    const [remarkValidation, setRemarkValidation] = useState(''); 
    
    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target;
        setIsRemark(checked);
    };

    const handleUpload = () => { 
        if(isRemark === true && remark === null){
            setRemarkValidation("required");
            return false;
        }
        else{
            handleUploadFinger();
        }
     }

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} align="center" style={{ margin: '1rem' }}>
                <Typography variant="h4" gutterBottom>
                    BIOMETRICS
                </Typography>
            </Grid>
            {from === 'fromVerified' ?
                (<Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} align="center" style={{ margin: '1rem' }}>
                        <Typography variant="h5" gutterBottom>
                            No Fingerprint Found !!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center" style={{ margin: '10px' }}>
                        <Button onClick={handleClose} variant="contained" color="error">
                            Close
                        </Button>
                    </Grid>
                </Grid>) : (
                    <>
                        <Grid item xs={6} style={{ margin: '1rem', padding: '0.5rem', maxWidth: '10rem' }}>
                            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1} style={{ border: '1px solid black', borderRadius: '8px', padding: '1rem' }}>
                                <Grid item>
                                    {fingerprints[0] && (
                                        <img src={`data:image/jpeg;base64,${fingerprints[0].BitmapData}`} alt="Left Thumb" style={{ width: '5rem', borderRadius: '50%' }} />
                                    )}
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" align="center">Left Thumb</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container justifyContent="center" alignItems="center" spacing={2} style={{ margin: '10px' }}>
                            {captured ? (
                                <>
                                    <Grid item xs={4}>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            name="status"
                                            checked={isRemark}
                                            onChange={handleChangeCheckbox}
                                            label="Remark"
                                            sx={{ fontWeight: "bold" }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button fullWidth onClick={handleUpload} variant="contained" color="primary">
                                            Upload
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button fullWidth onClick={handleRecaptureFinger} variant="contained" color="error">
                                            Cancel
                                        </Button>
                                    </Grid>
                                    {
                                    (isRemark === true) &&
                                    <Grid item xs={8}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={2}
                                            name='remark'
                                            variant='outlined'
                                            value={remark || ""}
                                            placeholder='Enter comment here.'
                                            onChange={(e) => handleRemark(e.target.value)}
                                            error={!!remarkValidation}
                                        />
                                    </Grid>
                                }
                                </>
                            ) : (
                                <Grid item container spacing={2} justifyContent="center" alignItems="center">
                                    <Grid item>
                                        <Button onClick={handleCaptureFinger} disabled={loading} variant="contained" color="primary">
                                            {loading ? <CircularProgress size={24} /> : "Capture"}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={handleClose} variant="contained" color="error">
                                            Close
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {error && (
                            <Grid item xs={12} align="center">
                                <Typography variant="body1" color="error">
                                    Error: {error}
                                </Typography>
                            </Grid>
                        )}
                    </>
                )}
        </Grid>
    );
};

export default CaptureOrUploadFingerprint;
